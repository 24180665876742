import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {RenderService} from "@src/app/services/render/render.service";

@Component({
  selector: 'app-installation-service-modal',
  templateUrl: './installation-service-modal.component.html',
  styleUrls: ['./installation-service-modal.component.scss']
})
export class InstallationServiceModalComponent implements OnDestroy {

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  public installationServiceAvailable: boolean;
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _modalService: ModalService,
    private _priceCalculatorService: PriceCalculatorService,
    private _applicationStateService: ApplicationStateService,
    protected _renderService: RenderService
  ) {
    this._modalService.closeEmitter
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(id => {
        if (id === 'installation-service-modal') {
          this.installationServiceAvailable = this._priceCalculatorService.installationServiceAvailableForConfiguration();
        }
      });
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.appInitialized), take(1), takeUntil(this._componentDestroyed$)
    ).subscribe(_ => {
      this.installationServiceAvailable = this._priceCalculatorService.installationServiceAvailableForConfiguration()
    })
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  public close(): void {
    this._modalService.close('installation-service-modal');
  }
}
