import {Asset, AssetRaw, Configuration, Id} from "@ess/jg-rule-executor";
import {Retailer} from "@ess/jg-rule-executor/dist/src/domain/safety-environment";
import {PreConfiguration} from "@ess/jg-rule-executor/dist/src/domain/preconfiguration";

export class ConfiguratorPreConfiguration {
  id: Id<Configuration>;
  code: string;
  name: string;
  scope: Id<Retailer>;
  price: number;
  image: Asset;

  constructor(
    id: Id<Configuration>,
    code: string,
    name: string,
    scope: Id<Retailer>,
    price: number,
    image: AssetRaw
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.scope = scope;
    this.price = price;
    this.image = image;
  }

  fromPreConfiguration(preConfiguration: PreConfiguration): ConfiguratorPreConfiguration {
    return new ConfiguratorPreConfiguration(
      preConfiguration.id,
      preConfiguration.code,
      preConfiguration.name,
      preConfiguration.scope,
      preConfiguration.price,
      Asset.fromRaw(preConfiguration.image)
    )
  }
}
