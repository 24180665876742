import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {ScreenshotComponent} from "@src/app/components/screenshot/screenshot.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'screenshot/:mode',
    component: ScreenshotComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
