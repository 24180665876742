<div class="frame bottom-spacer" [class.selected]="!this.useInstallationService"
     (click)="setInstallationService(false)">
    <div class="content">
        <svg-icon [src]="selectedIconSrc" class="icon-checkmark" [class.show-icon]="!this.useInstallationService"/>
        <p class="font-h3 line-height-h2" [innerHTML]="'app.result_view.montage.reject_service' | translate"></p>
    </div>
</div>
<div class="frame" [class.selected]="this.useInstallationService"
     (click)="setInstallationService(true)">
    <div class="content">
        <svg-icon [src]="selectedIconSrc" class="icon-checkmark" [class.show-icon]="this.useInstallationService"/>
        <div>
            <p class="font-h3" [innerHTML]="'app.result_view.montage.add_service' | translate"></p>
            <p class="font-body1">{{ "app.modal.installation_service.price_text" | translate: {price: currentInstallationPrice} }}</p>
        </div>
    </div>
</div>