import {Component, OnInit, ViewChild} from '@angular/core';
import {AnalyticsService} from "@src/app/services/analytics/analytics.service";
import * as THREE from 'three';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';
import {InitializationService} from "@src/app/services/initialization/initialization.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective, {static: true})
  toastContainer: ToastContainerDirective;

  constructor(
    private _analyticsService: AnalyticsService,
    private _toastrService: ToastrService,
    private _initService: InitializationService
  ) {
    this._initService.initializeTranslations();
    this._analyticsService.addAnalytics();

    // Disable colorManagement (default value changed in a three.js update, new default renders the colours wrong)
    // @ts-ignore
    THREE.ColorManagement.enabled = false;
    // This is needed because when you open the configurator in a new tab window which is not in focus, a resize is not called in Chrome
    window.resizeTo(window.innerWidth, window.innerHeight);
    this._toastrService.overlayContainer = this.toastContainer;
  }

  async ngOnInit() {
    await this._initService.initializeParameters();
  }
}
