import {Injectable, OnDestroy} from "@angular/core";
import {
  GammaResetRequestMessage,
  GammaProductRequestMessage,
  GammaResponseMessage
} from "@src/app/services/retailer-handlers/gamma-domain";
import {IframeService} from "@src/app/services/iframe/iframe.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {RetailerOptions} from "@ess/jg-rule-executor";
import {WebhookFlowType} from "@ess/jg-rule-executor/dist/src/domain/webhook-flow-type";

@Injectable({
  providedIn: 'root'
})
export class GammaHandlerService implements OnDestroy {

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _iframeService: IframeService,
    private _loggingService: LoggingService,
    private _dataService: InitDataService,
  ) {
  }

  /**
   * Checks if we are dealing with a 'gamma' retailer.
   * If yes, send request to reset to the window height of the iframe if so.
   */
  public sendHeightResetIfGamma(retailerOptions: RetailerOptions): void {
    if (
      this._iframeService.inIframe() &&
      retailerOptions.webhookOptions.flowType === WebhookFlowType.gammasendmessage &&
      ((screen.height / screen.width) > (3/2)) &&
      this._isIPhoneOrIPod()
    ) {
      this.sendResetRequest(retailerOptions, "600");
    }
  }

  // check for iPhone and iPod
  private _isIPhoneOrIPod(): boolean {
    return /iPhone|iPod/.test(navigator.userAgent)
  }

  /**
   * Sends a reset request to the parent window
   * When Gamma receives this message, the iframe will be resized to the given height and
   * the window will scroll to the top of the iFrame.
   */
  private sendResetRequest(retailerOptions: RetailerOptions, height: string): void {
    const gammaHeightRequestMessage = new GammaResetRequestMessage(height);
    this._iframeService.postMessageToParent(gammaHeightRequestMessage, retailerOptions.webhookOptions.webhookUrls.redirectUrl);
  }

  /**
   * Hands off the translated message to the iframeService if it can be parsed, and sets up response handler
   */
  public parseAndSendMessage(payload: string): void {
    try {
      const message: object = JSON.parse(payload);
      if(message["products"] && message["signature"]) {
        const gprm: GammaProductRequestMessage = GammaProductRequestMessage.fromObject(message as {products: object[], signature: string});
        this._iframeService.receivedMessageEmitter
          .pipe(takeUntil(this._componentDestroyed$), take(1))
          .subscribe(msgData => this.incomingMessageHandler(msgData));
        this._iframeService.postMessageToParent(
          gprm,
          this._dataService.currentRetailerOptions.webhookOptions.webhookUrls.redirectUrl
        );
      } else {
       this._loggingService.debug(`Payload did not match GammaProductRequestMessage: ${payload}`);
      }
    } catch (e){
      this._loggingService.debug(`Payload could not be parsed: ${payload}, error: ${e}`);
    }
  }

  /**
   * Handles incoming message objects
   */
  public incomingMessageHandler(messageData: object): void {
    const response = messageData as GammaResponseMessage;
    // check if the whole request failed, or we have a failed product
    if (!response?.error && response?.products && !response?.products?.find(r => !r.success)) {
      this._loggingService.debug("Received gamma success response.");
    } else {
      this._loggingService.error(new Error(`Received an error or malformed response from Gamma: ${response}`));
    }
  }

  /**
   * Destroy component
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
  }
}
