import {Id, ModuleVariant} from "@ess/jg-rule-executor";
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'itemIsSelected'
})
export class ItemIsSelected implements PipeTransform {
    transform(latestSelected : Id<ModuleVariant>[] , itemId: Id<ModuleVariant>): boolean {
        return latestSelected.includes(itemId);
    }
}