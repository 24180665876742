/* eslint-disable max-classes-per-file */
import {Asset, Configuration, Id, ModuleBlueprint, ModulePlacement} from "@ess/jg-rule-executor";

export class FailedToRetrieveBlueprintsError extends Error {
  constructor(error: Error) {
    super("Failed to retrieve ModuleBlueprints.\nUnderlying error: " + parseErrorToString(error));
    this.name = "Failed to retrieve ModuleBlueprints";
  }
}

export class FailedToRetrieveObsoleteBlueprintError extends Error {
  constructor(error: Error) {
    super("Failed to retrieve obsolete ModuleBlueprint.\nUnderlying error: " + parseErrorToString(error));
    this.name = "Failed to retrieve obsolete ModuleBlueprint";
  }
}

export class FailedToRetrieveCatalogError extends Error {
  constructor(error: Error) {
    super("Failed to retrieve Catalog.\nUnderlying error: " + parseErrorToString(error));
    this.name = "Failed to retrieve Catalog";
  }
}

export class FailedToRetrieveObsoleteVariantError extends Error {
  constructor(error: Error) {
    super("Failed to retrieve obsolete ModuleVariant.\nUnderlying error: " + parseErrorToString(error));
    this.name = "Failed to retrieve obsolete ModuleVariant";
  }
}

export class FailedToRetrieveConfigurationByCodeError extends Error {
  constructor(configCode: string, error: Error) {
    super(`Configuration with code ${configCode} could not be found.\nUnderlying error: ${parseErrorToString(error)})`);
    this.name = "Configuration with code could not be found";
  }
}

export class FailedToFetchBlueprintModels extends Error {
  constructor(error: Error) {
    super(`Blueprint Models could not be fetched.\nUnderlying error: ${parseErrorToString(error)})`);
    this.name = "Blueprint Models could not be fetched";
  }
}

export class FailedToFetchCurrentScopeId extends Error {
  constructor(error: Error) {
    super(`Failed to fetch current scope id.\nUnderlying error: ${parseErrorToString(error)})`);
    this.name = "Failed to fetch current scope id";
  }
}

export class FailedToFetchCurrentRetailerOptions extends Error {
  constructor(error: Error) {
    super(`Failed to fetch current retailer options.\nUnderlying error: ${parseErrorToString(error)})`);
    this.name = "Failed to fetch current retailer options";
  }
}

export class FailedToFetchTranslations extends Error {
  constructor(error: Error) {
    super(`Failed to fetch translations.\nUnderlying error: ${parseErrorToString(error)})`);
    this.name = "Failed to fetch translations";
  }
}

export class NoConfigurationLoadedError extends Error {
  constructor() {
    super("No configuration is currently loaded");
  }
}

export class FailedToRetrieveBlueprintAssetError extends Error {
  constructor(id: Id<ModuleBlueprint>, error: Error) {
    super(`Failed to retrieve Asset for blueprint with id: ${id}.\nUnderlying error: ${parseErrorToString(error)}`);
    this.name = "Failed to retrieve Asset for blueprint";
  }
}

export class FailedToRetrieveAssetError extends Error {
  constructor(id: Id<Asset>) {
    super(`Failed to retrieve Asset id: ${id}`);
    this.name = "Failed to retrieve Asset";
  }
}

export class MalformedBlueprintError extends Error {
  constructor(id: Id<ModuleBlueprint>) {
    super(`Failed to retrieve Asset for malformed blueprint with id: ${id}`);
    this.name = "Failed to retrieve Asset for malformed blueprint";
  }
}

export class MalformedConfigError extends Error {
  constructor(id: Id<Configuration>) {
    super(`Failed to find a FatVariant used in configuration id: ${id}`);
    this.name = "Failed to find a FatVariant used in configuration";
  }
}

export class FailedToLoadRulesetError extends Error {
  constructor() {
    super('Could not load ruleset');
  }
}

export class RuleExecutionNotInitializedError extends Error {
  constructor() {
    super('Rule execution is not yet initialized.');
  }
}

export class InvalidColorError extends Error {
  constructor(colorName: string) {
    super(`An invalid color was passed for ${colorName} in Retailer Options.`);
    this.name = "An invalid color was passed in Retailer Options";
  }
}

export class InvalidDateError extends Error {
  constructor(date: string) {
    super(`An invalid date format was passed: ${date}`);
    this.name = "An invalid date format was passed";
  }
}

export class ErrorLoggingError extends Error {
  constructor(index: number) {
    super(`Failed to log the error produced by error handler number ${index}.`);
    this.name="Failed to log the error produced by error handler";
  }
}

export class RuleExecutionError extends Error {
  constructor(msg: string) {
    super(msg);
  }
}

function parseErrorToString(error: Error): string {
  return "\n[" + error.name + "]: " + error.message;
}
