<div class="container" [ngClass]="{disabled: disabled}">
    @for (button of buttons; track button; let i = $index) {
        <div class="button-container">
            <button class="button {{styleInput}}"
                    (click)="onClickFn(button)"
                    [disabled]="disabled"
                    [ngClass]="{disabled: disabled}">
                <svg-icon [src]="iconSources[i]" class="icon" [ngClass]="{'normal-icon': normalIcon}"></svg-icon>
            </button>
        </div>
    }
</div>
