<app-modal id="open-modal" #modalComponent>
    <div class="open-modal-container">
        <h2 class="font-h2">{{ "app.modal.open.header" | translate }}</h2>
        <p class="font-body2 bottom-spacer">{{ "app.modal.open.body" | translate }}</p>
        <form name="form"
              #formDiv="ngForm"
              class="code-form bottom-spacer"
              [formGroup]="form"
              (ngSubmit)="onSubmit()">
            <app-textfield
                id="code"
                formControlName="code"
                placeholderKey="app.modal.open.placeholder"
                class="code-field bottom-spacer"
                [isDisabled]="false"
            ></app-textfield>
            @if (submittedAtLeastOnce && f.code.errors) {
                <div class="invalid-feedback font-body-small bottom-spacer">
                    @if (f.code.errors.maxlength) {
                        <span>{{ 'app.modal.open.errors.max_length' | translate: {length: CODE_LENGTH} }}</span>
                    }
                    @if (f.code.errors.minlength) {
                        <span>{{ 'app.modal.open.errors.min_length' | translate: {length: CODE_LENGTH} }}</span>
                    }
                    @if (f.code.errors.required) {
                        <span>{{ 'app.modal.open.errors.required' | translate }}</span>
                    }
                </div>
            }
            @if (submittedAtLeastOnce && configNotFound) {
                <div class="invalid-feedback font-body-small bottom-spacer">
                    <span>{{ 'app.modal.open.errors.not_found' | translate }}</span>
                </div>
            }
            <app-button class="modal-button"
                        [label]="'app.modal.open.button' | translate"
                        [buttonType]=ButtonType.primary
                        fillParent="true"
                        [(disabled)]="waitingForResponse"
                        (onClick)="formDiv.onSubmit()"
            ></app-button>
        </form>
    </div>
</app-modal>
