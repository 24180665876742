import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextfieldComponent)
    }
  ]
})
export class TextfieldComponent {
  @Input() placeholderKey: string;
  @Input() fieldnameKey: string;

  @Input() value: string;
  @Input() isDisabled: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() wideField: boolean = false;

  // Private onChange for form control
  private _onChangeFn: any;

  public change(event: any): void {
    this.value = event.target.value;
    this.valueChange.emit(this.value);
    // If used as a form control it gets a change function
    if(this._onChangeFn) {
      this._onChangeFn(this.value);
    }
  }

  /**
   * Needed as a form component
   */
  public writeValue(value: string): void {
    this.value = value;
  }

  /**
   * Needed as a form component.
   * Note; this will become typed in some future angular version
   */
  public registerOnChange(fn: any): void {
    this._onChangeFn = fn;
  }

  // Not needed in use but need to be defined

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
  }
}
