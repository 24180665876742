import {Injectable} from '@angular/core';
import {Group, Vector2} from "three";
import {RenderObjectBuildInfo} from "@ess/jg-rule-executor";
import {MODULE_PLACEMENT_ID} from "@src/app/constants";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {createText} from "@src/app/services/utils/text-utils";
import {PlacementService} from "@src/app/services/placement/placement.service";
import {cloneDeep} from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  // This should only be done once. In the event multiple render cycles are called we want to stop that.
  private _markerPositionOverlapChecked: boolean = false;
  private _mainMarkerSize: number = 28;
  private _mainFontSize: number = 18;
  private _visualXMarkerSize: number = 24;
  private _visualXFontSize: number = 12;
  private _circleRadius: number = 4;

  // The amount of pixels we add to make it not overlap anymore
  private _incrementalCorrection: number = 1;

  constructor(
    private _dataService: InitDataService
  ) {
  }

  /**
   * Create marker objects given buildInfo; these may overlap.
   * Markers are placed at the maximum Y coordinate of the provided bbox, so that they are always on top of the
   * configuration.
   *
   * The userdata for a marker will contain the module_placement_id of the module it belongs to for outside reference.
   */
  public createMarkerGroup(buildInfo: RenderObjectBuildInfo): Group {
    const markerGroup = new Group();

    if (buildInfo.fatVariant.blueprint.visualXAttachmentPoints.length > 0) {
      const labelMarker = createText(
        this._dataService.markerPlacement.mainMarkers.get(buildInfo.placement.id),
        this._createStyleMap(true),
        new Map(),
        'marker-class main-marker' // Selector for the afterRender function
      );

      const modulePosAndRotation = buildInfo.determineModulePositionAndRotation();

      labelMarker.position.add(modulePosAndRotation.position);
      labelMarker.position.add(PlacementService.correctBlueprintOffset(buildInfo.fatVariant.blueprint, modulePosAndRotation.rotation));
      labelMarker.userData[MODULE_PLACEMENT_ID] = buildInfo.placement.id;

      markerGroup.add(labelMarker);

      buildInfo.fatVariant.blueprint.visualXAttachmentPoints.forEach((x, idx) => {
        // only create marker when the attachment point is occupied
        if (x.xAttachmentPointIds.filter(at => buildInfo.placement.xAttachments.get(at) != null).length > 0) {
          const labelText = this._dataService.markerPlacement.visualXMarkers.get(buildInfo.placement.id)[idx];
          const visualXMarker = createText(
            labelText,
            this._createStyleMap(),
            new Map(),
            'marker-class attach-marker' // Selector for the afterRender function
          );

          visualXMarker.position.add(x.determinePosition(modulePosAndRotation.position, modulePosAndRotation.rotation));
          markerGroup.add(visualXMarker);
        }
      });
    }

    return markerGroup;
  }

  /**
   * If marker positions have not been checked yet, iterate over all markers and check if they do not overlap with any other markers.
   *
   * Markers are retrieved directly from the HTML document.
   *
   * We place main markers first, so those are (if at all possible) at least somewhat close to the intended position; we will still
   * move main markers around if they overlap with other main markers.
   *
   * If a marker needs to be displaced, we determine the position of the module it belongs to and try to displace it radiating outward;
   * so a module on the -x side of the module's center will displaced further towards -x, etc.
   */
  public markerPositionOverlapFix() {
    if (!this._markerPositionOverlapChecked) {
      // Only check once
      this._markerPositionOverlapChecked = true;
      // Select elements on class
      const elements: NodeListOf<HTMLElement> = document.querySelectorAll(".marker-class");
      // Construct all positions from the HTML elements
      let positions: {
        el: HTMLElement, w: number, h: number, l: number, t: number,
        correctionLeft: number, correctionTop: number, isMain: boolean, group: string
      }[] = [];
      elements.forEach(element => {
        const box = element.getBoundingClientRect();
        positions.push({
          el: element,
          correctionTop: 0,
          correctionLeft: 0,
          w: box.width,
          h: box.height,
          l: box.x,
          t: box.y,
          isMain: element.classList.contains("main-marker"),
          group: element.id.replace(/[0-9]/g, ""),
        });
      });

      // Check overlap and correct
      positions = positions.sort((a, b) => a.el.id > b.el.id ? -1 : 1);
      positions.filter(e => e.isMain).forEach(pos => {
        // for main modules, we want to keep them in their own space as much as possible, only moving around for other main modules
        while (this._checkForOverlap(pos, positions.filter(e => e.isMain))) {
          pos.correctionTop += this._incrementalCorrection;
          pos.correctionLeft += this._incrementalCorrection;
        }
        this._setCorrectionMargins(pos);
      });

      positions.filter(e => !e.isMain).forEach(pos => {
        // find which direction to correct
        const baseMarker = positions.find(e => e.isMain && e.group === pos.group);
        const baseLocation = new Vector2(baseMarker.l + baseMarker.correctionLeft, baseMarker.t + baseMarker.correctionTop);
        const posLocation = new Vector2(pos.l + pos.correctionLeft, pos.t + pos.correctionTop);
        const correction = new Vector2().subVectors(posLocation, baseLocation).normalize().multiplyScalar(this._incrementalCorrection);

        // if element not is main, take ALL other nodes into account
        let corrected = false;
        const originalPos = cloneDeep(pos);

        // Apply corrections until no overlap is detected
        while (this._checkForOverlap(pos, positions)) {
          pos.correctionTop += correction.y;
          pos.correctionLeft += correction.x;
          corrected = true;
        }

        if (corrected) {
          this._setCorrectionMargins(pos);
          this._calculateLineForCorrectedPosition(pos, originalPos, baseMarker);
        }
      });
    }
  }

  private _setCorrectionMargins(
    pos: { el: HTMLElement; w: number; h: number; l: number; t: number; correctionLeft: number; correctionTop: number; isMain: boolean; group: string }
  ) {
    if (pos.correctionTop > 0) {
      pos.el.style.marginTop = (2 * pos.correctionTop) + "px"; // Use 2 times correction as it is a margin
    } else {
      pos.el.style.marginBottom = (-2 * pos.correctionTop) + "px"; // Use 2 times correction as it is a margin
    }

    if (pos.correctionLeft > 0) {
      pos.el.style.marginLeft = (2 * pos.correctionLeft) + "px"; // Use 2 times correction as it is a margin
    } else {
      pos.el.style.marginRight = (-2 * pos.correctionLeft) + "px"; // Use 2 times correction as it is a margin
    }
  }

  /**
   * Checks if an element pos overlaps with any of the elements in allPositions.
   *
   * @param pos
   * @param allPositions
   * @private
   */
  private _checkForOverlap(
    pos: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number },
    allPositions: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number }[],
  ): boolean {
    return allPositions.reduce((collect, position) => {
      if (collect || pos.el.id === position.el.id) {
        return collect;
      } else {
        return this._overlaps(pos, position);
      }
    }, false);
  }

  /**
   * Checks overlap between two marker elements.
   *
   * @private
   */
  private _overlaps(
    pos1: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number },
    pos2: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number },
  ): boolean {
    return pos1.l + pos1.correctionLeft < pos2.l + pos2.correctionLeft + pos2.w &&
      pos1.l + pos1.correctionLeft + pos1.w > pos2.l + pos2.correctionLeft &&
      pos1.t + pos1.correctionTop < pos2.t + pos2.correctionTop + pos2.h &&
      pos1.t + pos1.correctionTop + pos1.h > pos2.t + pos2.correctionTop;
  }

  private _createStyleMap(main: boolean = false): Map<string, string> {
    return new Map([
      ["font-size", `${(main ? this._mainFontSize : this._visualXFontSize)}px`],
      ["width", `${main ? this._mainMarkerSize : this._visualXMarkerSize}px`],
      ["height", `${main ? this._mainMarkerSize : this._visualXMarkerSize}px`],
      // Round with simple black border
      ["border", "1px solid black"],
      ["border-radius", "50%"],
      ["background", "#fff"],
      // Center
      ["display", "flex"],
      ["justify-content", "center"],
      ["align-items", "center"],
    ]);
  };

  private _calculateLineForCorrectedPosition(
    targetPos: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number, isMain: boolean, group: string },
    originalPos: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number, isMain: boolean, group: string },
    baseMarker: { el: HTMLElement, w: number, h: number, l: number, t: number, correctionLeft: number, correctionTop: number, isMain: boolean, group: string }
  ): void {
    // Calculate center positions
    const originalCenterX = originalPos.l + originalPos.correctionLeft + originalPos.w / 2;
    const originalCenterY = originalPos.t + originalPos.correctionTop + originalPos.h / 2;

    const baseCenterX = baseMarker.l + baseMarker.correctionLeft + baseMarker.w / 2;
    const baseCenterY = baseMarker.t + baseMarker.correctionTop + baseMarker.h / 2;

    const posCenterX = targetPos.l + targetPos.correctionLeft + targetPos.w / 2;
    const posCenterY = targetPos.t + targetPos.correctionTop + targetPos.h / 2;

    // based on this check, the circle will or will not be created and the line shortened, so they don't overlap the base marker.
    const originalIsBase = baseCenterX == originalCenterX && baseCenterY == originalCenterY && originalPos !== baseMarker;

    const direction = new Vector2(posCenterX - originalCenterX, posCenterY - originalCenterY).normalize();
    const radius = targetPos.w / 2;

    const lineStartX = originalIsBase ? originalCenterX + direction.x * radius : originalCenterX;
    const lineStartY = originalIsBase ? originalCenterY + direction.y * radius : originalCenterY;
    const lineEndX = posCenterX - direction.x * radius;
    const lineEndY = posCenterY - direction.y * radius;

    this._drawLine(lineStartX, lineStartY, lineEndX, lineEndY);

    if (!originalIsBase) { // only place the circle if the originalPosition is not the same as the baseMarker
      this._drawSmallCircle(originalCenterX, originalCenterY, this._circleRadius);
    }
  }

  /**
   * Draw a circle
   * @param x
   * @param y
   * @param radius
   * @private
   */
  private _drawSmallCircle(x: number, y: number, radius: number) {
    const circle = document.createElement('div');
    circle.style.position = 'absolute';
    circle.style.width = `${radius * 2}px`;
    circle.style.height = `${radius * 2}px`;
    circle.style.backgroundColor = 'black';
    circle.style.borderRadius = '50%';
    circle.style.left = `${x - radius}px`;
    circle.style.top = `${y - radius}px`;

    document.body.appendChild(circle);
  }

  /**
   * Draw a line
   * @param x1
   * @param y1
   * @param x2
   * @param y2
   * @private
   */
  private _drawLine(x1: number, y1: number, x2: number, y2: number) {
    const line = document.createElement('div');
    line.style.position = 'absolute';

    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;

    line.style.width = `${length}px`;
    line.style.height = '0px'; // No thickness, the border will provide dashes
    line.style.border = '1px dashed black';
    line.style.left = `${x1}px`;
    line.style.top = `${y1}px`;
    line.style.transform = `rotate(${angle}deg)`;
    line.style.transformOrigin = '0 0';

    document.body.appendChild(line);
  }
}
