import {Injectable} from '@angular/core';
import {firstValueFrom, Subject} from "rxjs";
import {filter, map, take} from "rxjs/operators";
import {ActivationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {EntryPointService} from "@src/app/services/entry-point/entry-point.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {RenderService} from "@src/app/services/render/render.service";
import {AnalyticsService} from "@src/app/services/analytics/analytics.service";
import {ApolloService} from "@src/app/services/apollo/apollo.service";
import {GammaHandlerService} from "@src/app/services/retailer-handlers/gamma-handler.service";
import {environment} from "@src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  initialParametersSet: Subject<void> = new Subject<void>();

  constructor(
    private readonly _translateService: TranslateService,
    private _entryPointService: EntryPointService,
    private _dataService: InitDataService,
    private _router: Router,
    private _loggingService: LoggingService,
    protected _renderService: RenderService,
    private _analyticsService: AnalyticsService,
    private _apolloService: ApolloService,
    private _gammaHandlerService: GammaHandlerService
  ) {
  }

  public initializeTranslations(): void {
    this._translateService.setDefaultLang('nl');
    this._translateService.use('nl');
    this._loggingService.debug(`Version: V${environment.versions.version}`);
    this._loggingService.debug("Initial setup started");
  }

  public async initializeParameters(): Promise<void> {
    const event = await firstValueFrom(this._router.events.pipe(
      // Double negation seems to be needed here
      filter(x => !!((x instanceof ActivationEnd) && x.snapshot.queryParams)),
      map(x => x as unknown as ActivationEnd), // safely cast
      take(1) // only first, so no unsub needed
    ))
    // Get current scope from url
    const scope = event.snapshot.queryParams['scope'] ?? event.snapshot.queryParams['s'];

    // if no scope provided reroute, else continue flow
    if (!scope) {
      window.location.href = 'http://home.mycustomplayground.com/';
    } else {
      this._loggingService.debug(`Scope set to ${scope} from query parameters`);
      this._dataService.currentScope = scope;

      await this.initializeRetailerOptions(event, scope);
      // tree params
      const treeOffset = event.snapshot.queryParams["treeOffset"];
      const treeAmount = event.snapshot.queryParams["treeAmount"];
      const treeScale = event.snapshot.queryParams["treeScale"];
      const grassAmount = event.snapshot.queryParams["grassAmount"];

      if (treeOffset) {
        this._renderService.treeOffset = treeOffset;
      }
      if (treeAmount) {
        this._renderService.treeAmount = treeAmount;
      }
      if (treeScale) {
        this._renderService.treeScale = treeScale;
      }
      if (grassAmount) {
        this._renderService.grassAmount = grassAmount;
      }

      // Get authentication token from url fragment for testing draft blueprints
      const token = event.snapshot.fragment;
      if (token) {
        this._dataService.authJwtToken = token;
      }

      // Set the preloading of data
      this._dataService.noDataPreload = !(event.snapshot.queryParams['noPreload'] === 'false');
      this._dataService.noRulesAndValidation = event.snapshot.queryParams['noRules'] === 'true';

      // AR settings
      this._dataService.arAllowed.ios = !(event.snapshot.queryParams['ari'] === 'false');
      this._dataService.arAllowed.android = !(event.snapshot.queryParams['ara'] === 'false');
      this._dataService.openAr = event.snapshot.queryParams['openAr'] === 'true';

      // Bounding boxes
      this._dataService.showBoundingBoxes = event.snapshot.queryParams['boundingBoxes'] === 'true';

      this.initialParametersSet.next();
    }
  }

  public async initializeRetailerOptions(event: ActivationEnd, scope: string): Promise<void> {
    const options = await firstValueFrom(this._apolloService.getCurrentRetailerOptions$())
    if (options) {
      // Set the height of the iframe if we are dealing with a gamma retailer
      this._gammaHandlerService.sendHeightResetIfGamma(options);

      // Get id query parameter to start the config with
      const codeToLoad = (event.snapshot.queryParams['c'] ?? event.snapshot.queryParams['code'])

      // Get whether to display markers and/or measurement arrows
      const markers = event.snapshot.queryParams['markers'] === 'true';
      this._dataService.forceMeasurementsOn = event.snapshot.queryParams['measurementArrows'] === 'true';

      const downloadUsdz = event.snapshot.queryParams['downloadUsdz'] === 'true';

      this._dataService.showMarkers = markers;

      this._analyticsService.addAnalytics();

      this._entryPointService.firstTimeInitializeApp$(options, codeToLoad, markers, downloadUsdz).catch(e => {
        this._loggingService.log("Encountered an error while initializing the app: " + (e as Error).stack);
      });
    } else {
      alert("scope " + scope + " does not exist");
      window.location.href = 'http://home.mycustomplayground.com/';
    }
    return Promise.resolve();
  }
}
