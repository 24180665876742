import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {takeUntil} from "rxjs/operators";
import {createConfigCodeUrl} from "@src/app/services/utils/text-utils";

@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;

  private _componentDestroyed$: Subject<void> = new Subject();

  private code: string;
  public link: string;

  constructor(
    private _modalService: ModalService,
  ) {
  }

  ngAfterViewInit(): void {
    this._modalComponent.getModalArg("code")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (code: any) => {
          this.code = code as string;
          this.link = createConfigCodeUrl(this.code) + '&ara=true&ari=true&openAr=true';
        },
        error: _ => {
        }
      });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  // Close me up
  public close(): void {
    this._modalService.close('qr-code-modal');
  }
}
