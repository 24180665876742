import {EventEmitter, Injectable, OnDestroy} from "@angular/core";
import {fromEvent, Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {LoggingService} from "@src/app/services/logging/logging.service";

@Injectable({
  providedIn: 'root'
})
export class IframeService implements OnDestroy {

  // Emitter that will emit all incoming messages that match the class's filter function
  public receivedMessageEmitter: EventEmitter<object> = new EventEmitter<object>();
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _loggingService: LoggingService
  ) {
    this._setUpEventListener();
  }

  /**
   * Posts a message to the parent window, if there is one.
   * Target origin should match the url of the parent; can also be set as unspecified by using "*", but this is not advised.
   */
  public postMessageToParent(message: object, targetOrigin: string): void {
    this._loggingService.debug(`Sending message ${JSON.stringify(message, null, 2)} to parent with targetOrigin: ${targetOrigin}`);
     window.parent.postMessage(JSON.stringify(message), {targetOrigin});
  }

  public inIframe(): boolean {
    return window.self !== window.top;
  }

  /**
   * Destroy component
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  private _setUpEventListener(): void {
    fromEvent(window, 'message')
      .pipe(
        takeUntil(this._componentDestroyed$),
        filter((this._messageFilter))
      )
      .subscribe((event: MessageEvent) => {
        this._loggingService.debug(`Received message from parent: ${JSON.stringify(event.data, null, 2)} from origin ${event.origin}`);
        this.receivedMessageEmitter.emit(event.data);
      });
  }

  /**
   * Window will fire lots of 'normal' messages, so we need a filter to get ONLY messages from retailers.
   * Note that this filter should be updated if additional possible incoming message types are added!
   */
  private _messageFilter(ev: MessageEvent): boolean {
    return !!ev?.data?.products || !!ev?.data?.error;
  }
}
