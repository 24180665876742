import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {PlatformService} from './services/platform/platform.service.interface';
import {PlatformServiceImpl} from './services/platform/platform.service';
import {ApolloService, ApolloServiceConfig} from './services/apollo/apollo.service';
import {RouterService} from "@src/app/services/router/router.service.interface";
import {RouterServiceImpl} from "@src/app/services/router/router.service";
import {StorageService} from "@src/app/services/storage/storage.service";

export interface ModuleOptions {
  accessTokenHeader?: string;
  cookieName?: string;
  backendUrl?: string;
  portalBackendUrl?: string;
  gqlEntryPoint?: string;
  usePortalBackend?: boolean
}

export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<ModuleOptions>('FRONTEND_CORE_FORROOT_TOKEN');

export function createApolloServiceConfig(options?: ModuleOptions): ApolloServiceConfig {
  const config = new ApolloServiceConfig();

  if (options) {
    if (options.backendUrl) {
      config.backendUrl = options.backendUrl;
    }

    // Switch to portal backend if desired
    if (options.usePortalBackend && options.portalBackendUrl) {
      config.backendUrl = options.portalBackendUrl;
    }

    if (options.gqlEntryPoint) {
      config.gqlEntryPoint = options.gqlEntryPoint;
    }
  }

  return config;
}

@NgModule({
  imports: [],
  exports: [],
  providers: [
    ApolloService,
    StorageService,
    {provide: RouterService, useClass: RouterServiceImpl},
    {provide: PlatformService, useClass: PlatformServiceImpl}
  ]
})
export class CoreModule {

  // https://www.bennadel.com/blog/3565-providing-module-configuration-using-forroot-and-ahead-of-time-compiling-in-angular-7-2-0.htm
  static forRoot(options?: ModuleOptions): ModuleWithProviders<CoreModule> {
    return ({
      ngModule: CoreModule,
      providers: [
        // In order to translate the raw, optional OPTIONS argument into an
        // instance of the MyServiceOptions TYPE, we have to first provide it as
        // an injectable so that we can inject it into our FACTORY FUNCTION.
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options
        },
        // Once we've provided the OPTIONS as an injectable, we can use a FACTORY
        // FUNCTION to then take that raw configuration object and use it to
        // configure an instance of the MyServiceOptions TYPE (which will be
        // implicitly consumed by the MyService constructor).
        {
          provide: ApolloServiceConfig,
          useFactory: createApolloServiceConfig,
          deps: [FOR_ROOT_OPTIONS_TOKEN]
        }

        // NOTE: We don't have to explicitly provide the MyService class here
        // since it will automatically be picked-up using the "providedIn"
        // Injectable() meta-data.
      ]
    });
  }
}


