/* eslint max-classes-per-file: ["error", 2] */
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader} from "@ngx-translate/core";
import {Observable, of} from "rxjs";

export function loaderFactory(): TranslateLoader {
  return new LocalTranslationLoader();
}

/**
 * Loads translations by merging them all during compile/build time. All translations end up in the js bundle.
 */
class LocalTranslationLoader extends TranslateLoader {
  private _paths: string[];

  constructor(...paths: string[]) {
    super();
    this._paths = paths;
  }

  getTranslation(lang: string): Observable<any> {
    // some magic stuff happens here. the require(..) call results in some webpack plugin merging all jsons from assets/i18n in the bundle.
    // the merging happens at build time!

    // note that using require() on images/css/fonts will result in a hashed filename. this can be used to prevent browser caching issues.
    return of(this._paths.reduce((acc, path) => Object.assign(acc, require(`../../assets/i18n/${path}/${lang}.json`)), {}));
  }
}

export class ConfiguratorMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const paramString = params?.interpolateParams ? Object.keys(params.interpolateParams).join(", ") : undefined;
    return `${params.key}${paramString ? `(${paramString})` : ''}`;
  }
}
