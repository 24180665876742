import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Icon, iconSource} from "@src/app/library/components/icon";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {DebugService} from "@src/app/services/debug/debug.service";
import {IframeService} from "@src/app/services/iframe/iframe.service";
import {ModalService} from "@src/app/services/modal/modal.service";
import {ViewMode} from "@src/app/model/view-mode";

@Component({
  selector: 'app-canvas-overlay',
  templateUrl: './canvas-overlay.component.html',
  styleUrls: ['./canvas-overlay.component.scss']
})
export class CanvasOverlayComponent implements OnInit, OnDestroy {

  @Input() loadingFinished: boolean = false;

  public showGesture: boolean = false;
  public gestureTimeMs: number = 5440; // determined online :)
  public fullscreenAvailable: boolean = false;
  public fullscreenActive: boolean = false;

  // We start disabled as to not trigger anything
  public revertDisabled: boolean = true;

  // Track the view mode
  public currentViewIndex: number;

  public showMeasurements: boolean = false;

  public measurementIcons: [{icon: string}];
  public woodTypeIcons: [{icon: string}];
  public resetIcons: [{icon: string}];

  public showWoodTypeButton = false;

  // these will be loaded when retailer doesn't have custom icons
  public standardMeasurementIcons = [{icon: Icon.ruler}];
  public standardWoodTypeIcons = [{icon: Icon.woodtype}];
  public standardResetIcons = [{icon: Icon.revert}];

  public showLogo = false;
  public jgLogoSrc = iconSource(Icon.junglegymlogo);

  public inEditMode: Observable<boolean>;

  public debugIcon = Icon.hammer;
  public helpIcon = [{icon: Icon.questionmark, disabled: false}];

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _interactionService: InteractionService,
    _applicationStateService: ApplicationStateService,
    private _modalService: ModalService,
    private _dataService: InitDataService,
    private _iframeService: IframeService,
    public debugService: DebugService
  ) {
    _applicationStateService.getState$().pipe(filter(t => t === ApplicationState.appInitialized), take(1), takeUntil(this._componentDestroyed$))
      .subscribe(() => {
        this.showGesture = true;
        // Stop the animation given time after
        setTimeout(() => this.showGesture = false, this.gestureTimeMs);
        // Disable revert button if there is no history
        this._interactionService.getConfigurationChanged$()
          .pipe(takeUntil(this._componentDestroyed$))
          .subscribe(c => this.revertDisabled = c.history.length < 1);
        this._interactionService.getViewMode$()
          .pipe(takeUntil(this._componentDestroyed$))
          .subscribe(m => this.currentViewIndex = m);

        if (this._dataService.openAr) {
          this.openArOrQrModal();
        }
      });

    this.fullscreenAvailable = this._fullscreenAvailable();

    this.inEditMode = _interactionService.getViewMode$().pipe(
      map( v => v === ViewMode.edit),
      takeUntil(this._componentDestroyed$)
    )

    this.showWoodTypeButton = this._dataService.currentRetailerOptions?.woodTypesAvailable?.filter(wt => wt.available === true).length > 1;
  }

  public ngOnInit() {
    this.measurementIcons = this._dataService.currentRetailerOptions?.configuratorOptions?.configuratorIcons?.measurement ?
      [{icon: this._dataService.currentRetailerOptions.configuratorOptions?.configuratorIcons.measurement.url}] : null;
    this.woodTypeIcons = this._dataService.currentRetailerOptions?.configuratorOptions?.configuratorIcons?.woodType ?
      [{icon: this._dataService.currentRetailerOptions.configuratorOptions?.configuratorIcons?.woodType?.url}] : null;
    this.resetIcons = this._dataService.currentRetailerOptions?.configuratorOptions?.configuratorIcons?.reset ?
      [{icon: this._dataService.currentRetailerOptions.configuratorOptions?.configuratorIcons?.reset?.url}] : null;

    this.showLogo = this._dataService.currentRetailerOptions?.configuratorOptions?.bottomLogo;
  }

  /**
   * Destroy component
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
  }

  /**
   * Notify interaction service of revert
   */
  public revertClick(): void {
    this._interactionService.restorePreviousConfiguration();
  }

  /**
   * show rulers in configuration
   */
  public rulerClick(): void {
    this.showMeasurements = !this.showMeasurements;
    this._interactionService.setMeasurements(this.showMeasurements);
  }

  /**
   * Open wood type modal
   */
  public woodTypeClick(): void {
    this._modalService.open('wood-type-modal');
  }

  public openArOrQrModal(): void {
    if (this._dataService.isIos || this._dataService.isAndroid) {
      this._modalService.open('ar-instruction-modal');
    } else {
      this._interactionService.saveCurrentConfiguration('qr-code-modal');
    }
  }

  public toggleFullScreen(): void {
    if (!this.fullscreenActive) {
      this.fullscreenActive = true;
      this._requestFullscreen();
    } else {
      this.fullscreenActive = false;
      this._exitFullscreen();
    }
  }

  private _fullscreenAvailable(): boolean {
    return (!!(document.documentElement.requestFullscreen ||
        (document.documentElement as any).mozRequestFullScreen ||
        (document.documentElement as any).webkitRequestFullscreen ||
        (document.documentElement as any).msRequestFullscreen)) &&
      !this._iframeService.inIframe();
  }

  private _requestFullscreen(): void {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if ((document.documentElement as any).mozRequestFullScreen) {
      (document.documentElement as any).mozRequestFullScreen();
    } else if ((document.documentElement as any).webkitRequestFullscreen) {
      (document.documentElement as any).webkitRequestFullscreen();
    } else if ((document.documentElement as any).msRequestFullscreen) {
      (document.documentElement as any).msRequestFullscreen();
    }
  }

  private _exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  }
}

