import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent {
  @Input() checked: boolean = false;
  @Input() labelKey: string;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Private onChange for form control
  private _onChangeFn: any;

  /**
   * Need to use any here as there appears to be no useful container...
   */
  public change(event: any): void {
    this.checked = event.target.checked;
    this.checkedChange.emit(this.checked);
    // If used as a form control it gets a change function
    if(this._onChangeFn) {
      this._onChangeFn(this.checked);
    }
  }

  /**
   * Needed as a form component
   */
  public writeValue(value: boolean): void {
    this.checked = value;
  }

  /**
   * Needed as a form component.
   * Note; this will become typed in some future angular version
   */
  public registerOnChange(fn: any): void {
    this._onChangeFn = fn;
  }

  // Not needed in use but need to be defined

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
  }
}
