import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalService} from "@src/app/services/modal/modal.service";
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Asset} from "@ess/jg-rule-executor";

@Component({
  selector: 'app-carousel-modal',
  templateUrl: './carousel-modal.component.html',
  styleUrls: ['./carousel-modal.component.scss']
})
export class CarouselModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  public list: Asset[];
  public index: number = null;

  constructor(
    private _modalService: ModalService
  ) {
  }

  ngAfterViewInit() {
    this._modalComponent.getModalArg("list")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (list: Asset[]) => {
          this.list = list;
        },
        error: _ => {
        }
      });
    this._modalComponent.getModalArg("index")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (index: number) => {
          this.index = index;
        },
        error: _ => {
        }
      });
  }

  close(): void {
    // reset values, so it shows the correct image the next time.
    this.index = null;
    this.list = null;
    this._modalService.close('carousel-modal');
  }

  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
