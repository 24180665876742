@if (loadingFinished) {
    <div class="plp-side-view-container">
        <h2 class="font-h2">
            {{ 'app.plp.side-menu.title' | translate }}
        </h2>
        <p class="font-body1 body-text">
            {{ 'app.plp.side-menu.description' | translate }}
        </p>
    </div>
}

