<app-modal id="wood-type-modal" fullscreen="true" #modalComponent>
    <div class="wood-type-modal-container">
        <h2 class="font-h2">{{ "app.wood_type.header" | translate }}</h2>
        <p class="font-body2 long-text" [innerHTML]="'app.wood_type.intro' | translate"></p>
        <p class="font-body2 underline-text">{{ "app.wood_type.intro_link" | translate }}</p>
        @if (!hideSelection) {
            <app-wood-type-selection></app-wood-type-selection>
        }
        <p class="font-body2 long-text" [innerHTML]="'app.wood_type.detail' | translate"></p>
    </div>
</app-modal>
