import {ErrorHandler} from "@angular/core";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {ErrorLoggingError} from "@src/app/model/errors";

/**
 * ErrorHandler composed of other ErrorHandlers.
 */
export class ComposedErrorHandler extends ErrorHandler {
  private handlers: ErrorHandler[];
  private logger: LoggingService;

  constructor(logger: LoggingService, ...handlers: ErrorHandler[]) {
    super();
    this.logger = logger;
    this.handlers = handlers;
  }

  handleError(error: any): void {
    const throws = [];
    this.handlers.forEach((h) => {
        try {
          h.handleError(error);
        } catch (e) {
          if (e !== error) {
            // Something else failed within the error handler
            throws.push(e);
          }
        }
      }
    );
    if (throws.length) {
      throws.forEach((e, i) => {
        try {
          this.logger.fatal(e);
        } catch {
          this.logger.fatal(new ErrorLoggingError(i));
        }
      });
    }
  }
}
