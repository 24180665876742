<app-modal id="invalid-config-modal" #modalComponent>
    <div class="invalid-config-modal-container">
        <h2 class="font-h2">{{ "app.modal.invalid-config.header" | translate }}</h2>
        <p class="font-body2 bottom-spacer">{{ "app.modal.invalid-config.body" | translate }}</p>

        @for (item of variantsWithCount; track item) {
            <div class="variant-list-item font-body2">
                @if (item.miniature) {
                    <img crossorigin="anonymous"
                         [src]="item.miniature.url"
                         (error)="handleImageError(item.miniature.id)"
                         class="variant-img">
                } @else {
                    <div class="variant-img">
                        <ng-content></ng-content>
                    </div>
                }
                <div class="counter">{{ item.count + "x" }}</div>
                <p>{{ item.title | translate }}</p>
                <!--    //FUNCTION IN TEMPLATE-->
                @if (item.count > 1) {
                    <div class="counter">({{ item.count }})</div>
                }
            </div>
        }
        <div class="buttons">
            <app-button class="del-button"
                        [buttonType]="buttonType"
                        [label]="'app.modal.invalid-config.remove-button' | translate"
                        (onClick)="removeParts()"
            ></app-button>
            <app-button class="replace-button"
                        [buttonType]="buttonType"
                        [label]="'app.modal.invalid-config.replace-button' | translate"
                        (onClick)="replaceParts()"
            ></app-button>
            <app-button class="skip-button"
                        [buttonType]="buttonType"
                        [label]="'app.modal.invalid-config.skip-button' | translate"
                        (onClick)=close()
            ></app-button>
        </div>
    </div>
</app-modal>
