@if (visible) {
    <div class="outside-menu"></div>
    <div class="menu-container">
        <button class="close-button clickable">
            <svg-icon (click)="closeMenu()" [src]="closeIconSource"></svg-icon>
        </button>
        @if (menuItems) {
            <div class="menu-item-container">
                @for (item of menuItems; track item) {
                    <div>
                        @if (!item.hide) {
                            <div
                                [class]="item.disabled ? 'menu-item disabled' : 'menu-item clickable'"
                                (click)="item.onClick()">
                                <div class="icon-container">
                                    <svg-icon class="icon" [src]=item.icon></svg-icon>
                                </div>
                                <div class="font-body1 item-text">{{ item.name | translate }}</div>
                            </div>
                        }
                        @if (item.addBarAfter && !item.hide) {
                            <div class="bottom-bar"></div>
                        }
                    </div>
                }
                @if (debugService.debug.debug) {
                    <div>
                        <div class="bottom-bar"></div>
                        <div class="menu-item clickable" (click)="debugService.activateDebug()">
                            <div class="icon-container">
                                <svg-icon class="icon" [src]=debugIcon></svg-icon>
                            </div>
                            <div class="font-body1 item-text">Debug</div>
                        </div>
                        <div class="menu-item clickable" (click)="downloadUsdz()">
                            <div class="icon-container">
                                <svg-icon class="icon" [src]=openIcon></svg-icon>
                            </div>
                            <div class="font-body1 item-text">Download USDZ</div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}
