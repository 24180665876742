import {Observable, of} from 'rxjs';

/**
 * This class demonstrates how to share logic between different platform-specific
 * implementations of a services (in this case the PlatformService).
 */
export class PlatformServiceShared {
  constructor(
    private _platform: string) {
  }

  getShared(): Observable<string> {
    return of(this._platform);
  }
}
