import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {ModalService} from "@src/app/services/modal/modal.service";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {Clipboard} from '@angular/cdk/clipboard';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAX_CONFIGURATOR_NAME_LENGTH, MAX_EMAIL_LENGTH} from "@src/app/constants";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {validateEmail, validateNoHtmlInjection} from "@src/app/validators/validators";
import {createConfigCodeUrl} from "@src/app/services/utils/text-utils";
import {IframeService} from "@src/app/services/iframe/iframe.service";
import {TranslateService} from "@ngx-translate/core";
import {ButtonType} from "@src/app/library/components/buttons/button.type";
import {RenderService} from "@src/app/services/render/render.service";

@Component({
  selector: 'app-save-modal',
  templateUrl: './save-modal.component.html',
  styleUrls: ['./save-modal.component.scss']
})
export class SaveModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public code: string;
  public email: string;
  public link: string;
  public submittedDesign: boolean = false;
  public editActive: boolean = false;
  public submittedAtLeastOnce: boolean = false;
  public isIframe: boolean = false;

  public MAX_EMAIL_LENGTH: number = MAX_EMAIL_LENGTH;
  public MAX_CONFIGURATOR_NAME_LENGTH: number = MAX_CONFIGURATOR_NAME_LENGTH;
  public copyIconSrc: string = iconSource(Icon.copy);

  public agreementString: string = '';

  public form: FormGroup = this._formBuilder.group({
    email: ['', [validateEmail, Validators.email, Validators.maxLength(MAX_EMAIL_LENGTH), Validators.required]],
    name: ['', [validateNoHtmlInjection, Validators.maxLength(MAX_CONFIGURATOR_NAME_LENGTH), Validators.required]],
    agreement: [false, [Validators.requiredTrue]]
  });

  public buttons: { name: string; iconSource: string, onClick: () => void }[] = [
    {
      name: "app.modal.share.whatsapp",
      iconSource: iconSource(Icon.whatsapp),
      onClick: () => this.shareWhatsApp()
    }, {
      name: "app.modal.share.email",
      iconSource: iconSource(Icon.email),
      onClick: () => this.shareEmail()
    }
  ];


  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _formBuilder: FormBuilder,
    private _modalService: ModalService,
    private _notificationService: NotificationService,
    private _clipboard: Clipboard,
    private readonly _eltRef: ElementRef,
    public _dataService: InitDataService,
    private _iframeService: IframeService,
    private _translateService: TranslateService,
    protected _renderService: RenderService
  ) {
    this.isIframe = this._iframeService.inIframe();
  }

  // convenience getter for easy access to form fields
  get f(): FormGroup['controls'] {
    return this.form.controls;
  }

  /**
   * onInit, subscribe to closeEmitter (so that we can refresh the modal if necessary)
   */
  public ngOnInit() {
    this._modalService.closeEmitter
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(id => {
        if (id === 'save-modal') {
          this.submittedDesign = false;
        }
      });
  }

  private _setAgreementString(): void {
    this.agreementString = (this._dataService.currentRetailerOptions ? this._dataService.currentRetailerOptions.agreementLinks : [])
      .map(l => `<a href="${l.href}" target="_blank">${l.text}</a>`).join(", ");
  }

  /**
   * afterViewInit, set up subscriptions on name and code
   * Also set the agreements as this component is initialized on startup, retailer options are not yet known
   * on setup of this component
   */
  public ngAfterViewInit() {
    this._modalComponent.getModalArg("code")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (code: any) => {
          this.code = code as string;

          this.link = createConfigCodeUrl(this.code);

          // Weird place but needed here as this component is initialized on app startup, at this point retailerOptions must be defined
          this._setAgreementString();
        },
        error: _ => {
        }
      });
    this._modalComponent.getModalArg("name")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (name: any) => {
          name = name as string;
          if (!name || name === 'Default config') {
            const date = new Date().toLocaleDateString().replace(/\//g, "-");
            this.f.name.setValue("MijnJungleGym_" + date);
          } else {
            this.f.name.setValue(name);
          }
        }
      });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  public edit(): void {
    this.editActive = !this.editActive;
  }

  /**
   * Handle data submit; validate and emit input, and set values where necessary
   */
  public submit(): void {
    this.f.email.setValue((this.f.email.value || '').trim());
    this.f.name.setValue((this.f.name.value || '').trim());

    this.f.email.updateValueAndValidity();
    this.f.email.updateValueAndValidity(); // removes 'wrong' error from previous failed attempt

    this.f.name.updateValueAndValidity();
    this.f.name.updateValueAndValidity();

    this.f.agreement.updateValueAndValidity();
    this.f.agreement.updateValueAndValidity();

    this.submittedAtLeastOnce = true;
    this.editActive = false;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // emit values from modalComponent to pass it up the chain
    this._modalComponent.outputEmitter.emit({
      code: this.code,
      name: this.f.name.value,
      email: this.f.email.value,
      hasAcceptedTerms: this.f.agreement.value // always true here as validity is OK :)
    });

    this.email = this.f.email.value;

    this.submittedDesign = true;
  }

  /**
   * close the modal
   */
  public close(): void {
    this.submittedDesign = false;
    this._modalService.close('save-modal');
  }


  /**
   * Open a new page to share the config link via WhatsApp
   */
  public shareWhatsApp(): void {
    const messageText = encodeURIComponent(this._translateService.instant('app.modal.share.whatsapp_message', {link: this.link, code: this.code}));
    window.open(`https://wa.me/?text=${messageText}`, "_blank");
  }

  /**
   * Open a new page to share the config link via WhatsApp
   */
  public shareEmail(): void {
    const subjectText = encodeURIComponent(this._translateService.instant('app.modal.share.email_subject'));
    const messageText = encodeURIComponent(this._translateService.instant('app.modal.share.email_message', {link: this.link, code: this.code}));
    window.open(`mailto:?subject=${subjectText}&body=${messageText}`, "_blank");
  }

  /**
   * Close this modal and open the share modal for the user to share their saved configuration
   */
  public share(): void {
    this.close();
    this._modalService.open('share-modal', {code: this.code});
  }

  /**
   * Copy link to clipboard and notify the user of this
   */
  public copyNotify(toClipboard: string): void {
    if (this._clipboard.copy(toClipboard)) {
      this._notificationService.showNotification("app.notifications.copy.success", Icon.copy, NotificationCardType.info);
    } else {
      this._notificationService.showNotification("app.notifications.copy.failed", Icon.copy, NotificationCardType.warning);
    }
  }

  protected readonly ButtonType = ButtonType;
}
