// Enumeration of all possible icons
// Files are stored in assets using the name.svg
export enum Icon {
  ar = 'ar',
  cart = 'cart',
  save = 'save',
  dots = 'dots',
  revert = 'revert',
  previewmode = 'previewmode',
  questionmark = 'questionmark',
  trash = 'trash',
  close = 'close',
  ruler = 'ruler',
  share = 'share',
  copy = 'copy',
  history = 'history',
  upload = 'upload',
  edit = 'edit',
  plus = 'plus',
  minus = 'minus',
  info = 'info',
  infoinverted = 'infoinverted',
  warn = 'warn',
  chevronl = 'chevronl',
  chevronr = 'chevronr',
  arrowl = 'arrowl',
  arrowr = 'arrowr',
  checkmark = 'checkmark',
  tool = 'tool',
  woodtype = 'woodtype',
  email = 'email',
  whatsapp = 'whatsapp',
  phone = 'phone',
  human = 'human',
  drill = 'drill',
  hammer = 'hammer',
  saw = 'saw',
  compass = 'compass',
  fullscreen = 'fullscreen',
  bulletpoint = 'bulletpoint',
  openfolder = 'openfolder',
  help = 'help',
  restart = 'restart',
  junglegymlogo = 'jg-logo',
}

/**
 * Creates the relative icon location for an icon
 */
export function iconSource(icon: Icon): string {
  return `/assets/icons/${icon}.svg`;
}
