<div class="main-container">
    @if (translationsAreLoaded) {
        <header class="header">
            <app-header [loadingFinished]="translationsAreLoaded" class="steps"></app-header>
            <app-icon-series-button
                id="menu-button"
                class="menu-button clickable"
                [buttons]="menuSelectionIcons"
                [styleInput]="'border rounded'"
                [clickDelay]="100"
                (onClick)="menuOrQuestionClick($event)"
                [normalIcon]="true"
            ></app-icon-series-button>
        </header>
    }
    <div class="{{
    this.viewMode === viewModeEnum.plp ? 'view-container plp-grid' :
    this.viewMode === viewModeEnum.preview ? 'preview-view-container view-grid' :
    'view-container view-grid'
    }}">
        <ng-container
            [ngTemplateOutlet]="
                viewMode === viewModeEnum.plp ? plp :
                viewMode !== viewModeEnum.loading ? edit :
                defaultContainer"
        ></ng-container>

        <ng-template #defaultContainer></ng-template>
        <ng-template #plp>
            <div class="plp-container portrait">
                <app-plp-side-view [loadingFinished]="translationsAreLoaded" class="plp-side-view"></app-plp-side-view>
                <app-preconfig-selection class="plp-view"></app-preconfig-selection>
            </div>
            <app-plp-side-view [loadingFinished]="translationsAreLoaded"
                               class="plp-side-view landscape"></app-plp-side-view>
            <app-preconfig-selection class="plp-view landscape"></app-preconfig-selection>
        </ng-template>
        <ng-template #edit>
            <app-spinner [finishedIndicator]="appInitialized$"
                         [initialToleranceMs]="initialTimeToleranceMs" class="canvas"
                         [displayText]="processingState | async"
                         [progress]="currentProgress$ | async"
            ></app-spinner>
            @if (showGeneralSpinner$ | async) {
                <app-spinner
                    [finishedIndicator]="generalSpinnerFinished$"
                    [initialToleranceMs]="generalSpinnerToleranceMs" class="canvas"
                    [displayText]="generalSpinnerText"
                ></app-spinner>
            }
            <app-canvas class="canvas"></app-canvas>
            <app-canvas-overlay [loadingFinished]="configAssetsLoaded && translationsAreLoaded"
                                class="canvas overlay"></app-canvas-overlay>
            <ng-container
                [ngTemplateOutlet]="
                    viewMode === viewModeEnum.edit ? editView :
                    viewMode === viewModeEnum.preview ? preView :
                    defaultView"
            ></ng-container>
            <ng-template #editView>
                <app-catalog [loadingFinished]="translationsAreLoaded" class="catalog"></app-catalog>
            </ng-template>
            <ng-template #preView>
                <app-result-view class="result-view"></app-result-view>
            </ng-template>
            <ng-template #defaultView></ng-template>
        </ng-template>
    </div>
    <app-footer [loadingFinished]="translationsAreLoaded" class="footer"></app-footer>
    <div class="menu-container">
        <app-menu class="menu" #menuComponent></app-menu>
    </div>
</div>
