<app-modal id="ar-instruction-modal" #modalComponent>
    <div class="ar-instruction-modal-container">
        <h2 class="font-h2 title-text">{{ "app.modal.ar.header" | translate }}</h2>
        @if (isIos) {
            <p class="font-body2 bottom-spacer" [innerHTML]="'app.modal.ar.instructions.ios' | translate">
            </p>
        } @else {
            <p class="font-body2 bottom-spacer">
                {{ "app.modal.ar.instructions.android" | translate }}
            </p>
        }
        <app-button class="modal-button"
                    [label]="'app.modal.ar.start' | translate"
                    [buttonType]="'primary'"
                    fillParent="true"
                    [rightIcon]="'chevronr'"
                    [smallIcon]="true"
                    [(disabled)]="disableStartButton"
                    (onClick)="toggleAR()"
        ></app-button>
    </div>
</app-modal>
