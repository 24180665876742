import {HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {Router} from '@angular/router';
import {InitDataService} from "@src/app/services/data/init-data.service";
import {AUTH_HEADER, BACKEND_AUTH_ERROR, UNAUTHENTICATED_ERROR} from "@src/app/constants";

/**
 * Interceptor to add and get authorisation cookie on request and response
 */
@Injectable()
export class AccessInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _dataService: InitDataService
  ) {
  }

  /**
   * Overridden intercept method
   * If requesting local resources or no token provided, simply handle the request
   * For non-local requests WITH token, add auth header.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.startsWith('/') || !this._dataService.authJwtToken) {
      return next.handle(request);
    } else {
      return next.handle(request.clone({
        headers: request.headers.set(AUTH_HEADER, `Bearer ${this._dataService.authJwtToken}`),
        withCredentials: true
      })).pipe(
          tap(x => {
            if(x instanceof HttpResponse && x.body && x.body.errors && x.body.errors.map(t => t?.extensions?.code).includes(BACKEND_AUTH_ERROR)) {
              window.alert(UNAUTHENTICATED_ERROR);
            }
          })
        );
    }
  }
}

export const accessInterceptorProvider = {
  provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true
};
