<div class="card" [ngClass]="{'card-selected': this.activeCode === this.data?.code}">
    <div class="checkmark-logo-container">
        @if (this.activeCode === this.data?.code) {
            <svg-icon [src]="checkMark" class="checkmark"></svg-icon>
        }
        <svg-icon [src]="jgLogoSrc" class="logo"></svg-icon>
    </div>
    <div class="card-content">
        <img [src]="data?.image?.url" alt="Card Image" class="card-image">
        <div class="card-content">
            <p class="font-body1 card-title">{{ data?.name }}</p>
            <h2 class="font-h3">{{ data?.price ? ('app.plp.card.from-price' | translate) + ' ' + formattedPrice : 'app.plp.card.no-price' }}</h2>
        </div>
    </div>
</div>
