import {Component, NgZone, OnInit} from '@angular/core';
import {Icon, iconSource} from "@src/app/library/components/icon";
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
        height: 0,
        marginTop: 0
      })),
      state('removed', style({
        opacity: 0,
        height: 0,
        marginTop: 0
      })),
      transition('inactive => active', animate('200ms ease-out', keyframes([
        style({
          transform: 'translate3d(-100%, 0, 0)',
          opacity: 0,
          height: 0,
          marginTop: 0
        }),
        style({
          opacity: 1,
          height: '*',
          marginTop: '*'
        }),
        style({
          transform: 'none',
          opacity: 1
        })
      ]))),
      transition('active => removed', animate('200ms ease-out', keyframes([
        style({
          opacity: 1,
          height: '*',
          marginTop: '*'
        }),
        style({
          opacity: 0,
          height: 0,
          marginTop: 0
        })
      ])))
    ])
  ],
  preserveWhitespaces: false
})
export class NotificationCardComponent extends Toast implements OnInit {
  constructor(toastrService: ToastrService, toastPackage: ToastPackage, ngZone?: NgZone) {
    super(toastrService, toastPackage, ngZone);
  }

  public leftIconSource: string;
  public closeIconSource: string = iconSource(Icon.close);

  /**
   * Set icon source
   */
  public ngOnInit() {
    this.leftIconSource = iconSource(Icon[this.title]); // We use the title field to pass on the icon, as we don't use titles anyway
  }
}
