import {EventEmitter, Injectable} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";

@Injectable({providedIn: 'root'})
export class ModalService {
  private _modals: ModalComponent[] = [];
  public disabled: boolean = false;

  public closeEmitter: EventEmitter<string> = new EventEmitter<string>();

  /**
   * internal function for modalComponent, do not use
   * used by modals to add themselves to the available modals
   */
  public add(modal: ModalComponent): void {
    // add modal to array of active modals
    this._modals.push(modal);
  }

  /**
   * internal function for modalComponent, do not use
   * used by modals to remove themselves from the available modals
   */
  public remove(id: string): void {
    // remove modal from array of active modals
    this._modals = this._modals.filter(x => x.id !== id);
  }

  /**
   * open the modal with the given Id (if it exists) and pass in any args, if any.
   *
   * @param id: the id for the modal (as defined in the html id field)
   * @param args: optional args object. Args can later be referenced from the child modal by name (see modal.component)
   * @return modalComponent: the component instance that was just opened (so we can access e.g. the output emitter)
   */
  public open(id: string, args?: object): ModalComponent {
    // open modal specified by id
    if (!this.disabled) {
      const modal = this._modals.find(x => x.id === id);
      modal?.open(args);
      return modal;
    }
  }

  /**
   * close the modal with the given Id if it exists
   */
  public close(id: string): void {
    // close modal specified by id
    const modal = this._modals.find(x => x.id === id);
    modal?.close();
  }
}
