import {Injectable} from "@angular/core";
import {environment} from "@src/environments/environment.local";
import {take} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";
import {ModalService} from "@src/app/services/modal/modal.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {IframeService} from "@src/app/services/iframe/iframe.service";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private _loggingService: LoggingService,
  ) {
  }

  /**
   * Add analytics header if relevant cookie is set.
   *
   * @private
   */
  public addAnalytics(): void {
    this._loggingService.debug("Setting analytics permissions to true.");
    // typescript complains that the dataLayer does not exist; that is correct, as it is a field specifically used
    // and created by Google Tag Manager. As such, we expect this object to have been created (see index.html)
    // but will safeguard it for good measure anyway.
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted'
    });
  }
}
