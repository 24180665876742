import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {BrowserOptions} from "@sentry/browser";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    config: BrowserOptions
  ) {
    Sentry.init(config);
  }

  handleError(error: any): void {
    // Don't log Chunk load errors to sentry. These errors occur when a chunk cannot be loaded, e.g.
    // because the connection is poor, or the chunk cannot be retrieved because of a new deploy.
    // Usual workaround is a reload. See https://github.com/webpack/webpack/issues/7502#issuecomment-439360421
    // Since we already give the user the option to reload when there is a new deploy, and we don't want to log this error
    // every time the user has a poor connection, we ignore the error
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (!chunkFailedMessage.test(error.message)) {
      Sentry.captureException(error.originalError || error.message || error.error || error);
      throw error;
    }
  }
}
