<app-modal id="save-modal" #modalComponent xmlns="http://www.w3.org/1999/html">
    @if (!submittedDesign && !isIframe) {
        <div class="save-modal-container">
            <div class="bottom-spacer">
                <h2 class="font-h2">{{ "app.modal.save.header1" | translate }}</h2>
                <p class="font-body2">{{ "app.modal.save.body1" | translate }}</p>
            </div>
            <!--        start of form         -->
            <form [formGroup]="form" (ngSubmit)="submit()" class="save-modal-form bottom-spacer">
                <label class="font-subtitle">{{ 'app.modal.save.input.email.label' | translate }}</label>
                <app-textfield id="email"
                               formControlName="email"
                               class="bottom-spacer"
                               placeholderKey="app.modal.save.input.email.placeholder"
                               [wideField]="true"
                               [value]="f.email.value"
                ></app-textfield>
                @if (submittedAtLeastOnce && f.email.errors) {
                    <div class="error-message font-body-small">
                        @if (f.email.errors?.maxlength) {
                            <span>{{ 'app.modal.save.input.email.max_length' | translate: {length: MAX_EMAIL_LENGTH} }}</span>
                        }
                        @if (f.email.errors?.required) {
                            <span>{{ 'app.modal.save.input.email.valid' | translate }}</span>
                        }
                        @if (f.email.errors?.email) {
                            <span>{{ 'app.modal.save.input.email.email' | translate }}</span>
                        }
                    </div>
                }
                <label class="font-subtitle">{{ 'app.modal.save.input.name.label' | translate }}</label>
                <app-textfield id="name"
                               formControlName="name"
                               class="bottom-spacer"
                               [wideField]="true"
                               [value]="f.name.value"
                ></app-textfield>
                @if (submittedAtLeastOnce && f.name.errors) {
                    <div class="error-message font-body-small">
                        @if (f.name.errors?.maxlength) {
                            <span>{{ 'app.modal.save.input.name.max_length' | translate: {length: MAX_CONFIGURATOR_NAME_LENGTH} }}</span>
                        }
                        @if (f.name.errors?.required) {
                            <span>{{ 'app.modal.save.input.name.valid' | translate }}</span>
                        }
                        @if (f.name.errors?.html) {
                            <span>{{ 'app.modal.save.input.name.html' | translate }}</span>
                        }
                    </div>
                }
                <p class="font-subtitle">{{ "app.modal.save.code.label" | translate }}</p>
                <p class="font-body2" [innerHTML]="'app.modal.save.code.text' | translate"></p>
                <h2 class="font-h2">{{ code }}</h2>
                <div class="bottom-spacer agreement-container">
                    <app-checkbox id="agreement"
                                  formControlName="agreement"
                                  [checked]="f.agreement.value"
                                  [labelKey]="'app.modal.save.agreement' | translate : {agreementString}"
                    ></app-checkbox>
                </div>
                @if (submittedAtLeastOnce && f.agreement.errors) {
                    <div class="error-message font-body-small">
                        @if (f.agreement.errors?.required) {
                            <span>{{ 'app.modal.save.input.agreement.valid' | translate }}</span>
                        }
                    </div>
                }
                <div class="button-container ">
                    <app-button class="modal-button"
                                [label]="'app.modal.save.buttons.email' | translate"
                                [buttonType]="'primary'"
                                fillParent="true"
                                [rightIcon]="'chevronr'"
                                [smallIcon]="true"
                                (onClick)="submit()"
                                [clickDelay]="1000"
                    ></app-button>
                </div>
                <p class="error-message font-body-small">{{ 'app.notifications.save.warning' | translate }}</p>
            </form>
            <!-- end of form-->
        </div>
    }
    @if (submittedDesign || isIframe) {
        <div class="save-modal-container-submitted bottom-spacer">
            <h2 class="font-h2">{{ "app.modal.save.header2" | translate }}</h2>
            <p class="font-body2 bottom-spacer">{{ "app.modal.save.body2" | translate }}
                <span>{{ email }}</span>.
            </p>
            @if (!isIframe) {
                <div>
                    <p class="font-body2">{{ "app.modal.share.intro" | translate }}</p>
                    <h3 class="font-h2">{{ code }}</h3>
                    <p class="font-subtitle">{{ "app.modal.share.send_with_link" | translate }}</p>
                    <div class="link-container bottom-spacer" (click)="copyNotify(link)">
                        <p class="font-body2 link">{{ link }}</p>
                        <svg-icon [src]="copyIconSrc"></svg-icon>
                    </div>
                </div>
            } @else {
                <div class="copy-container">
                    <app-textfield [isDisabled]="true" [value]="code"/>
                    <app-button
                        class="modal-button"
                        [buttonType]="ButtonType.secondary"
                        (click)="copyNotify(code)"
                        [label]="'app.modal.save.copy_code' | translate"
                    />
                </div>
            }
            <br>
            @if (!_dataService.currentRetailerOptions?.configuratorOptions?.hideShareContent) {
                <h2 class="font-h2">{{ "app.modal.share.header" | translate }}</h2>
                <div class="social-media-buttons bottom-spacer">
                    @for (button of buttons; track button) {
                        <div class="social-media-item" (click)="button.onClick()">
                            <button class="social-media-button">
                                <svg-icon class="icon" [src]="button.iconSource"></svg-icon>
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    }
</app-modal>
