@if (isSpinning) {
    <div class="spinner" (click)="$event.stopPropagation()">
        <img class="loading" src="assets/gifs/spinner.gif" alt="Loading..."/>
        @if (displayText) {
            <p>{{ displayText | translate }} @if (progress) {
                ({{ progress.cur }} / {{ progress.expect }})
            }</p>
        }
    </div>
}
