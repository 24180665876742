import {Component, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {ApplicationState} from "@src/app/model/applicationstate";
import {Icon, iconSource} from "@src/app/library/components/icon";

@Component({
  selector: 'app-installation-service-selection',
  templateUrl: './installation-service-selection.component.html',
  styleUrl: './installation-service-selection.component.scss'
})
export class InstallationServiceSelectionComponent implements OnDestroy {

  public currentInstallationPrice: string = '';
  public useInstallationService: boolean;
  public installationServiceAvailable: boolean;
  public selectedIconSrc: string = iconSource(Icon.checkmark);

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _priceCalculatorService: PriceCalculatorService,
    private _applicationStateService: ApplicationStateService
  ) {
    // This is needed as the installation service isn't immediately available, but you can already open the modal.
    // When the app finishes loading the modal changes and you get the option the select the installation service.
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.appInitialized), take(1), takeUntil(this._componentDestroyed$)
    ).subscribe(_ => {
      this.installationServiceAvailable = this._priceCalculatorService.installationServiceAvailableForConfiguration();
    });
    this._priceCalculatorService.currentPrice$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(price => {
        this.currentInstallationPrice = this._priceCalculatorService.priceFormatter(
          this._priceCalculatorService.getCurrentInstallationPrice()
        );
      });
    this._priceCalculatorService.installationServiceChanged$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(useInstallation =>
        this.useInstallationService = useInstallation
      );
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * Save the installation service setting
   */
  setInstallationService(value: boolean): void {
    this.useInstallationService = value;
    this._priceCalculatorService.installationServiceChange(value);
  }
}

