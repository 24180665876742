import dayjs from "dayjs";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {InvalidDateError} from "@src/app/model/errors";

export const localIsoFormat = "YYYY-MM-DDTHH:mm:ss";
export const offsetISOFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const zeroTimeISOFormat = 'YYYY-MM-DDT00:00:00Z';
export const textFieldDateFormat = "dd-MM-yyyy";

/**
 * Deletes the time aspect from a date
 *
 * @param date Date with date and timezone information
 * @returns a new Date instance with the date and timezone information from the given date, but with the time information set to 00:00:00
 */
export function zeroTime(date: Date): Date {
  return new Date(dayjs(date).format(zeroTimeISOFormat));
}

/**
 * Returns the timezone postfix for an iso date string;
 * possible returned value formats are: 'Z', '+01:00', '-02:00', ''.
 */
export function timezonePostfix(isodate: string): string {
  // 2021-06-15T00:00:00Z
  if (isodate.match(/\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}Z/)) {
    return 'Z';
  }

  // 2021-06-15T00:00:00+02:00
  // 2021-06-15T00:00:00-02:00
  if (isodate.match(/\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}[+-]\d{2}\:\d{2}/)) {
    const time = isodate.split('T')[1];

    let symbol = '+';
    let split = time.split(symbol);

    if (split.length < 2) {
      symbol = '-';
      split = time.split(symbol);
    }

    return symbol + split[1];
  }

  return '';
}

export function removeTimezoneInformation(date: string, loggingService: LoggingService): string {
  // 2021-06-15T00:00:00Z
  if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}Z/)) {
    // drop the Z UTC-timezone postfix
    return date.replace(/Z$/, '');
  }

  // 2021-06-15T00:00:00+02:00 and 2020-12-16T10:48:15.738+01:00
  if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}(\.\d{1,})?[+-]\d{2}\:\d{2}/)) {
    // drop the +XX:XX timezone offset postfix
    return date.replace(/[+-]\d{2}\:\d{2}/, '');
  }

  // already in correct format
  if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}/)) {
    return date;
  }

  loggingService.error(new InvalidDateError(date));
  return date;
}

/**
 * Changes the given date to the local offset time.
 *
 * @param loggingService Logger used
 * @param date; datetime timestamp with timezone information in the ISO format;
 *  - 2021-06-15T00:00:00+02:00
 *  - 2021-06-15T00:00:00Z
 * @param localTime (optional, will default to system's timezone offset) a string
 *    representation (in ISO format) of a timezone offset;
 *  - '-01:00'
 *  - '+03:00'
 */
export function originalTimeAsLocalTime(loggingService: LoggingService, date: string, localTime?: string): string {
  // default to local timezone offset postfix
  const withoutTimezone = removeTimezoneInformation(date, loggingService);
  const tzPostfix = localTime || timezonePostfix(dayjs(new Date(withoutTimezone)).format(offsetISOFormat));
  return `${withoutTimezone}${tzPostfix}`;
}

/**
 * Sort function for date formats
 *
 * @param lhs Lefthandsize to compare
 * @param rhs Righthandside to compare
 */
export function dateSortFn(lhs: Date, rhs: Date): number {
  if (lhs < rhs) {
    return -1;
  } else if (rhs < lhs) {
    return 1;
  } else {
    return 0;
  }
}

