<!--Button component-->
<!--Has different classes for different button styles-->
<button type="button"
        [class]="'button ' + buttonType + (fillParent ? ' fill-parent' : '')"
        [ngClass]="{
          'circle-button': circleButton,
          'small-button': smallButton,
          'next-button': ovalButton,
          'correct-left': circleButton && leftIcon == 'chevronr',
          'correct-right': circleButton && leftIcon == 'chevronl',
          'border-color':  borderColor,
        }"
        [disabled]="disabled"
        (click)="onClickFn()">
    <!--Left icon-->
    <svg-icon [src]="hideLeftIcon ? undefined : leftIconSource" class="icon left"
              [ngClass]="{
              'small-icon': smallIcon,
              'normal-icon': normalIcon
              }"></svg-icon>
    <!--Label-->
    <span
            class="font-button button-text"
            [ngClass]="{
                'normal-weight': !boldText
            }"
    >{{label}}</span>
    <!--Right icon-->
    <svg-icon [src]="hideRightIcon ? undefined : rightIconSource" class="icon right"
              [ngClass]="{
              'small-icon': smallIcon,
              'normal-icon': normalIcon
              }"></svg-icon>
</button>
