import { Injectable } from '@angular/core';
import * as Comlink from "comlink";

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  private worker: Worker;
  private workerApi: any;

  constructor() {
  }

  public initializeWorkerPool() {
    if (!this.worker) {
        this.worker = new Worker(new URL('../../../assets/workers/generic-worker.js', import.meta.url));
        this.workerApi = Comlink.wrap<any>(this.worker);
    }
  }

  public async runTask(funcWithBind: Function) {
    await this.workerApi.setFunction(Comlink.proxy(funcWithBind));
    return this.workerApi;
  }

  // Clean up workers if needed
  public terminateWorkers() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }
}
