import {HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {mergeMap, Observable, timer} from 'rxjs';
import {InitDataService} from "@src/app/services/data/init-data.service";
import {SCOPE_HEADER} from "@src/app/constants";
import {LoggingService} from "@src/app/services/logging/logging.service";

/**
 * Interceptor to add scope
 */
@Injectable()
export class ScopeInterceptor implements HttpInterceptor {
  private _scopeWaitingTimeMs = 100;

  constructor(
    private _dataService: InitDataService,
    private _loggingService: LoggingService,
  ) {
  }

  /**
   * Adds scope to each request.
   * If scope undefined and fetching fails, add nothing and just return next handler, but do try again after a set time
   *
   * Note that this interceptor is not async on purpose :)
   * Async interceptors don't seem to play well with Apollo.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.startsWith('/')) { // Internal Url's, so don't care about scope
      return next.handle(request);
    } else if (this._dataService.currentScope) { // Otherwise we need scope!
      return next.handle(request.clone({
        headers: request.headers.set(SCOPE_HEADER, this._dataService.currentScope)
      }));
    } else { // If scope not set, wait a while and try again
      return timer(this._scopeWaitingTimeMs).pipe(mergeMap(_ => this.intercept(request, next)));
    }
  }
}

export const scopeInterceptorProvider = {
  provide: HTTP_INTERCEPTORS, useClass: ScopeInterceptor, multi: true
};
