import {Attribute, id, Id, ModuleVariant} from "@ess/jg-rule-executor";
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapContainsValuePipe'
})
export class MapContainsValuePipe implements PipeTransform {
    transform(map : Map<any,any>,key : any , value : any): boolean {
        return map?.get(id<Attribute>(key)) === value;
    }
}