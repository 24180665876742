import {VariantWithCount} from "@src/app/model/variant_with_count";

export class CategoryResultInfo {
  public categoryTitle: string;
  public variantsResultInfo: VariantWithCount[];

  constructor(categoryTitle: string, variantsResultInfo: VariantWithCount[]) {
    this.categoryTitle = categoryTitle;
    this.variantsResultInfo = variantsResultInfo;
  }
}
