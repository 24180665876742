import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer";
import {environment} from "@src/environments/environment";

export function createText(
  text: string,
  styleMap: Map<string, string>, // key-value props
  parentStyleMap: Map<string, string> = new Map(),
  className: string = '',
  id: string = text,
): CSS2DObject {
  const label = document.createElement('div');
  label.className = "label no-z-index";
  parentStyleMap.forEach((value, key) => label.style.setProperty(key, value));

  const labelChildDiv = document.createElement('div');
  labelChildDiv.className = className;
  labelChildDiv.id = id;
  labelChildDiv.textContent = text;
  styleMap.forEach((value, key) => labelChildDiv.style.setProperty(key, value));

  label.appendChild(labelChildDiv);
  const textObj = new CSS2DObject(label);
  textObj.layers.set(0);
  return textObj;
}

export function createConfigCodeUrl(
  code: string
): string {
  const url: string = environment.baseUrl;
  const parameters: string[] = location.search
    .slice(1) // remove the question mark at the beginning of the search string
    .split("&")
    .filter(p => p.includes("s=") || p.includes("scope=")) // remove all url parameters expect the scope


  return url + "?" + [...parameters, `c=${code}`].join("&");
}

// eslint-disable-next-line max-len
export const bulletpointSVG : string = `<svg class="svg-override svg-size-override" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <path d="M11.1767 12.9403V16.1672C11.1767 16.4146 11.2601 16.6219 11.4269 16.7893C11.5937 16.9567 11.8024 17.0403 12.0528 17.0403C12.3033 17.0403 12.511 16.9572 12.6758 16.791C12.8407 16.6247 12.9231 16.4165 12.9231 16.1662V12.9403H16.1666C16.414 12.9403 16.6214 12.8569 16.7887 12.6902C16.9561 12.5234 17.0398 12.3147 17.0398 12.0642C17.0398 11.8137 16.9567 11.6061 16.7904 11.4412C16.6242 11.2764 16.4159 11.194 16.1656 11.194H12.9231V7.8338C12.9231 7.5864 12.8397 7.37902 12.6729 7.21165C12.5061 7.0443 12.2975 6.96063 12.047 6.96063C11.7965 6.96063 11.5888 7.04374 11.424 7.20998C11.2592 7.37621 11.1767 7.58448 11.1767 7.8348V11.194H7.83324C7.58584 11.194 7.37846 11.2774 7.21109 11.4442C7.04374 11.6109 6.96007 11.8196 6.96007 12.0701C6.96007 12.3206 7.04319 12.5282 7.20942 12.6931C7.37565 12.8579 7.58393 12.9403 7.83424 12.9403H11.1767ZM12.0049 22.1679C10.5976 22.1679 9.27695 21.9016 8.04302 21.3689C6.80907 20.8363 5.73182 20.1097 4.81127 19.1891C3.89072 18.2686 3.16413 17.1911 2.63149 15.9567C2.09884 14.7223 1.83252 13.401 1.83252 11.9927C1.83252 10.5844 2.09884 9.26292 2.63149 8.02815C3.16413 6.79337 3.89051 5.71841 4.81064 4.80328C5.73078 3.88814 6.80815 3.16367 8.04275 2.62985C9.27734 2.09602 10.5989 1.8291 12.0074 1.8291C13.4159 1.8291 14.7376 2.09591 15.9727 2.62953C17.2078 3.16316 18.2828 3.88737 19.1977 4.80215C20.1127 5.71692 20.837 6.79263 21.3707 8.0293C21.9044 9.26595 22.1713 10.5882 22.1713 11.9961C22.1713 13.4037 21.9044 14.7245 21.3705 15.9584C20.8367 17.1923 20.1123 18.2684 19.1971 19.1865C18.282 20.1047 17.2063 20.8312 15.9701 21.3659C14.734 21.9005 13.4122 22.1679 12.0049 22.1679Z"/> </svg>`

