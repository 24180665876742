<div class="result-view-container">
    <div class="title-description">
        <h2 class="font-h2">{{ "app.result_view.inspect.title" | translate }}</h2>
        <p class="font-body1" [innerHTML]="'app.result_view.inspect.text' | translate"></p>
    </div>
    <!--    //FUNCTION IN TEMPLATE-->
    @if (allUsedBlueprintAssets?.size > 0) {
        <div class="dropdown">
            <div class="blueprint-pictures">
                @for (item of allUsedBlueprintAssets; track item; let index = $index) {
                    <img crossorigin="anonymous"
                         (click)="openCarousel(index)"
                         [src]="item.url"
                         (error)="handleImageError(item.id)"
                         class="variant-img pointer" [ngStyle]="{'grid-column': (index % 6 + 1)}">
                }
            </div>
        </div>
    }
    @if (multipleWoodTypes) {
        <div class="wood-type-container">
            <div class="wood-title-container">
                <p class="font-h3 wood-title">{{ "app.wood_type.header" | translate }}</p>
                <svg-icon class="icon" [src]="iconSource(Icon.infoinverted)" (click)="openWoodType()"/>
            </div>
            <app-wood-type-selection></app-wood-type-selection>
        </div>
    }
    @if (showInstallation) {
        <p class="font-h3 wood-title">{{ "app.modal.installation_service.header" | translate }}</p>
        <app-installation-service-selection />
    }
    <div class="dropdown">
        @if (!multipleWoodTypes) {
            <div class="variant-list-item">
                <img crossorigin="anonymous"
                     [src]="'/assets/icons/' + woodType + '.jpg'"
                     class="variant-img">
                {{ 'app.woodtype.' + woodType | translate }}
            </div>
        }
        @for (categoryResultInfo of categoriesDisplay; track categoryResultInfo) {
            @if (categoryResultInfo.variantsResultInfo.length > 0) {
                <p class="font-h3 category-title">{{ categoryResultInfo.categoryTitle }}</p>
            }
            @for (item of categoryResultInfo.variantsResultInfo; track item) {
                <div class="variant-list-item font-body2">
                    @if (item.miniature) {
                        <img crossorigin="anonymous"
                             [src]="item.miniature.url"
                             (error)="handleImageError(item.miniature.id)"
                             class="variant-img">
                    } @else {
                        <div class="variant-img">
                            <ng-content></ng-content>
                        </div>
                    }
                    <!--    //FUNCTION IN TEMPLATE-->
                    <h3 class="font-h3 counter">{{ item.count + "x " }}</h3>
                    <p class="font-body1">{{ item.title | translate }}</p>
                </div>
            }
        }
    </div>
    <p class="font-body1">{{ "app.result_view.contains.delivery" | translate }}</p>
    <p class="font-body2 warning" [innerHTML]="'app.result_view.warning' | translate"></p>
</div>

