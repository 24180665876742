<div class="modal-background"></div>
<div class="modal" [ngClass]="{'fullscreen-modal': fullscreen, 'normal-modal': normalScreen, 'in-iframe': isInFrame}">
    <div class="modal-body">
        @if (!blocking) {
            <div class="close-button" (click)="close()">
                <svg-icon class="icon" [src]="closeSrc"></svg-icon>
            </div>
        }
        <ng-content></ng-content>
    </div>
</div>
