import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {firstValueFrom, Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CODE_LENGTH} from "@src/app/constants";
import {take, takeUntil} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ButtonType} from "@src/app/library/components/buttons/button.type";
import {EntryPointService} from "@src/app/services/entry-point/entry-point.service";

@Component({
  selector: 'app-open-modal',
  templateUrl: './open-modal.component.html',
  styleUrls: ['./open-modal.component.scss']
})
export class OpenModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public CODE_LENGTH = CODE_LENGTH;
  public submittedAtLeastOnce = false;
  public waitingForResponse = false;
  public configNotFound = false;

  public findingInitialConfig = false;
  public configCode: string = '';
  protected readonly ButtonType = ButtonType;

  public form: FormGroup = this._formBuilder.group({
    code: [this.configCode, [Validators.required, Validators.maxLength(CODE_LENGTH), Validators.minLength(CODE_LENGTH)]]
  });

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _modalService: ModalService,
    private _formBuilder: FormBuilder,
    private _dataService: InitDataService,
    private _entryPointService: EntryPointService
  ) {
  }

  // convenience getter for easy access to form fields
  get f(): FormGroup['controls'] {
    return this.form.controls;
  }

  /**
   * onInit, subscribe to closeEmitter (so that we can refresh the modal if necessary)
   */
  public ngOnInit() {
    this._modalService.closeEmitter
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(id => {
        if (id === 'open-modal') {
          if (this.findingInitialConfig && this.configNotFound) {
            this._modalComponent.outputEmitter.emit({
              newConfigCode: undefined
            });
          }

          // reset the open modal
          this.f.code.reset();
          this.configCode = '';

          this.findingInitialConfig = false;
          this.submittedAtLeastOnce = false;
          this.configNotFound = false;
        }
      });
  }

  /**
   * afterViewInit, set up subscription on code
   */
  public ngAfterViewInit() {
    this._modalComponent.getModalArg("code")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (code: any) => {
          //If the modal can't find the argument it returns undefined at the moment
          if (code !== undefined) {
            this.configCode = code as string;
            // When a code is supplied, this means the code has already been tried and no config was found
            this.form.patchValue({code});
            this.findingInitialConfig = true;
            this.submittedAtLeastOnce = true;
            this.configNotFound = true;
          }
        },
        error: _ => {
        }
      });
  }

  // Close me up
  public close(): void {
    this._modalService.close('open-modal');
  }

  // Submit entered code
  async onSubmit(): Promise<void> {
    this.f.code.updateValueAndValidity();
    this.f.code.updateValueAndValidity(); // Removes 'wrong' error from previous failed attempt

    // Variables to check if we should show errors
    this.submittedAtLeastOnce = true;
    this.configNotFound = false;
    if (this.f.code.valid) {
      this.waitingForResponse = true;
      await this._entryPointService.initializeApp$(this._dataService.currentRetailerOptions, this.f.code.value);
      this.waitingForResponse = false;
      this.close();
    }
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
