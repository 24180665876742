import {FormControl, FormGroup, ValidationErrors} from "@angular/forms";

/**
 * General function to make some validators
 */
function validatorFromRegex(c: FormControl, regex: string, errorTag: string): ValidationErrors | null {
  const reg = new RegExp(regex);
  return reg.test(c.value) ? null : {
    [errorTag.toString()]: { // tostring to stop intellij from complaining
      valid: false
    }
  };
}

/**
 * Validates at least one digit is in the field
 */
export function validateDigit(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(c, "\\S*([0-9])+\\S*", "digit");
}

/**
 * Validates at least one upper case letter is in the field
 */
export function validateUpperCase(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(c, "\\S*([A-Z])+\\S*", "upperCase");
}

/**
 * Validates at least one lower case letter is in the field
 */
export function validateLowerCase(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(c, "\\S*([a-z])+\\S*", "lowerCase");
}

/**
 * Validates at least one special character is in the field
 * I.e. not a letter, number or whitespace
 */
export function validateSpecialChar(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(c, "\\S*([^A-Za-z0-9\\s])+\\S*", "specialChar");
}

/**
 * Validates if some input is a valid zipcode area (just the 4 digits)
 */
export function validateZipCode(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(c, "^[1-9]\\d{3}$", "zipCode");
}

/**
 * Validates if an email address is valid (more comprehensive than the standard one)
 */
export function validateEmail(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(
    c,
    "^[a-zA-Z0-9.!#$%&'*+\\/=?^_~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+)+$",
    "email"
  );
}

/**
 * Validates if html characters are used (this is not allowed)
 */
export function validateNoHtmlInjection(c: FormControl): ValidationErrors | null {
  return validatorFromRegex(
    c,
    "^[^<>/]*$",
    "html"
  );
}

/**
 * Validates if some input is a valid number (it doesn't accept a comma as decimal separator).
 */
export function validateNumber(c: FormControl): ValidationErrors | null {
  return !isNaN(Number(c.value)) ? null : {
    ["number"]: {
      valid: false
    }
  };
}

/**
 * Validates if a number field is a whole number
 */
export function validateWhole(c: FormControl): ValidationErrors | null {
  // don't validate non-existing values
  if (c.value === undefined || c.value === null) {
    return null;
  }

  return !isNaN(Number(c.value)) && Number.isInteger(Number(c.value)) ? null : {
    ["wholeNumber"]: {
      valid: false
    }
  };
}

/**
 * Cross field validator for checking two fields to equal each other
 */
export function validateEqualsOther(thisKey: string, otherKey: string, tag?: string): (fg: FormGroup) => ValidationErrors | null {
  return (fg: FormGroup) => {
    tag = tag || 'equals' + otherKey.charAt(0).toUpperCase() + otherKey.slice(1).toLowerCase();
    return fg.controls[thisKey].value === fg.controls[otherKey].value ? null : {
      [tag]: {
        valid: false
      }
    };
  };
}

/**
 * Cross field validator for checking two fields to equal each other (Case-insensitive)
 */
export function validateEqualsOtherCaseInsensitive(thisKey: string, otherKey: string, tag?: string): (fg: FormGroup) => ValidationErrors | null {
  return (fg: FormGroup) => {
    tag = tag || 'equals' + otherKey.charAt(0).toUpperCase() + otherKey.slice(1).toLowerCase();

    if (!!fg.controls[thisKey].value && !!fg.controls[otherKey].value) {
      return fg.controls[thisKey].value.toLowerCase() === fg.controls[otherKey].value.toLowerCase() ? null : {
        [tag]: {
          valid: false
        }
      };
    } else {
      return null;
    }
  };
}
