import {InMemoryCache} from "@apollo/client/core";
import dayjs from "dayjs";
import {localIsoFormat, originalTimeAsLocalTime} from "@src/app/helpers/date.helpers";
import {LoggingService} from "@src/app/services/logging/logging.service";

type DateField = {
  read(existing: any): any;
  merge(existing: string | null, incoming: any | null): string;
};

/**
 * Date field constant to parse from OffsetDateTime string from Scala.
 */
function dateField(loggingService: LoggingService): DateField {
  return {
    read(existing: any): any {
      // return null instead of undefined
      if (!existing) {
        return null;
      }
      return existing;
    },
    merge(existing: string | null, incoming: any | null): string {
      return JgApolloMemoryCache.ignoreTimezone(loggingService, existing, incoming);
    }
  };
}

/**
 * Handling the caching.
 */
export class JgApolloMemoryCache extends InMemoryCache {
  constructor(loggingService: LoggingService) {
    super({
      typePolicies: {
        UndoConfiguration: {
          fields: {
            date: dateField(loggingService)
          }
        }
      }
    });
  }

  /**
   * Convert a timestamp (either from the backend, or from a mutation)
   * into a timestamp for the local timezone, ignoring the original timezone
   * and making sure the timestamp will always appear in the _original_ local time.
   *
   * So given the following incoming timestamp; 2020-01-02T13:45:00+03:00
   * And the following local timezone offset: +01:00
   * It will give: 2020-01-02T13:45:00+01:00
   *
   * We need to explicitly add the local timezone for consistent results, without timezone
   * offset safari will assume UTC timezone, while other browsers will assume local timezone.
   */
  public static ignoreTimezone(loggingService: LoggingService, existing: string | null, incoming: any | null): string {
    if (typeof incoming === 'string') {
      return originalTimeAsLocalTime(loggingService, incoming);
    }

    if (incoming instanceof Date) {
      return dayjs(incoming).format(localIsoFormat);
    }

    return existing;
  }
}
