import {Id, ModulePlacement} from "@ess/jg-rule-executor";
import {SpriteData} from "@src/app/model/sprite-data";
import {Sprite} from "three";

export class EditPointInfo {
  public placementId: Id<ModulePlacement>;

  constructor(
    placementId: Id<ModulePlacement>,
  ) {
    this.placementId = placementId;
  }

  static fromSpriteData(spriteData: SpriteData) {
    return new EditPointInfo(
      spriteData.modulePlacementId
    );
  }

  /**
   * create EPInfo from sprite.
   * DOES NOT DO NULL CHECK
   */
  static fromSprite(sprite: Sprite) {
    const spriteData: SpriteData = SpriteData.fromSprite(sprite);
    return EditPointInfo.fromSpriteData(spriteData);
  }
}
