// Obj3D Userdata variables
export const MODULE_PLACEMENT_ID: string = "modulePlacementId";
export const DEBUG: string = 'DEBUG';
export const SPRITE_DATA: string = 'SPRITE_DATA';

// Interceptor variables
export const SCOPE_HEADER: string = 'Scope';
export const AUTH_HEADER: string = 'Authorization';
export const BACKEND_AUTH_ERROR: string = 'backend.errors.authorization.user_not_authorized';
export const UNAUTHENTICATED_ERROR: string = 'You\'re no longer authenticated. Make sure you refresh your portal session';

// input validation values
export const CODE_LENGTH: number = 6;
export const MAX_CONFIGURATOR_NAME_LENGTH: number = 255;
export const MAX_EMAIL_LENGTH: number = 255;

// default values
export const DEFAULT_CONFIG_CODE: string = "000000";
export const DEFAULT_CURRENCY: string = "EUR";
export const DEFAULT_LOCALE: string = "nl_NL";

// redirect settings
export const SCOPE_NOT_FOUND_REDIRECT = 'https://home.mycustomplayground.com';
