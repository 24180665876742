import {Injectable} from '@angular/core';
import {JSON} from 'ta-json';
import {StorageSelectors} from './storage.selectors';

/**
 * Provides function to save and load information
 * securely on local device.
 */
@Injectable()
export class StorageService {

  /**
   * Reads from the secured storage.
   * If reading fails, returns null.
   */
  public readFromStorage<T>(selector: StorageSelectors): T | undefined {
    const raw = localStorage.getItem(selector);
    if (raw) {
      return JSON.deserialize<T>(JSON.parse(raw || '{}'));
    } else {
      return undefined;
    }
  }

  /**
   * Writes to secured storage.
   */
  public setInStorage<T>(selector: StorageSelectors, value: T): void {
    const raw = JSON.stringify(value);
    localStorage.setItem(selector, raw);
  }

  /**
   * Removes stored object from secured storage.
   */
  public removeFromStorage(selector: StorageSelectors): void {
    localStorage.removeItem(selector);
  }

  /**
   * Removes everything from the storage
   */
  public clearStorage(): void {
    localStorage.clear();
  }
}
