/* eslint-disable max-classes-per-file */
import {Catalog, CatalogCategory, Id, ModuleVariant} from "@ess/jg-rule-executor";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";

export class FatFlatCatalog {
  id: Id<Catalog>;
  categories: FatFlatCatalogCategory[];

  constructor(
    moduleManagementService: ModuleManagementService,
    catalog: ConfiguratorCatalog
  ) {
    this.id = catalog.id;
    this.categories = catalog.categories?.map(category => new FatFlatCatalogCategory(moduleManagementService, category));
  }
}

export class FatFlatCatalogCategory {
  title: string;
  variants: ModuleVariant[];
  invalidVariants: ModuleVariant[];

  // constructor maps a proper CatalogCategory to the flat structure w/ full variants for use in the CatalogComponent
  constructor(
    moduleManagementService: ModuleManagementService,
    catalogCategory: CatalogCategory
  ) {
    this.title = catalogCategory.title;
    this.variants = catalogCategory.moduleCategories.reduce((acc, cur) => {
      const variants: ModuleVariant[] = cur.moduleVariantIds.map(id => moduleManagementService.getFatModuleVariantById(id).variant);
      return [...acc, ...variants];
    }, new Array<ModuleVariant>());
  }

  cloneWithTitleOnly(): FatFlatCatalogCategory {
    return {
      title: this.title,
      variants: [],
      invalidVariants: []
    } as FatFlatCatalogCategory
  }
}
