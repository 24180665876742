import {Injectable} from '@angular/core';
import {RouterService} from './router.service.interface';
import {Router} from '@angular/router';

@Injectable()
export class RouterServiceImpl extends RouterService {

  constructor(
    private _router: Router
  ) {
    super();
  }

  /**
   * Navigates user back to previous page in the route tree.
   */
  public navigateBack(): void {
    this._router.navigate(['..']);
  }

}
