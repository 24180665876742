<app-modal id="plp-modal" #modalComponent>
    <ng-container
        [ngTemplateOutlet]="!this.submittedDesign && !this.code ? confirmation : afterSave"
    ></ng-container>
    <ng-template #confirmation>
        @if (!submittedDesign) {
            <div class="plp-modal-container">
                <div class="bottom-spacer">
                    <h2 class="font-h2">{{ "app.modal.plp.confirm.title" | translate }}</h2>
                    <p class="font-body2">{{ "app.modal.plp.confirm.info" | translate }}</p>
                </div>
                <div class="button-container">
                    <app-button class="modal-button"
                                [label]="'app.modal.plp.buttons.save' | translate"
                                [buttonType]="'secondary'"
                                fillParent="true"
                                (onClick)="saveConfig()"
                                [clickDelay]="1000"
                    ></app-button>
                    <app-button class="modal-button"
                                [label]="'app.modal.plp.buttons.dont-save' | translate"
                                [buttonType]="'secondary'"
                                fillParent="true"
                                (onClick)="doNotSaveConfig()"
                                [clickDelay]="1000"
                    ></app-button>
                </div>
            </div>
        }
    </ng-template>

    <ng-template #afterSave>
        <div class="save-modal-container">
            <div class="bottom-spacer">
                <h2 class="font-h2">{{ "app.modal.plp.confirm.saved" | translate }}</h2>
                <p class="font-body2">{{ "app.modal.plp.confirm.code" | translate }}</p>
                <h2 class="font-h2 primary-color-light">{{ code }}</h2>
            </div>
            <app-button class="modal-button"
                        [label]="'app.modal.plp.buttons.close' | translate"
                        [buttonType]="'primary'"
                        fillParent="true"
                        [rightIcon]="'chevronr'"
                        [smallIcon]="true"
                        (onClick)="doNotSaveConfig()"
                        [clickDelay]="1000"
            ></app-button>
        </div>
    </ng-template>
</app-modal>
