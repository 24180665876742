import {Component, OnInit} from '@angular/core';
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ApolloService} from "@src/app/services/apollo/apollo.service";
import {firstValueFrom, Observable, of} from "rxjs";
import {ConfiguratorPreConfiguration} from "@src/app/model/configurator-preconfiguration";

@Component({
  selector: 'app-preconfig-selection',
  templateUrl: './preconfig-selection.component.html',
  styleUrls: ['./preconfig-selection.component.scss']
})
export class PreconfigSelectionComponent implements OnInit {

  public preconfigurations: Observable<ConfiguratorPreConfiguration[]>;

  public activeCode: string = '';
  public longestName: Observable<string>;
  private internalLongestName: string = '';

  public showLogo: boolean;

  constructor(
    private _dataService: InitDataService,
    private _apolloService: ApolloService
  ) {
    this.showLogo = this._dataService?.currentRetailerOptions?.configuratorOptions?.bottomLogo;
  }

  async ngOnInit() {
    this.preconfigurations = this._apolloService.getPreconfigurationsByScope$(this._dataService.currentScope)
    const configs = await firstValueFrom(this.preconfigurations);
    configs.map(preconfig => {
      // This could theoretically lead to incorrect results as not all characters are the same length, and it just compares the number of characters
      if (preconfig.name.length > this.internalLongestName.length) {
        this.internalLongestName = preconfig.name;
      }
    });
    this.longestName = of(this.internalLongestName);
  }

  selectConfiguration(code: string, price: number): void {
    this._dataService.currentPreConfigurationCode = code;
    this.activeCode = code;
    this._dataService.currentPreConfigurationPrice$.next(price);
  }
}
