import {Injectable, OnDestroy} from '@angular/core';
import {ArrowHelper, Box3, Group, Object3D, Vector3} from "three";
import {RenderService} from "@src/app/services/render/render.service";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {createText} from "@src/app/services/utils/text-utils";
import {ViewMode} from "@src/app/model/view-mode";

@Injectable({
  providedIn: 'root'
})
export class MeasurementService implements OnDestroy {
  // Set a scale to multiply the dimensions of the configuration by
  private _SCALE: number = 1;
  // This offset is used to make the width and length lines visible and not stick to configuration
  private _LINE_OFFSET = 0.1;

  // Offsets to make text appear where we want it to
  private _HORIZONTAL_TEXT_OFFSET = '30px';
  private _VERTICAL_TEXT_OFFSET = '-1em';
  private _HORIZONTAL_TEXT_OFFSET_TOPVIEW = '0'; // TODO: JG-785: Edit these further to put the text in nicer places
  private _VERTICAL_TEXT_OFFSET_TOPVIEW = '1.5em';
  private _HORIZONTAL_TEXT_OFFSET_FRONTVIEW = '0';
  private _VERTICAL_TEXT_OFFSET_FRONTVIEW = '1.5em';
  private _HORIZONTAL_TEXT_OFFSET_SIDEVIEW = '0';
  private _VERTICAL_TEXT_OFFSET_SIDEVIEW = '1.5em';
  private _arrowSize: number = 0.1;
  private _fontSize: string = "25px";
  // use normal size for the moment; leave here so this is easily changed later
  private _fontSizeLarge: string = "25px";

  private _serviceDestroyed$: Subject<void> = new Subject();

  constructor(
    private _renderService: RenderService
  ) {
    this._renderService.triggerMeasurementRefresh.pipe(takeUntil(this._serviceDestroyed$)).subscribe(_ => {
      this.setMeasurementArrows(this._renderService.showMeasurements);
    });
  }

  /**
   * Set measurement arrows
   */
  public setMeasurementArrows(showMeasurements: boolean): void {
    this._renderService.removeMeasurementArrows();
    if (showMeasurements) {
      this._renderService.toggleAndSetMeasurementArrows(true, this.createMeasurementArrowGroup());
    } else {
      this._renderService.toggleAndSetMeasurementArrows(false);
    }
  }

  /**
   * Determines the size of the 3d objects without sprites or the floor.
   * Results in a 3d vector, in which every entry is multiplied by the scale factor (hardcoded for now).
   * Every entry should be considered in meters.
   */
  public getObjectsSize(): Vector3 {
    // Get total box and determine the size, multiply by the scale
    return this._renderService.getTotalObjectBox().getSize(new Vector3()).multiplyScalar(this._SCALE);
  }

  /**
   * This function gets the total size of the configuration and creates arrow lines to indicate the size.
   */
  public createMeasurementArrowGroup(): Group {
    const totalObject: Box3 = this._renderService.getTotalObjectBox();
    const startPoint: Vector3 = totalObject.min;
    const endPoint: Vector3 = totalObject.max;
    const currentViewMode: ViewMode = this._renderService.getViewModeValue();
    let orthographicView: boolean = false;
    let useLargeFont: boolean = false;
    let text_offset_x: string = this._HORIZONTAL_TEXT_OFFSET;
    let text_offset_y: string = this._VERTICAL_TEXT_OFFSET;
    let hideX: boolean = false;
    let hideY: boolean = false;
    let hideZ: boolean = false;

    switch (currentViewMode) {
      case ViewMode.cleanFront:
        text_offset_x = this._HORIZONTAL_TEXT_OFFSET_FRONTVIEW;
        text_offset_y = this._VERTICAL_TEXT_OFFSET_FRONTVIEW;
        orthographicView = true;
        hideX = true;
        break;
      case ViewMode.cleanSide:
        text_offset_x = this._HORIZONTAL_TEXT_OFFSET_SIDEVIEW;
        text_offset_y = this._VERTICAL_TEXT_OFFSET_SIDEVIEW;
        orthographicView = true;
        hideZ = true;
        break;
      case ViewMode.cleanTop:
        text_offset_x = this._HORIZONTAL_TEXT_OFFSET_TOPVIEW;
        text_offset_y = this._VERTICAL_TEXT_OFFSET_TOPVIEW;
        hideY = true;
        orthographicView = true;
        useLargeFont = true;
        break;
    }

    const xArrow = this._createMeasurement(
      new Vector3(
        startPoint.x,
        orthographicView ? startPoint.y - this._LINE_OFFSET : startPoint.y,
        startPoint.z - this._LINE_OFFSET
      ),
      new Vector3(
        endPoint.x,
        orthographicView ? startPoint.y - this._LINE_OFFSET : startPoint.y,
        startPoint.z - this._LINE_OFFSET
      ),
      orthographicView ? text_offset_x : null,
      text_offset_y,
      useLargeFont
    );

    const yArrow = this._createMeasurement(
      new Vector3(
        startPoint.x - this._LINE_OFFSET,
        startPoint.y,
        startPoint.z - this._LINE_OFFSET
      ),
      new Vector3(
        startPoint.x - this._LINE_OFFSET,
        endPoint.y,
        startPoint.z - this._LINE_OFFSET
      ),
      text_offset_x,
      orthographicView ? text_offset_y : null,
      useLargeFont
    );

    const zArrow = this._createMeasurement(
      new Vector3(
        startPoint.x - this._LINE_OFFSET,
        orthographicView ? startPoint.y - this._LINE_OFFSET : startPoint.y,
        startPoint.z
      ),
      new Vector3(
        startPoint.x - this._LINE_OFFSET,
        orthographicView ? startPoint.y - this._LINE_OFFSET : startPoint.y,
        endPoint.z
      ),
      orthographicView ? text_offset_x : null,
      text_offset_y,
      useLargeFont
    );

    const arrowGroup = new Group();
    if (!hideX) {
      arrowGroup.add(...xArrow);
    }
    if (!hideY) {
      arrowGroup.add(...yArrow);
    }
    if (!hideZ) {
      arrowGroup.add(...zArrow);
    }
    return arrowGroup;
  }


  /**
   * This function creates two arrow lines in opposite direction to mark the size of the object.
   */
  private _createMeasurement(from: Vector3, to: Vector3, offsetX: string, offsetY: string, useLargeFont = false): Object3D[] {
    const direction: Vector3 = to.clone().sub(from);
    const otherDirection: Vector3 = from.clone().sub(to);
    const length: number = direction.length();
    const textLocation = new Vector3((from.x + to.x) / 2, (from.y + to.y) / 2, (from.z + to.z) / 2); // middle
    const text = this._createText(length.toFixed(2) + "m", textLocation, offsetX, offsetY, useLargeFont ? this._fontSizeLarge : this._fontSize);
    return [text, new ArrowHelper(direction.normalize(), from, length, 0x000000, this._arrowSize, this._arrowSize),
      new ArrowHelper(otherDirection.normalize(), to, length, 0x000000, this._arrowSize, this._arrowSize)];
  }

  private _createText(text: string, position: Vector3, offsetX: string = null, offsetY: string = null, fontSize: string): CSS2DObject {
    const styleMap = new Map();
    if (offsetY) {
      styleMap.set("margin-top", offsetY);
    }
    if (offsetX) {
      styleMap.set("margin-left", offsetX);
    }
    styleMap.set("font-size", fontSize);
    const textObj = createText(
      text,
      styleMap
    );
    textObj.position.copy(position);
    return textObj;
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }
}
