/**
 * Helper function for flattening nested arrays
 */
export function flatten<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr);
}

/**
 * Helper function to filter unique values in an array
 */
export function filterUnique<T>(arr: T[]): T[] {
  return arr.filter((v: T, i: number, s: T[]) => s.indexOf(v) === i);
}

/**
 * Helper function to determine equality of two sets,
 * since the default equals for sets simple checks for object equality.
 */
export function eqSet<T>(as: Set<T>, bs: Set<T>): boolean {
  if (as.size !== bs.size) {
    return false;
  }
  for (const a of Array.from(as.keys())) {
    if (!bs.has(a)) {
      return false;
    }
  }
  return true;
}

/**
 * Helper function to determine if a set of sets X contains (an equivalent to) a given set Y.
 * We need this because JS does not appear to properly handle X.has(Y)
 * (again, due to it using object equality rather than a 'real' equals check)
 */
export function setOfSetContains<T>(x: Set<Set<T>>, y: Set<T>): boolean {
  const a = Array.from(x.keys());
  return a.some(s => eqSet(s, y));
}
