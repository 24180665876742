import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfiguratorPreConfiguration} from "@src/app/model/configurator-preconfiguration";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-preconfig-card',
  templateUrl: './preconfig-card.component.html',
  styleUrls: ['./preconfig-card.component.scss']
})
export class PreconfigCardComponent implements OnInit, OnDestroy {
  @Input() data: ConfiguratorPreConfiguration;
  @Input() activeCode: string = '';
  @Input() showLogo: boolean = true;
  @Input() longestName: string = '';
  public jgLogoSrc = iconSource(Icon.junglegymlogo);
  public formattedPrice: string;
  private _componentDestroyed$: Subject<void> = new Subject();
  public checkMark: string = iconSource(Icon.checkmark);

  constructor(
    private _priceCalculatorService: PriceCalculatorService
  ) {

  }

  ngOnInit(): void {
    this.formattedPrice = this.data ? this._priceCalculatorService.priceFormatter(this.data.price) : "unknown price";
    this.calcHeightForNameField();
  }

  /**
   * We are given the longest string name for any of the preconfigurations and all cards should base their height on the
   * longest one, so we calculate how many lines it would take up, and set the minimum height accordingly, so that all
   * cards are the same length
   */
  private calcHeightForNameField() {
    // There is more than one element with the className, as there is a portrait & landscape version, and they are both
    // always available one of them is not visible as it has no width and height. So we take the one where the width is determined.
    const element = Array.from(document.getElementsByClassName("checkmark-logo-container"))
      .filter(e => e.getBoundingClientRect().width > 0)[0]
    const textAreaWidth = element.getBoundingClientRect().width;

    const fontFamily = window.getComputedStyle(element).fontFamily;
    const fontBody1Size = 16;
    const fontBody1LineHeight = 24;

    // Create a temporary paragraph to calculate text dimensions
    const tempParagraph = document.createElement("p");
    tempParagraph.style.font = `${fontBody1Size}px ${fontFamily}`;
    tempParagraph.style.position = "absolute";
    tempParagraph.style.top = "-9999px"; // Place it off-screen should be unnecessary but just in case
    document.body.appendChild(tempParagraph);

    //The last word had a \n it making results tiniest bit inaccurate now we filter it away
    const words = this.longestName.replace(/\n/g, '').split(' ');
    let lineCount = 0;
    let currentLine = '';

    //We add each word one by one to the test line if it becomes wider than the textArea, if so we increase the line count
    // and place the word on the next line, and continue on
    for (let i = 0; i < words.length; i++) {
      const testLine = currentLine + words[i] + ' ';
      tempParagraph.textContent = testLine;
      if (tempParagraph.clientWidth > textAreaWidth && i > 0) {
        lineCount++;
        currentLine = words[i] + ' ';
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine.trim() !== '') {
      lineCount++;
    }
    document.body.removeChild(tempParagraph);

    //Set the property so that the name text can use it for the height
    document.body.style.setProperty("--name-height", (lineCount * fontBody1LineHeight) + "px");
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
