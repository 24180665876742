import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {takeUntil} from "rxjs/operators";
import {RenderService} from "@src/app/services/render/render.service";

@Component({
  selector: 'app-wood-type-modal',
  templateUrl: './wood-type-modal.component.html',
  styleUrls: ['./wood-type-modal.component.scss']
})
export class WoodTypeModalComponent implements OnDestroy, AfterViewInit {
  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  public hideSelection: boolean = false;

  constructor(
    private _modalService: ModalService,
    protected _renderService: RenderService
  ) {
  }

  ngAfterViewInit(): void {
    this._modalComponent.getModalArg("hide")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (hide: any) => {
          this.hideSelection = !!hide
        },
        error: _ => {
        }
      });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  // Close me up
  public close(): void {
    this._modalService.close('wood-type-modal');
  }
}
