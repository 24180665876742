import {Injectable} from '@angular/core';
import {Configuration} from "@ess/jg-rule-executor";
import {Observable} from "rxjs";
import {ApolloService} from "@src/app/services/apollo/apollo.service";

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(private _apolloService: ApolloService) {
  }

  /**
   * Retrieve the configuration for the given code from Apollo
   */
  public getConfigurationByCode$(configCode: string): Observable<Configuration> {
    return this._apolloService.getConfigurationByCode$(configCode);
  }

  /**
   * Save a configuration to the DB as a new configuration, and return the code.
   */
  public saveConfiguration$(configuration: Configuration): Observable<string> {
    return this._apolloService.uploadConfiguration$(configuration);
  }

  /**
   * Save a configuration to the DB as a new configuration, and return the code.
   */
  public updateConfiguration$(
    code: string,
    hasAcceptedTerms: boolean,
    name?: string,
    email?: string,
  ): Observable<boolean> {
    return this._apolloService.updateConfiguration$(
      code,
      hasAcceptedTerms,
      name,
      email
    );
  }
}
