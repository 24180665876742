import {Injectable} from '@angular/core';
import {ColorCodes, RetailerOptions} from "@ess/jg-rule-executor";
import {InvalidColorError} from "@src/app/model/errors";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {PriceAppearance} from "@ess/jg-rule-executor/dist/src/domain/price-appearance";

@Injectable({
  providedIn: 'root'
})
export class CustomStyleService {

  // regex for a hex color - note that we include the #
  public readonly hexColorRegex: RegExp = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

  constructor(
    private _loggingService: LoggingService
  ) {
  }

  public setCustomStyle(options: RetailerOptions): void {
    this._setApplicationColors(options);
    this._setApplicationFont(options);
    this._setPriceStyle(options.configuratorOptions?.priceAppearance)

    document.documentElement.style.setProperty('--extra-card-width', `${options?.configuratorOptions?.extraCardWidth ?? 0}`);
  }

  /**
   * Validate colors in the given RetailerOptions.colorCodes, and sets those as the application colors.
   * If colorCodes is missing or there is any invalid value, use the default instead and log an error.
   *
   * @param options
   */
  private _setApplicationColors(options: RetailerOptions): void {
    if (options?.colorCodes && this.isValidColorCodes(options.colorCodes)) {
      document.documentElement.style.setProperty('--button', options.colorCodes.button);
      document.documentElement.style.setProperty('--buttonPrimaryHover', options.colorCodes.buttonPrimaryHover);
      document.documentElement.style.setProperty('--buttonSecondaryHover', options.colorCodes.buttonSecondaryHover);
      document.documentElement.style.setProperty('--gradient1', options.colorCodes.gradient1);
      document.documentElement.style.setProperty('--gradient2', options.colorCodes.gradient2);
      document.documentElement.style.setProperty('--cartButton', options.colorCodes.cartButton);
      document.documentElement.style.setProperty('--cartButtonHover', options.colorCodes.cartButtonHover);
      document.documentElement.style.setProperty('--background', options.colorCodes.background);
      document.documentElement.style.setProperty('--selectedItemBackground', options.colorCodes.selectedItemBackground);
      document.documentElement.style.setProperty('--bodyText', options.colorCodes.bodyText);
      document.documentElement.style.setProperty('--headerText', options.colorCodes.headerText);
      document.documentElement.style.setProperty('--selectedItem', options.colorCodes.selectedItem);
      document.documentElement.style.setProperty('--icons', options.colorCodes.icons);
      document.documentElement.style.setProperty('--activeStep', options.colorCodes.activeStep);
      document.documentElement.style.setProperty('--inactiveStep', options.colorCodes.inactiveStep);
      document.documentElement.style.setProperty('--dropShadow', options.colorCodes.dropShadow);
      document.documentElement.style.setProperty('--warn', options.colorCodes.warn);
      document.documentElement.style.setProperty('--border', options.colorCodes.border);
    } else {
      document.documentElement.style.setProperty('--button', '#30362F');
      document.documentElement.style.setProperty('--buttonPrimaryHover', '#30362F'); //JungleGym still needs to decide this
      document.documentElement.style.setProperty('--buttonSecondaryHover', '#30362F'); //JungleGym still needs to decide this
      document.documentElement.style.setProperty('--gradient1', '#FAFAF4');
      document.documentElement.style.setProperty('--gradient2', '#EEEBDD');
      document.documentElement.style.setProperty('--cartButton', '#30362F');
      document.documentElement.style.setProperty('--cartButtonHover', '#FFFFFF');  //JungleGym still needs to decide this
      document.documentElement.style.setProperty('--background', '#FFFFFF');
      document.documentElement.style.setProperty('--selectedItemBackground', '#FFFFFF');
      document.documentElement.style.setProperty('--bodyText', '#30362F');
      document.documentElement.style.setProperty('--headerText', '#30362F');
      document.documentElement.style.setProperty('--selectedItem', '#30362F');
      document.documentElement.style.setProperty('--icons', '#30362F');
      document.documentElement.style.setProperty('--activeStep', '#ffffff'); //Should be N/A for JungleGym
      document.documentElement.style.setProperty('--inactiveStep', '#ffffff'); //Should be N/A for JungleGym
      document.documentElement.style.setProperty('--dropShadow', '#dfcfbb');
      document.documentElement.style.setProperty('--warn', '#eb5757');
      document.documentElement.style.setProperty('--border', '#CCCCCC');
    }
  }

  /**
   * Sets the font from the retailer options as the application font.
   * If font is missing or there is any invalid value, use the default instead.
   *
   * @param options
   */
  private _setApplicationFont(options: RetailerOptions): void {
    const allFontsAvailable = (
      options?.configuratorOptions?.font?.regularFontAsset?.url &&
      options?.configuratorOptions?.font?.boldFontAsset?.url &&
      options?.configuratorOptions?.font?.italicFontAsset?.url &&
      options?.configuratorOptions?.font?.mediumFontAsset?.url
    );

    if (allFontsAvailable) {
      this._addFontFace(options.configuratorOptions.font.regularFontAsset.url, "Custom Font Regular");
      this._addFontFace(options.configuratorOptions.font.boldFontAsset.url, "Custom Font Bold", 700);
      this._addFontFace(options.configuratorOptions.font.italicFontAsset.url, "Custom Font Italic");
      this._addFontFace(options.configuratorOptions.font.mediumFontAsset.url, "Custom Font Medium");
    }
  }

  private _setPriceStyle(priceAppearance: PriceAppearance): void {
    if (priceAppearance?.color) {
      document.documentElement.style.setProperty('--priceColor', priceAppearance.color)
    }
    if (priceAppearance?.font) {
      this._addFontFace(priceAppearance.font.url, "Custom Font Price")
    }
  }

  public isValidColorCodes(colors: ColorCodes): boolean {
    // Works because getOwnPropertyNames _will_ get all color names, even if they are null/undefined
    return Object.getOwnPropertyNames(colors)
      .map(key => this.isValidColor(colors[key], key))
      .every(val => val === true);
  }

  public isValidColor(color: string, key: string): boolean {
    const valid = this.hexColorRegex.test(color);
    if (!valid) {
      this._loggingService.error(new InvalidColorError(key));
    }
    return valid;
  }

  private _addFontFace(url: string, name: string, fontWeight: number = 400): void {
    const styleSheet = document.styleSheets[0];
    const regularFontFace = "@font-face {\n" +
      `  font-family: '${name}';\n` +
      "  font-style: normal;\n" +
      `  font-weight: ${fontWeight};` +
      `  src: url('${url}') format('truetype');\n` +
      "}";
    styleSheet.insertRule(regularFontFace);
  }
}
