import {Injectable} from '@angular/core';
import {Sprite, Texture, TextureLoader, Vector3} from "three";
import {SpriteData} from "@src/app/model/sprite-data";

export enum AttachmentPointKinds {
  add = 'add',
  addSelected = 'addSelected',
  missing = 'missing',
  missingSelected = 'missingSelected',
  tower = 'tower',
  towerSelected = 'towerSelected',
  towerMissing = 'towerMissing',
  towerMissingSelected = 'towerMissingSelected',
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentPointService {

  public static readonly ATTACHMENT_POINT_SIZES: Vector3 = new Vector3(36, 36, 1);
  public static readonly EDIT_POINT_SIZES: Vector3 = new Vector3(66, 66, 1);

  private _textureLoader: TextureLoader = new TextureLoader();

  // Important to preload all textures to make it faster to make the sprites
  private _allTextures: Map<AttachmentPointKinds, Texture> = new Map([
    [AttachmentPointKinds.add, this._textureLoader.load(this.getPath(AttachmentPointKinds.add))],
    [AttachmentPointKinds.addSelected, this._textureLoader.load(this.getPath(AttachmentPointKinds.addSelected))],
    [AttachmentPointKinds.missing, this._textureLoader.load(this.getPath(AttachmentPointKinds.missing))],
    [AttachmentPointKinds.missingSelected, this._textureLoader.load(this.getPath(AttachmentPointKinds.missingSelected))],
    [AttachmentPointKinds.tower, this._textureLoader.load(this.getPath(AttachmentPointKinds.tower))],
    [AttachmentPointKinds.towerSelected, this._textureLoader.load(this.getPath(AttachmentPointKinds.towerSelected))],
    [AttachmentPointKinds.towerMissing, this._textureLoader.load(this.getPath(AttachmentPointKinds.towerMissing))],
    [AttachmentPointKinds.towerMissingSelected, this._textureLoader.load(this.getPath(AttachmentPointKinds.towerMissingSelected))],
  ]);

  /**
   * Gets created texture
   */
  public getTexture(apKind: AttachmentPointKinds): Texture {
    return this._allTextures.get(apKind);
  }

  public getPath(apKind: AttachmentPointKinds): string {
    return `assets/icons/canvas/${apKind}.svg`;
  }

  /**
   * Deselect Attachment Point (or edit point)
   */
  public deselectSprite(attachmentPoint: Sprite) {
    this.resetSpriteTexture(attachmentPoint);
  }

  /**
   * Select Attachment Point (or edit point)
   */
  public selectSprite(attachmentPoint: Sprite) {
    this.resetSpriteTexture(attachmentPoint);
    this.changeSpriteTexture(attachmentPoint, SpriteData.fromSprite(attachmentPoint).selectedKind);
  }

  /**
   * change the texture of a given sprite to the provided attachmentPointKind's texture
   */
  public changeSpriteTexture(
    sprite: Sprite,
    attachmentPointKind: AttachmentPointKinds,
  ): void {
    sprite.material.map = this.getTexture(attachmentPointKind);
    sprite.material.map.image.source = this.getPath(attachmentPointKind);
  }

  /**
   * reset a sprite texture to the one defined in spriteData.default_kind
   */
  public resetSpriteTexture(sprite: Sprite): void {
    if (SpriteData.spriteHasData(sprite)) {
      const kind: AttachmentPointKinds = SpriteData.fromSprite(sprite).defaultKind;
      this.changeSpriteTexture(sprite, kind);
    }
  }
}
