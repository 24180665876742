import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-plp-side-view',
  templateUrl: './plp-side-view.component.html',
  styleUrls: ['./plp-side-view.component.scss']
})
export class PlpSideViewComponent {
  @Input() loadingFinished: boolean = false;

  constructor(
  ) {
  }
}
