<app-modal id="help-modal" fullscreen="true" #modalComponent (closing)="close()">
    <h2 class="font-h2">{{ "app.modal.help.header" | translate }}</h2>
    <div class="modal-container">
        <div
            class="font-subtitle">{{ 'app.help.title' | translate : {INST: helpLink ? helpLink.text : 'app.help.fallback' | translate} }}
        </div>
        <!--Should be a ng-container but safari issues, so div it is-->
        @for (helpItem of helpItems; track helpItem) {
            <div>
                <!--Only show if there is a video asset-->
                @if (helpItem.videoAsset) {
                    <div class="video-area">
                        <video #theVideo [id]="'helpItem.video.' + helpItem.order"
                               class="video"
                               crossorigin="anonymous"
                               [poster]="helpItem.thumbnailAsset?.url"
                               controls
                               controlsList="nodownload">
                            <source [src]="helpItem.videoAsset.url">
                        </video>
                        <div #theThumbnail class="thumbnail-img">
                            @if (helpItem.thumbnailAsset?.url) {
                                <img crossorigin="anonymous" [src]="helpItem.thumbnailAsset.url">
                            }
                        </div>
                        <div #theOverlay
                             class="overlay"
                             (click)="theThumbnail.style.display = 'none'; theOverlay.style.display = 'none'; theVideo.style.opacity = '1'; theVideo.play()">
                            <img src="assets/video-overlay.png">
                        </div>
                        <div class="font-body2 title-text">{{ helpItem.title }}</div>
                    </div>
                }
            </div>
        }
    </div>
</app-modal>
