@if (loadingFinished) {
    <footer class="footer">
        <div class="footer-items">
            @if (plpAvailable || previewMode) {
                <app-button
                    class="back-button"
                    buttonType="secondary"
                    leftIcon="chevronl"
                    [disabled]="(inPlp || plpDisabled) && !previewMode"
                    [borderColor]="true"
                    [circleButton]="true"
                    [smallIcon]="true"
                    (onClick)="clickBack()"
                ></app-button>
            }
            <div class="center-text">
                <div class="price-text">
                    @if (allInStock) {
                        @if (priceTextDefined) {
                            <p class="font-body1">{{ priceText }}</p>
                        }
                        <strong [class]="priceTextDefined ? 'font-h3 font-price' : 'font-h2 font-price'">{{ priceToDisplay }}</strong>
                    }
                </div>
                <div class="extra-text landscape">
                    <p class="font-body-small">{{ extraText | translate }}</p>
                </div>
            </div>
            @if (!inPlp && saveButton) {
                <app-button class="save-button portrait"
                            buttonType="secondary"
                            rightIcon="save"
                            [disabled]="!configValid"
                            [circleButton]="true"
                            (onClick)="clickSave()"
                            [clickDelay]="1000"
                ></app-button>
            }
            @if (!inPlp) {
                <app-button class="save-button landscape"
                            [label]="'app.footer.buttons.save' | translate"
                            buttonType="secondary"
                            [ovalButton]="true"
                            fillParent="true"
                            [disabled]="!configValid"
                            (onClick)="clickSave()"
                            [clickDelay]="1000"
                ></app-button>
            }
            <app-button class="next-button"
                        [label]="(editMode ? 'app.footer.buttons.check' : previewMode ? 'app.footer.buttons.cart' :  'app.footer.buttons.next') | translate"
                        [buttonType]="!previewMode ? 'primary' : 'cart'"
                        [ovalButton]="true"
                        fillParent="false"
                        [disabled]="((!configAvailable || !configValid || !allInStock || !woodTypeAvailable || inPlp) && !dataService.currentPreConfigurationCode || (!this.rulesAreLoaded && this.editMode))"
                        (onClick)="clickNext()"
            ></app-button>
        </div>
        <div class="extra-text portrait">
            <p class="font-body-small">{{ extraText | translate }}</p>
        </div>
    </footer>
}
