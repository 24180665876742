import {Injectable} from '@angular/core';
import {FatModuleVariant, Id, ModuleBlueprint, ModuleVariant} from "@ess/jg-rule-executor";
import {InitDataService} from "@src/app/services/data/init-data.service";

@Injectable({
  providedIn: 'root'
})
export class ModuleManagementService {
  constructor(private _dataService: InitDataService) {
  }

  /**
   * Returns a FatModuleVariant (Variant+Blueprint) for the given variant Id.
   */
  public getFatModuleVariantById(id: Id<ModuleVariant>): FatModuleVariant {
    return this._dataService.inConfigAndPublishedModuleVariants.find(fv => fv.variant.id === id);
  }
}
