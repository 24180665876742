import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonType} from "@src/app/library/components/buttons/button.type";
import {Icon, iconSource} from "@src/app/library/components/icon";

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  // Type of the button
  @Input() public buttonType: ButtonType = ButtonType.primary;
  // Optional left button icon
  @Input() public leftIcon?: Icon;
  // Optional right button icon
  @Input() public rightIcon?: Icon;
  // Button label
  @Input() public label: string;
  // Indicates if the button should follow the parent container
  @Input() public fillParent: boolean = false;
  // Disabled-ness
  @Input() public disabled: boolean = false;
  // Delay after click for second click
  @Input() public clickDelay?: number;
  // Indicates if, when clicked, should be re-enabled or not
  @Input() public manualReactivation: boolean = false;
  // Use alternate smaller button size
  @Input() public smallButton: boolean = false;
  // Use alternate smaller icon size
  @Input() public smallIcon: boolean = false;
  // Use alternate normal icon size
  @Input() public normalIcon: boolean = false;
  // Hide the right icon (necessary because you can't supply an icon after init)
  @Input() public hideRightIcon: boolean = false;
  // Hide the left icon (necessary because you can't supply an icon after init)
  @Input() public hideLeftIcon: boolean = false;
  // Use alternate smaller icon size
  @Input() public circleButton: boolean = false;
  //Use the border colour instead
  @Input() public borderColor: boolean = false;
  // decide if text should be bold (true by default)
  @Input() public boldText: boolean = true;

  //Next step button because gamma wants special padding
  @Input() public ovalButton: boolean = false;

  // Emitter for click
  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();
  // Emitter for disabled change
  @Output() public disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Variables for icon source to prevent change detection
  public leftIconSource: string;
  public rightIconSource: string;

  // Startup, set icons
  public ngOnInit(): void {
    this.hideRightIcon = this.circleButton && !this.rightIcon || this.hideRightIcon;
    this.hideLeftIcon = this.circleButton && !this.leftIcon || this.hideLeftIcon;
    this.leftIconSource = this.leftIcon ? iconSource(this.leftIcon) : undefined;
    this.rightIconSource = this.rightIcon ? iconSource(this.rightIcon) : undefined;
  }

  /**
   * Click function.
   * Prevents second click for a given delay or if manual enabling is needed
   */
  public onClickFn(): void {
    this.onClick.emit();
    // If the button should delay of manual reactivation is needed, disable
    if (this.clickDelay || this.manualReactivation) {
      this.disabled = true;
      this.disabledChange.emit(this.disabled);

      // Now if manual reactivation is not required, set a timeout to reactivate after set delay
      if (!this.manualReactivation) {
        setTimeout(() => {
          this.disabled = false;
          this.disabledChange.emit(this.disabled);
        }, this.clickDelay);
      }
    }
  }
}
