import {Component, Input, OnDestroy} from '@angular/core';
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {combineLatest, Subject} from "rxjs";
import {filter, take, takeUntil} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ViewMode} from '@src/app/model/view-mode';
import {ApplicationState} from '@src/app/model/applicationstate';
import {ApplicationStateService} from '@src/app/services/applicationstate/application-state.service';
import {ModalService} from "@src/app/services/modal/modal.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  @Input() loadingFinished: boolean = false;

  // Track the view mode
  public currentViewIndex: number;
  public showSteps = false;

  public plpDisabled: boolean = false;

  public rulesAreLoaded: boolean = false;

  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _interactionService: InteractionService,
    public dataService: InitDataService,
    private _applicationStateService: ApplicationStateService,
    private _modalService: ModalService,
  ) {
    _applicationStateService.getState$().pipe(filter(t => t === ApplicationState.translationsAreLoaded), take(1), takeUntil(this._componentDestroyed$))
      .subscribe(() => {
        this._interactionService.getViewMode$()
          .pipe(takeUntil(this._componentDestroyed$))
          .subscribe(m => {
            this.currentViewIndex = m
          });
      });

    combineLatest([
      _applicationStateService.getState$(),
      _interactionService.getViewMode$()
    ]).pipe(
      takeUntil(this._componentDestroyed$))
      .subscribe(([a, v])  => {
        this.plpDisabled = !(((a === ApplicationState.rulesAreLoaded ||
              a === ApplicationState.fullyOperational) &&
            v === ViewMode.edit) ||
          v === ViewMode.plp)
        if (a === ApplicationState.rulesAreLoaded) {
          this.rulesAreLoaded = true;
        } else if (a === ApplicationState.init) {
          this.rulesAreLoaded = false;
        }
      });

    this.showSteps = this.dataService.showPlp;
  }

  public changeViewClick(index: number): void {
    switch (index) {
      case 1:
        this._interactionService.getViewMode$().pipe(take(1), takeUntil(this._componentDestroyed$)).subscribe(
          v => {
            if (v === ViewMode.plp) {
              return;
            } else {
              this._modalService.open('plp-modal', {config: this.dataService.configuration});
            }
          }
        )
        break;
      case 2:
        this._interactionService.setViewMode(ViewMode.edit);
        break;
      case 3:
        this._interactionService.setViewMode(ViewMode.preview);
        break;
    }
  }

  /**
   * OnDestroy close subscriptions
   */
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

