import {Component, Input} from '@angular/core';
import {Asset, Id, ModuleVariant} from "@ess/jg-rule-executor";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {IconValue} from "@src/app/model/configurator-catalog";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {FailedToRetrieveAssetError} from "@src/app/model/errors";

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.scss']
})
export class CatalogItemComponent {
  // The variant it needs to display
  @Input() item: ModuleVariant;
  @Input() iconValue: IconValue;
  @Input() disabled: boolean = false;
  @Input() edit: boolean = false;
  @Input() isWoodType: boolean = false;
  @Input() woodType: string;
  @Input() itemSelected: boolean;
  public  selectedIconSrc: string = iconSource(Icon.checkmark);

  constructor(
    private _loggingService: LoggingService
  ) {
  }

  public handleImageError(assetId: Id<Asset>) {
    this._loggingService.error(new FailedToRetrieveAssetError(assetId));
  }
}
