import {Injectable} from '@angular/core';
import {Box3, Euler, Group, Object3D, Sprite, SpriteMaterial, Texture, Vector3} from "three";
import {
  ConfigurationPlacement,
  Id,
  ModuleBlueprint,
  ModulePlacement,
  RenderObjectBuildInfo,
  VisualChange,
  VisualXAttachmentPoint,
  WoodType
} from "@ess/jg-rule-executor";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {ModelLoadingService} from "@src/app/services/model-loading/model-loading.service";
import {MODULE_PLACEMENT_ID, SPRITE_DATA} from "@src/app/constants";
import {filterUnique} from "@src/app/helpers/general.helpers";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {SpriteData} from "@src/app/model/sprite-data";
import {
  AttachmentPointKinds,
  AttachmentPointService
} from "@src/app/services/attachment-point/attachment-point.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {DebugService} from "@src/app/services/debug/debug.service";
import {MarkerService} from "@src/app/services/marker/marker.service";

@Injectable({
  providedIn: 'root'
})
export class PlacementService {
  public moduleGroupName: string = 'module';
  public visualXAttachmentPointsGroupName: string = 'visual_x_buttons';
  public editButtonsGroupName: string = 'edit_buttons';
  public markerGroupName: string = 'markers';
  private _foundMissingVariant: boolean = false;
  // Material and texture variables
  private _materialNameKey: string = 'materialName';
  private _woodKey: string = 'isWood';
  private _isWoodValue: number = 1;
  // Keep track of logged warnings for materials and textures as to not overflow
  private _loggedMaterialNames: string[] = [];

  constructor(
    private _moduleManagementService: ModuleManagementService,
    private _modelLoadingService: ModelLoadingService,
    private _attachmentPointService: AttachmentPointService,
    private _dataService: InitDataService,
    private _loggingService: LoggingService,
    private _debugService: DebugService,
    private _markerService: MarkerService
  ) {
  }

  private _showMarkers: boolean;

  set showMarkers(v: boolean) {
    this._showMarkers = v;
  }

  public static correctBlueprintOffset(bp: ModuleBlueprint, rotation: Euler): Vector3 {
    if (bp.name.toLowerCase().includes("exten")) {
      // extension tower -- ugly solution...
      const xaps = bp.xAttachmentPoints;
      return xaps.map(x => x.position)
        .reduce((acc, cur) => acc.add(cur), new Vector3())
        .divideScalar(xaps.length)
        .applyEuler(rotation);
    }
    return new Vector3();
  }

  /**
   * Function that constructs 3D objects for all modules and empty attachment points.
   *
   * @param configurationPlacement configurationPlacement is required for retrieving Attachments.
   * @param addAttachmentPoints indicates whether to add attachment points
   */
  public async createObjectForPlacement(
    configurationPlacement: ConfigurationPlacement,
    addAttachmentPoints: boolean
  ): Promise<{ modules: Group, sprites: Group, markers: Group, afterRender: (() => void)[] }> {
    const placementGroup: Group = new Group();
    const spriteGroup: Group = new Group();
    const markerGroup: Group = new Group();

    if (addAttachmentPoints) {
      this._foundMissingVariant = false;  // need a reset here
    }

    await Promise.all(
      configurationPlacement
        .computeRenderInfo(variantId => this._moduleManagementService.getFatModuleVariantById(variantId))
        .map(async renderInfo => {
          // Create the Group for the module, apply visual changes, add it to the return group
          const moduleGroup: Group = await this._createModuleGroup(renderInfo);
          this._addMaterialsAndTextures(moduleGroup);
          this._addVisualChanges(moduleGroup, renderInfo.getVisualChanges());
          this._changeWoodType(moduleGroup, configurationPlacement.woodType);
          placementGroup.add(moduleGroup);

          if (addAttachmentPoints && renderInfo.fatVariant.blueprint.visualXAttachmentPoints.length > 0) {
            const modulesMissing = configurationPlacement.placements.filter(value =>
                !(this._dataService.allCatalogVarIds.includes(value.variantId))).map(value => value.id)
            // Add all attachment points to the return group
            spriteGroup.add(this._createVisualXAttachmentGroup(renderInfo, modulesMissing));
          }
          if(addAttachmentPoints) {
            spriteGroup.add(await this._createEditButtonGroup(renderInfo));
          }

          if (this._showMarkers) {
            const markerResult = this._markerService.createMarkerGroup(renderInfo);
            markerResult.name = this.markerGroupName;
            markerGroup.add(markerResult);
          }
        })
    );

    const afterRender = this._setupPostRenderFunctions();

    // Tell the dataService if a missing variant was found or not
    this._dataService.missingVariants$.next(this._foundMissingVariant);

    this._handleDebug(placementGroup, configurationPlacement);

    return {
      modules: placementGroup,
      sprites: spriteGroup,
      markers: markerGroup,
      afterRender
    };
  }

  /**
   * Prepares functions that will be called (by the RenderService) after rendering has finished.
   * Currently added functions:
   * - markerPositionOverlapFix (to move apart markers in the assembly view so they no longer overlap)
   *
   * @private
   */
  private _setupPostRenderFunctions(): (() => void)[] {
    const afterRender: (() => void)[] = [];
    afterRender.push(() => {
      this._markerService.markerPositionOverlapFix();
    });
    return afterRender;
  }

  private _handleDebug(parentGroup: Group, configurationPlacement: ConfigurationPlacement): void {
    const debugGroup = new Group();
    if (this._dataService.showBoundingBoxes || this._debugService.debug.debug) {
      debugGroup.add(this._debugService.renderBBoxes(configurationPlacement));
      parentGroup.add(debugGroup);
    }
  }

  /**
   * Creates a 3d object for a module given the build information.
   */
  private async _createModuleGroup(
    buildInfo: RenderObjectBuildInfo
  ): Promise<Group> {
    const model: Group = await this._modelLoadingService.getModelForBlueprint$(buildInfo.fatVariant.blueprint);
    model.name = this.moduleGroupName + "-" + buildInfo.fatVariant.blueprint.name;
    const resObject = new Group().copy(model);
    resObject.userData = {...resObject.userData, [MODULE_PLACEMENT_ID]: buildInfo.placement.id};

    const positioningData = buildInfo.determineModulePositionAndRotation();
    // we need to make sure that the center of the model is oriented right to start!
    resObject.position.applyEuler(positioningData.rotation);
    resObject.position.add(positioningData.position);
    resObject.rotation.copy(positioningData.rotation);
    return resObject;
  }

  /**
   * Adds all materials and textures to the 3d object where applicable.
   * It uses the material and texture data from the model loading service directly
   */
  private _addMaterialsAndTextures(obj: Object3D): void {
    obj.traverse(c => {
      if (c.userData[this._materialNameKey] && this._modelLoadingService.allMaterials.has(c.userData[this._materialNameKey])) {
        // @ts-ignore
        c.material = this._modelLoadingService.allMaterials.get(c.userData[this._materialNameKey]);
      } else if (
        c.userData[this._materialNameKey] &&
        !this._modelLoadingService.allMaterials.has(c.userData[this._materialNameKey]) &&
        !this._loggedMaterialNames.includes(c.userData[this._materialNameKey])
      ) {
        this._loggingService.warn(`Tried to apply material ${c.userData[this._materialNameKey]}, but it does not exist (1)`);
        this._loggedMaterialNames.push(c.userData[this._materialNameKey]);
      }
    });
  }

  /**
   * Applies all given visual changes to a given object
   * Right now you can change the color of an element or hide it
   * This could be expanded in the future, for example with translations
   */
  private _addVisualChanges(
    obj: Object3D,
    visualChanges: VisualChange[]
  ): void {
    const invisibles = visualChanges.flatMap((vc) => vc.type === 'invisible' ? [vc] : []);
    // If there are elements to turn invisible, do so
    if (invisibles.length > 0) {
      // Find all children that need a visual change once to prevent unnecessary looping
      const modelMap: Map<string, Object3D> = new Map<string, Object3D>();
      // Also filter unique values to prevent extra loops
      filterUnique(invisibles).forEach((invisible) => {
        modelMap.set(invisible.name, obj.getObjectByName(invisible.name));
      });
      invisibles.forEach(vc => {
        this._hideMesh(modelMap.get(vc.name));
      });
    }
    const materialChanges = visualChanges.flatMap((vc) => vc.type === 'materialchange' ? [vc] : []);
    if (materialChanges.length > 0) {
      // Outside loop over object as this can get pretty large
      obj.traverse(c => {
        materialChanges.forEach(materialChange => {
          materialChange.changes.forEach((value, key) => {
            if (key === c.name && this._modelLoadingService.allMaterials.has(value)) {
              // @ts-ignore
              c.material = this._modelLoadingService.allMaterials.get(value);
            } else if (key === c.name && !this._modelLoadingService.allMaterials.has(value) && !this._loggedMaterialNames.includes(key)) {
              this._loggingService.warn(`Tried to apply material with name ${value}, but it does not exist (2)`);
              this._loggedMaterialNames.push(key);
            }
          });
        });
      });
    }
  }

  /**
   * Sets the visibility of the given Object3D to false, where we assume this is a Mesh or a Group.
   * If it's not, nothing happens here
   */
  private _hideMesh(obj?: Object3D): void {
    // Note that we check if the material is a Mesh or Group, only then the change is applied
    if (obj?.type === 'Mesh' || obj?.type === 'Group') {
      obj.visible = false;
    }
  }

  /**
   * Changes the wood type of all elements that have the isWood tag in their userData.
   * Note that if a group has the tag, we change all the child elements in the group.
   */
  private _changeWoodType(obj: Object3D, woodType: WoodType): void {
    if (this._modelLoadingService.allMaterials.has(woodType)) {
      const woodMat = this._modelLoadingService.allMaterials.get(woodType);

      obj.traverse(child => {
        // @ts-ignore
        if (child.userData[this._woodKey] === this._isWoodValue && child.isMesh) {
          // @ts-ignore
          child.material = woodMat;
          // @ts-ignore
        } else if (child.userData[this._woodKey] === this._isWoodValue && child.isGroup) {
          child.children.map(c => {
            // @ts-ignore
            if (c.isMesh) {
              // @ts-ignore
              c.material = woodMat;
            }
          });
        }
      });
    } else if (!this._loggedMaterialNames.includes(woodType)) {
      this._loggingService.warn(`Material for woodtype ${woodType} not available!`);
      this._loggedMaterialNames.push(woodType);
    }
  }

  /**
   * Creates an attachmentGroup for given build info
   */
  private _createVisualXAttachmentGroup(buildInfo: RenderObjectBuildInfo, modulesMissing: Id<ModulePlacement>[]): Group {
    const attachmentGroup = new Group();
    const visualXAttachmentPoints = buildInfo.fatVariant.blueprint.visualXAttachmentPoints;

    //List of ids which contains all attachment points that have an attachment that is temporarily unavailable
    const unavailableAttachmentXPoints = Array.from(buildInfo.placement.xAttachments).map(value => {
      if(modulesMissing.includes(value[1].placementId))
        return value[0]
    });

    // create a sprite for each attachment point
    visualXAttachmentPoints.map((vxap: VisualXAttachmentPoint) => {
      // If there are attachment points, show them all
      if (vxap.xAttachmentPointIds.length > 0) {
        const unavailable = vxap.xAttachmentPointIds.some(value => unavailableAttachmentXPoints.includes(value))
        const sprite = this._createAttachmentSprite(unavailable ? AttachmentPointKinds.missing: AttachmentPointKinds.add);
        const modulePositioningData = buildInfo.determineModulePositionAndRotation();

        sprite.position.add(vxap.determinePosition(modulePositioningData.position, modulePositioningData.rotation));
        sprite.userData = {
          [SPRITE_DATA]: SpriteData.createVisualXAPSpriteData(
            buildInfo.placement.id,
            vxap.xAttachmentPointIds,
            unavailable,
            vxap.orientation ? vxap.orientation.clone().applyEuler(modulePositioningData.rotation) : new Vector3()
          )
        };
        attachmentGroup.add(sprite);
      }
    });
    // Set name to identify
    attachmentGroup.name = this.visualXAttachmentPointsGroupName + "-" + buildInfo.fatVariant.blueprint.name;
    return attachmentGroup;
  }

  /**
   * Creates 3d object for an Edit Point (with pencil sprite) _if_ the module is a tower.
   * Note that the orientation of the EP is always set as (0, 1, 0);
   *
   * @param buildInfo
   * @private
   */
  private async _createEditButtonGroup(buildInfo: RenderObjectBuildInfo): Promise<Group> {
    const bp: ModuleBlueprint = buildInfo.fatVariant.blueprint;
    const mbCategory = this._dataService.catalog?.categories?.map(c => c.moduleCategories).flat().find(c => c.reference === bp.categoryReference);
    const editButtonGroup = new Group();
    editButtonGroup.name = this.editButtonsGroupName + "-" + bp.name;
    // Add edit button only if the module is a main module
    if (mbCategory?.customizable) {
      const modulePositioningData = buildInfo.determineModulePositionAndRotation();

      let editPointObject: Sprite;
      if (this._dataService.allCatalogVarIds.find(x => x === buildInfo.placement.variantId)) {
        editPointObject = this._createAttachmentSprite(AttachmentPointKinds.tower);
      } else {
        editPointObject = this._createAttachmentSprite(AttachmentPointKinds.towerMissing);
        this._foundMissingVariant = true;
      }

      editPointObject.userData = {
        [SPRITE_DATA]: SpriteData.createEditSpriteData(buildInfo?.placement?.id, this._isMissing(buildInfo), true)
      };

      editPointObject.position.copy(modulePositioningData.position);

      const offset = PlacementService.correctBlueprintOffset(bp, modulePositioningData.rotation);

      editPointObject.position.add(offset);

      // get model and calculate height for editPoint placement
      const model = await this._modelLoadingService.getModelForBlueprint$(buildInfo.fatVariant.blueprint);
      const bbox = new Box3().setFromObject(model);
      const height = bbox.max.y - bbox.min.y;
      editPointObject.position.setY(height + 0.6);
      editButtonGroup.add(editPointObject);
    }
    return editButtonGroup;
  }

  /**
   * Determine if there is a (fat) variant missing in the datastore.
   */
  private _isMissing(buildInfo: RenderObjectBuildInfo) {
    return !this._dataService.allCatalogVarIds.includes(buildInfo.fatVariant.variant.id);
  }

  /**
   * Create a sprite for a given type of attachment or edit point, with a given size.
   */
  private _createAttachmentSprite(apKind: AttachmentPointKinds): Sprite {
    const map: Texture = this._attachmentPointService.getTexture(apKind);
    map.image.source = this._attachmentPointService.getPath(apKind);
    const circleMat = new SpriteMaterial({map});
    const sprite = new Sprite(circleMat);
    sprite.scale.set(1, 1, 1);
    return sprite;
  }
}
