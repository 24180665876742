import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {ModalService} from "@src/app/services/modal/modal.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {ViewMode} from "@src/app/model/view-mode";
import {PersistenceService} from "@src/app/services/persistence/persistence.service";
import {Configuration} from "@ess/jg-rule-executor";
import {ApplicationState} from "@src/app/model/applicationstate";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";

@Component({
  selector: 'app-plp-modal',
  templateUrl: './plp-modal.component.html',
  styleUrls: ['./plp-modal.component.scss']
})
export class PlpModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: Configuration;
  public code: string;
  public submittedDesign: boolean = false;

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _modalService: ModalService,
    private _persistenceService: PersistenceService,
    private _interactionService: InteractionService,
    private _applicationStateService: ApplicationStateService,
  ) {
  }

  /**
   * onInit, subscribe to closeEmitter (so that we can refresh the modal if necessary)
   */
  public ngOnInit() {
    this._modalService.closeEmitter
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(id => {
        if (id === 'plp-modal' && this.submittedDesign) {
            this.resetConfiguration();
          }
      });
  }

  ngAfterViewInit() {
    this._modalComponent.getModalArg("config")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (config: any) => {
          this.config = config as Configuration;
        },
        error: _ => {
        }
      });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * Handle data submit; validate and emit input, and set values where necessary
   */
  public saveConfig(): void {
    this._persistenceService.saveConfiguration$(this.config).subscribe(
      s => {
        this.code = s;
        this.submittedDesign = true;
      }
    )
  }

  public doNotSaveConfig(): void {
    this.resetConfiguration();
  }

  resetConfiguration(): void {
    this._interactionService.setViewMode(ViewMode.plp);
    this._applicationStateService.updateState(ApplicationState.init);
    this.close();
  }

  /**
   * close the modal
   */
  public close(): void {
    this.code = null;
    this.config = null;
    this.submittedDesign = false;
    this._modalService.close('plp-modal');
  }
}
