import {Observable} from 'rxjs';

/**
 * An implementation of this abstract class will be used by the
 * Dependency Injection.
 *
 * This abstract class helps enforcing implementation of new
 * functionality for all supported platforms at build-time.
 */
export abstract class PlatformService {
  public abstract get(): Observable<string>;

  public abstract getShared(): Observable<string>;
}
