import {Id, ModulePlacement, XAttachmentPoint} from "@ess/jg-rule-executor";
import {SPRITE_DATA} from "@src/app/constants";
import {Sprite, Vector3} from "three";
import {AttachmentPointKinds} from "@src/app/services/attachment-point/attachment-point.service";

export class SpriteData {
  xAttachmentId: Id<XAttachmentPoint>;
  modulePlacementId: Id<ModulePlacement>;
  childIds: Id<XAttachmentPoint>[];
  defaultKind: AttachmentPointKinds;
  selectedKind: AttachmentPointKinds;
  isEditButton: boolean;
  orientation: Vector3;

  constructor(
    xAttachmentId?: Id<XAttachmentPoint>,
    modulePlacementId?: Id<ModulePlacement>,
    childIds?: Id<XAttachmentPoint>[],
    defaultKind?: AttachmentPointKinds,
    selectedKind?: AttachmentPointKinds,
    orientation?: Vector3,
    isEditButton: boolean = false
  ) {
    this.xAttachmentId = xAttachmentId;
    this.modulePlacementId = modulePlacementId;
    this.childIds = childIds;
    this.defaultKind = defaultKind;
    this.selectedKind = selectedKind;
    this.orientation = orientation;
    this.isEditButton = isEditButton;
  }

  // Note; only used in tests
  static createAttachmentSpriteData(
    xAttachmentId: Id<XAttachmentPoint>,
    modulePlacementId: Id<ModulePlacement>,
    isMissing: boolean,
    orientation: Vector3
  ): SpriteData {
    return new SpriteData(
      xAttachmentId,
      modulePlacementId,
      undefined,
      isMissing ? AttachmentPointKinds.missing : AttachmentPointKinds.add,
      isMissing ? AttachmentPointKinds.missingSelected : AttachmentPointKinds.addSelected,
      orientation
    );
  }

  static createVisualXAPSpriteData(
    modulePlacementId: Id<ModulePlacement>,
    childIds: Id<XAttachmentPoint>[],
    isMissing: boolean,
    orientation: Vector3
  ): SpriteData {
    return new SpriteData(
      undefined,
      modulePlacementId,
      childIds,
      isMissing ? AttachmentPointKinds.missing : AttachmentPointKinds.add,
      isMissing ? AttachmentPointKinds.missingSelected : AttachmentPointKinds.addSelected,
      orientation,
      false
    );
  }

  static createEditSpriteData(
    placementId: Id<ModulePlacement>,
    isMissing: boolean,
    isTower: boolean = false
  ): SpriteData {
    return new SpriteData(
      undefined,
      placementId,
      undefined,
      isMissing && isTower ? AttachmentPointKinds.towerMissing : isTower ? AttachmentPointKinds.tower : isMissing ?
        AttachmentPointKinds.missing : AttachmentPointKinds.addSelected,
      isMissing && isTower ? AttachmentPointKinds.towerMissingSelected : isTower ? AttachmentPointKinds.towerSelected : isMissing ?
        AttachmentPointKinds.missingSelected : AttachmentPointKinds.addSelected,
      undefined,
      true
    );
  }

  static spriteHasData(sprite: Sprite): boolean {
    return sprite?.userData?.hasOwnProperty(SPRITE_DATA) && SpriteData.isSpriteData(sprite.userData[SPRITE_DATA]);
  }

  /**
   * Check if an object is effectively castable to sprite data.
   * Required since userData will get saved as a generic object.
   */
  static isSpriteData(obj: object): boolean {
    const objKeys = Object.getOwnPropertyNames(obj);
    const spriteKeys = Object.getOwnPropertyNames(new SpriteData());
    return objKeys.every(objKey => spriteKeys.includes(objKey));
  }

  /**
   * returns a new SpriteData object containing the spriteData from the given sprite (if it has any)
   */
  static fromSprite(sprite: Sprite): SpriteData | undefined {
    if (SpriteData.spriteHasData(sprite)) {
      const castData = sprite.userData[SPRITE_DATA] as SpriteData;
      return new SpriteData(
        castData.xAttachmentId,
        castData.modulePlacementId,
        castData.childIds,
        castData.defaultKind,
        castData.selectedKind,
        castData.orientation,
        castData.isEditButton
      );
    } else {
      return undefined;
    }
  }

  /**
   * equals implementation for SpriteData - we need this since JS by default DOES NOT check for deep equality
   */
  equals(sd: SpriteData): boolean {
    return sd.xAttachmentId === this.xAttachmentId &&
      sd.modulePlacementId === this.modulePlacementId &&
      sd.childIds?.toString() === this.childIds?.toString() &&
      sd.defaultKind === this.defaultKind &&
      sd.selectedKind === this.selectedKind &&
      sd.isEditButton === this.isEditButton;
  }
}
