<app-modal id="carousel-modal" #modalComponent fullscreen="true" (closing)="close()">
    @if (list && index !== null) { <!-- We use [@.disabled]="true" to disable the animations for now -->
        <c-carousel [activeIndex]="index" [interval]="0" class="carousel" transition="slide" [@.disabled]="true">
            <c-carousel-inner class="image-wrapper">
                @for (item of list; track item) {
                    <c-carousel-item>
                        <img alt="{{item.filename}}"
                             class="image d-block w-100"
                             [src]="item.url"
                             loading="lazy">
                    </c-carousel-item>
                }
            </c-carousel-inner>
            <c-carousel-control class="nav-previous" direction="prev"></c-carousel-control>
            <c-carousel-control class="nav-next" direction="next"></c-carousel-control>
        </c-carousel>
    }
</app-modal>
