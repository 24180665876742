import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {takeUntil} from "rxjs/operators";
import {ModalService} from "@src/app/services/modal/modal.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-blocking-modal',
  templateUrl: './blocking-modal.component.html',
  styleUrls: ['./blocking-modal.component.scss']
})
export class BlockingModalComponent implements AfterViewInit {
  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();
  protected text: string;

  constructor(
    private _modalService: ModalService
  ) {
  }

  ngAfterViewInit(): void {
    this._modalComponent.getModalArg("text")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (text: any) => {
          this.text = text as string;
        },
        error: _ => {
        }
      });
  }
}
