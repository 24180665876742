import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {Link} from "@ess/jg-rule-executor";
import {HelpItem} from "@ess/jg-rule-executor/dist/src/domain/HelpItem";

@Component({
  selector: 'app-help-modal',
  templateUrl: './help.modal.component.html',
  styleUrls: ['./help.modal.component.scss']
})
export class HelpModalComponent implements OnDestroy {

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;

  private _componentDestroyed$: Subject<void> = new Subject();

  public helpLink: Link;
  public helpItems: HelpItem[] = [];

  constructor(
    private _modalService: ModalService,
    private _dataService: InitDataService,
    private _applicationStateService: ApplicationStateService
  ) {
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.currentScopeAndRetailerAreLoaded), take(1), takeUntil(this._componentDestroyed$)
    ).subscribe(() => {
      this.helpLink = this._dataService.currentRetailerOptions.helpLink;
      this.helpItems = (this._dataService.currentRetailerOptions.helpItems ?? []).sort((a, b) => a.order - b.order);
    });
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  // Close me up
  public close(): void {
    this.helpItems.forEach(hi => {
      try {
        (document.getElementById(`helpItem.video.${hi.order}`) as HTMLVideoElement).pause();
      } catch (e) {
      }
    });
    this._modalService.close('help-modal');
  }
}
