import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {ModalService} from "@src/app/services/modal/modal.service";
import {Asset, FatModuleVariant, Id, ModulePlacement, ModuleVariant} from "@ess/jg-rule-executor";
import {takeUntil} from "rxjs/operators";
import {FailedToRetrieveAssetError} from "@src/app/model/errors";
import {BlueprintTranslationKeys} from "@src/app/model/blueprint-translation-keys";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {ButtonType} from "@src/app/library/components/buttons/button.type";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {VariantWithCount} from "@src/app/model/variant_with_count";

@Component({
  selector: 'app-invalid-config-modal',
  templateUrl: './invalid-config-modal.component.html',
  styleUrls: ['./invalid-config-modal.component.scss']
})
export class InvalidConfigModalComponent implements OnInit, AfterViewInit, OnDestroy {

  public missingModules: ModulePlacement[] = [];
  public variantsWithCount: VariantWithCount[];
  public buttonType: ButtonType = ButtonType.secondary;

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;
  private _componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private _modalService: ModalService,
    private _loggingService: LoggingService,
    private _moduleManagementService: ModuleManagementService,
    private _interactionService: InteractionService
  ) {
  }

  /**
   * onInit, subscribe to closeEmitter (so that we can refresh the modal if necessary)
   */
  public ngOnInit() {
    this._modalService.closeEmitter
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(id => {
        if (id === 'invalid-config-modal') {
          this._modalComponent.outputEmitter.emit({});
        }
      });
  }

  public handleImageError(assetId: Id<Asset>) {
    this._loggingService.error(new FailedToRetrieveAssetError(assetId));
  }

  /**
   * afterViewInit, set up subscription on code
   */
  public ngAfterViewInit() {
    this._modalComponent.getModalArg("missingModules")
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (missingModules: ModulePlacement[]) => {
          const variants: Id<ModuleVariant>[] = missingModules.map(x => x.variantId)
          const fatVariantsWithCount: Map<Id<FatModuleVariant>, { fatVariant: FatModuleVariant, count: number }> =
            variants.reduce((acc, cur) => {
              if (acc.has(cur)) {
                // if we already have this variant, count++
                const updatedVal = acc.get(cur);
                updatedVal.count = updatedVal.count + 1;
                acc.set(cur, updatedVal);
              } else {
                // else, add variant to map
                const fatVariant = this._moduleManagementService.getFatModuleVariantById(cur);
                acc.set(cur, {fatVariant, count: 1});
              }
              return acc;
            }, new Map<Id<FatModuleVariant>, { fatVariant: FatModuleVariant, count: number }>());

          this.variantsWithCount = Array.from(fatVariantsWithCount.keys()).map(fid => {
            const title = fatVariantsWithCount.get(fid).fatVariant.blueprint.translationKeys?.get(BlueprintTranslationKeys.title) ??
              `undefined title (variantId: ${fid})`;
              const miniature = fatVariantsWithCount.get(fid).fatVariant.variant.catalogIcon;
              const count = fatVariantsWithCount.get(fid).count;
              return new VariantWithCount(title,miniature,count);
          });

          this.missingModules = missingModules;
        },
        error: _ => {
        }
      });
  }

  removeParts() {
    this._interactionService.removeMissingModules(this.missingModules);
    this.close();
  }

  replaceParts() {
    this._interactionService.replaceMissingModules(this.missingModules);
    this.close();
  }

  // Close me up
  public close(): void {
    this._modalService.close('invalid-config-modal');
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
