import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, race, Subject, switchMap, tap, timer} from "rxjs";
import {RenderService} from "@src/app/services/render/render.service";

@Component({
  selector: 'app-spinner-on-demand',
  templateUrl: './spinner-on-demand.component.html',
  styleUrl: './spinner-on-demand.component.scss'
})
export class SpinnerOnDemandComponent implements OnInit, OnDestroy {
  // Toggle to show spinning or not
  protected isSpinning$: BehaviorSubject<boolean>;

  // Timing observable that is set on starting the spinner
  private _aliveTimer?: Observable<0>;

  // If the finished indicator does not emit in this timeframe, we start the spinner
  @Input() initialToleranceMs: number = 300;
  // Minimal time the spinner should display in milliseconds
  @Input() minimalSpinningTimeMs: number = 500;

  constructor(
    private _renderService: RenderService
  ) {
    this.isSpinning$ = new BehaviorSubject(false);

  }
  /**
   * 1. emit true op spinnerSubject
   * 2. when done: emit false op spinnerSubject
   * 3. als dat sneller gebeurt dan initialTolerance: niet tonen (niet startSpinner callen), anders wel
   */
  public ngOnInit() {

    // handle logic when spinner is activated
    this._renderService.spinnerOnSubject$.pipe(
      // wait for initial true
      switchMap(
        _ => {
          return race(
            timer(this.initialToleranceMs).pipe(tap(this._startSpinning.bind(this))),
            this._renderService.spinnerOffSubject$
          )
        })
    ).subscribe();

    // handle logic when is deactivated
    this._renderService.spinnerOffSubject$.pipe(
      switchMap(_ => this._aliveTimer ?? of(0)),
      tap(this._stopSpinning.bind(this))
    ).subscribe();
  }


  /**
   * Starts the spinning process
   */
  private _startSpinning(): void {
      this.isSpinning$.next(true);
      this._aliveTimer = timer(this.minimalSpinningTimeMs);
  }

  /**
   * Stops the spinning process after a set amount of time
   */
  private _stopSpinning(): void {
    this._aliveTimer = undefined;
    this.isSpinning$.next(false);
  }

  ngOnDestroy() {
    this._renderService.spinnerOnSubject$.unsubscribe();
    this._renderService.spinnerOffSubject$.unsubscribe();
  }

}
