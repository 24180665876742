/**
 * Describes the current state of the application, from initialisation to fully operational
 */
export enum ApplicationState {
  // Initial application state
  init = 0,
  currentScopeAndRetailerAreLoaded,
  translationsAreLoaded,
  plainConfigLoaded,
  materialsAndTexturesLoaded,
  currentBlueprintsLoaded,
  catalogAndVariantsLoaded,
  configFatVariantsLoaded,
  configAssetsLoaded,
  rulesAreLoaded,
  appInitialized,
  fullyOperational
}
