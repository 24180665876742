import {INGXLoggerConfig, INGXLoggerMetadata, INGXLoggerMonitor, NgxLoggerLevel} from 'ngx-logger';
import {environment} from "@src/environments/environment";
import * as Sentry from '@sentry/browser';
import {SeverityLevel} from '@sentry/browser';

export class SentryLoggingMonitor implements INGXLoggerMonitor {
  private _ngxSentryMap: Map<NgxLoggerLevel, SeverityLevel> = new Map([
    [NgxLoggerLevel.TRACE, "debug"],
    [NgxLoggerLevel.DEBUG, "debug"],
    [NgxLoggerLevel.INFO, "info"],
    [NgxLoggerLevel.LOG, "log"],
    [NgxLoggerLevel.WARN, "warning"],
    [NgxLoggerLevel.ERROR, "error"],
    [NgxLoggerLevel.FATAL, "fatal"],
  ]);

  // We only send the log levels above the noted level in the environment
  private _minimalLogLevel: NgxLoggerLevel = NgxLoggerLevel[environment.logging.minLogLevel];

  public onLog(logObject: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    if (
      this._minimalLogLevel !== NgxLoggerLevel.OFF &&
      this._minimalLogLevel <= logObject.level
    ) {
      if (logObject.level === NgxLoggerLevel.ERROR || logObject.level === NgxLoggerLevel.FATAL) {
        try {
          Sentry.captureException(new Error(logObject.message), {extra: logObject.additional[0]});
          return;
        } catch {
          // Continue and logObject error as message instead
        }
      }
      const msg: string = logObject.message + '\n' + logObject.additional;
      Sentry.captureMessage(msg, {level: this._ngxSentryMap.get(logObject.level)});
    }
  }
}
