<div class="textfield-container" [ngClass]="{'wide-field' : wideField}">
    <div class="textfield-border-container">
        <div class="textfield-name font-caption">{{fieldnameKey | translate}}</div>
        <input
                type="text"
                placeholder="{{placeholderKey | translate}}"
                class="textfield font-body1"
                (change)="change($event)"
                [value]="value"
                [disabled]="isDisabled"
        >
    </div>
</div>
