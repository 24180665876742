import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Icon, iconSource} from "@src/app/library/components/icon";

@Component({
  selector: 'app-icon-series-button',
  templateUrl: './icon-series-button.component.html',
  styleUrls: ['./icon-series-button.component.scss']
})
export class IconSeriesButtonComponent implements OnInit {
  // List of button icons with data needed
  @Input() public buttons: { name?: string, icon: Icon, disabled: boolean }[] = [];
  // Delay after click for second click
  @Input() public clickDelay?: number;
  // Indicate if all buttons should be disabled together
  @Input() public disableTogether: boolean = false;
  // Indicates if, when clicked, should be re-enabled or not
  @Input() public manualReactivation: boolean = false;
  // Indicates if the buttons are disabled or not
  @Input() public disabled: boolean = false;
  // change button to be a square
  @Input() public styleInput: string = '';
  // text underneath icon
  @Input() public text: string = '';
  // get icon from link when true
  @Input() customIcon: boolean = false;
  // get icon from link when true
  @Input() normalIcon: boolean = false;

  // Emitter for click
  @Output() public onClick: EventEmitter<string> = new EventEmitter<string>();
  // Emitter for disabled change
  @Output() public disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Variables for icon source to prevent change detection
  public iconSources: string[];

  // Startup, set icons
  public ngOnInit(): void {
    this.iconSources = this.buttons.map(s => this.customIcon ? s.icon : iconSource(s.icon));
  }

  /**
   * Click function.
   * Prevents second click for a given delay or if manual enabling is needed.
   */
  public onClickFn(button: { name?: string, icon: Icon, disabled: boolean }): void {
    this.onClick.emit(button.name ? button.name : button.icon);
    // If the button should delay of manual reactivation is needed, disable
    if (this.clickDelay || this.manualReactivation) {
      this.disabledChange.emit(true);
      // Now if manual reactivation is not required, set a timeout to reactivate after set delay
      if (!this.manualReactivation) {
        setTimeout(() => {
          this.disabledChange.emit(false);
        }, this.clickDelay);
      }
    }
  }

}
