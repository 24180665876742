import {Component, OnDestroy} from '@angular/core';
import {bulletpointSVG} from "@src/app/services/utils/text-utils";
import {WoodType} from "@ess/jg-rule-executor";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {combineLatestWith, filter, switchMap, take, takeUntil} from "rxjs/operators";
import {ApplicationState} from "@src/app/model/applicationstate";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {Subject} from "rxjs";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {RenderService} from "@src/app/services/render/render.service";

@Component({
  selector: 'app-wood-type-selection',
  templateUrl: './wood-type-selection.component.html',
  styleUrls: ['./wood-type-selection.component.scss']
})
export class WoodTypeSelectionComponent implements OnDestroy {
  private _componentDestroyed$: Subject<void> = new Subject();
  public grenenPrice: string;
  public douglasPrice: string;
  public woodTypes: string[] = [];
  public selectedWoodType: WoodType;
  public bulletPoint = bulletpointSVG;


  constructor(
    private _interactionService: InteractionService,
    private _applicationStateService: ApplicationStateService,
    private _priceCalculatorService: PriceCalculatorService,
    private _dataService: InitDataService,
    protected _renderService: RenderService
  ) {
    this._applicationStateService.getState$().pipe(
      filter(t => t >= ApplicationState.appInitialized),
      take(1),
      switchMap(_ => this._interactionService.getConfigurationChanged$()),
      filter(c => !!c),
      combineLatestWith(this._priceCalculatorService.currentPrice$()),
      takeUntil(this._componentDestroyed$),
    ).subscribe(([config, _]) => {
      this.selectedWoodType = config.configurationPlacement.woodType;
      const gp = this._priceCalculatorService.queryPriceForConfiguration(config, WoodType.grenen);
      const dp = this._priceCalculatorService.queryPriceForConfiguration(config, WoodType.douglas);
      this.grenenPrice = `${this._priceCalculatorService.priceFormatter(gp)}`;
      this.douglasPrice = `${this._priceCalculatorService.priceFormatter(dp)}`;
    });


    this.woodTypes = this._dataService.currentRetailerOptions?.woodTypesAvailable.map(w => w.woodType);
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.currentScopeAndRetailerAreLoaded), take(1), takeUntil(this._componentDestroyed$)
    ).subscribe(_ => {
      this.woodTypes = this._dataService.currentRetailerOptions?.woodTypesAvailable
        .filter(w => w.available === true)
        .map(w => w.woodType);
    });
  }

  public selectWoodType(woodType: string): void {
    this.selectedWoodType = WoodType[woodType];
    this._interactionService.setConfigurationWoodType(this.selectedWoodType);
  }

  protected readonly WoodType = WoodType;

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}



