import {Id, ModulePlacement, XAttachmentPoint} from "@ess/jg-rule-executor";
import {SpriteData} from "@src/app/model/sprite-data";
import {Sprite} from "three";

export class AttachmentPointInfo {
  public placementId: Id<ModulePlacement>;
  public xAttachmentId?: Id<XAttachmentPoint>;
  public childIds?: Id<XAttachmentPoint>[];

  constructor(
    placementId: Id<ModulePlacement>,
    xAttachmentId?: Id<XAttachmentPoint>,
    childIds?: Id<XAttachmentPoint>[]
  ) {
    this.placementId = placementId;
    this.xAttachmentId = xAttachmentId;
    this.childIds = childIds;
  }

  static fromSpriteData(spriteData: SpriteData) {
    return new AttachmentPointInfo(
      spriteData.modulePlacementId,
      spriteData.xAttachmentId,
      spriteData.childIds
    );
  }

  /**
   * create APInfo from sprite.
   * DOES NOT DO NULL CHECK
   */
  static fromSprite(sprite: Sprite) {
    const spriteData: SpriteData = SpriteData.fromSprite(sprite);
    return AttachmentPointInfo.fromSpriteData(spriteData);
  }
}
