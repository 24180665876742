@if (loadingFinished) {
    <div class="header">
        @if (showSteps) {
            <div class="steps landscape">
                <app-button class="step-button"
                            [label]="(currentViewIndex > 1 ? '' : '1.') + ('app.steps.product-list.large' | translate)"
                            [buttonType]="currentViewIndex === 1 ? 'active-step' : (currentViewIndex > 1 ? 'completed-step' : 'inactive-step')"
                            [leftIcon]="'checkmark'"
                            [smallButton]="true"
                            [hideLeftIcon]="currentViewIndex === 1"
                            [boldText]="false"
                            [disabled]="this.plpDisabled"
                            (onClick)="changeViewClick(1)"> <!-- TODO: Fix the hiding -->
                </app-button>
                <app-line></app-line>
                <app-button class="step-button"
                            [label]="(currentViewIndex > 2 ? '' : '2.') + ('app.steps.edit.large' | translate)"
                            [buttonType]="currentViewIndex == 2 ? 'active-step' : (currentViewIndex > 2 ? 'completed-step' : 'inactive-step')"
                            [leftIcon]="'checkmark'"
                            [smallButton]="true"
                            [hideLeftIcon]="currentViewIndex <= 2"
                            [boldText]="false"
                            [disabled]="!dataService.currentPreConfigurationCode"
                            (onClick)="changeViewClick(2)">
                </app-button>
                <app-line/>
                <app-button class="step-button"
                            [label]="(currentViewIndex > 3 ? '' : '3.') + ('app.steps.check.large' | translate)"
                            [buttonType]="currentViewIndex == 3 ? 'active-step' : (currentViewIndex > 3 ? 'completed-step' : 'inactive-step')"
                            [smallButton]="true"
                            [disabled]="!dataService.currentPreConfigurationCode || currentViewIndex <= 1 || (!this.rulesAreLoaded && currentViewIndex == 2)"
                            [boldText]="false"
                            (onClick)="changeViewClick(3)">
                </app-button>
            </div>
            <div class="steps portrait">
                <app-button class="step-button"
                            [label]="'app.steps.product-list.small' | translate"
                            [buttonType]="currentViewIndex == 1 ? 'active-step' : (currentViewIndex > 1 ? 'completed-step' : 'inactive-step')"
                            [circleButton]="true"
                            [smallButton]="true"
                            (onClick)="changeViewClick(1)">
                </app-button>
                <app-line></app-line>
                <app-button class="step-button"
                            [label]="'app.steps.edit.small' | translate"
                            [buttonType]="currentViewIndex == 2 ? 'active-step' : (currentViewIndex > 2 ? 'completed-step' : 'inactive-step')"
                            [circleButton]="true"
                            [smallButton]="true"
                            [disabled]="!dataService.currentPreConfigurationCode"
                            (onClick)="changeViewClick(2)">
                </app-button>
                <app-line/>
                <app-button class="step-button"
                            [label]="'app.steps.check.small' | translate"
                            [buttonType]="currentViewIndex == 3 ? 'active-step' : (currentViewIndex > 3 ? 'completed-step' : 'inactive-step')"
                            [circleButton]="true"
                            [smallButton]="true"
                            [disabled]="!dataService.currentPreConfigurationCode || currentViewIndex <= 1 || (!this.rulesAreLoaded && currentViewIndex == 2)"
                            (onClick)="changeViewClick(3)">
                </app-button> <!-- TODO: Make buttons and checkmarks smaller! -->
            </div>
        } @else {
            @if (loadingFinished) {
                <div class="logo-header">
                    <img [src]="dataService.currentRetailerOptions?.configuratorOptions.banner?.url"
                         crossorigin="anonymous"
                         class="banner-image"/>
                    <p class="font-overline">{{ 'app.header' | translate }}</p>
                </div>
            }
        }
    </div>
}

<ng-template #banner>
    @if (loadingFinished) {
        <div class="logo-header">
            <img [src]="dataService.currentRetailerOptions?.configuratorOptions.banner?.url"
                 crossorigin="anonymous"
                 class="banner-image"/>
            <p class="font-overline">{{ 'app.header' | translate }}</p>
        </div>
    }
</ng-template>
