import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ButtonComponent} from "@src/app/library/components/buttons/button/button.component";
import {AngularSvgIconModule} from "angular-svg-icon";
import {IconSeriesButtonComponent} from "@src/app/library/components/buttons/icon-series-button/icon-series-button.component";
import {CardComponent} from "@src/app/library/components/card/card.component";
import {CheckboxComponent} from "@src/app/library/components/checkbox/checkbox.component";
import {TranslateModule} from "@ngx-translate/core";
import {TextfieldComponent} from "@src/app/library/components/textfield/textfield.component";
import { LineComponent } from './components/shapes/line/line.component';

// Library module to combine all 'components' that can be imported in the app module.
@NgModule({
  declarations: [
    ButtonComponent,
    IconSeriesButtonComponent,
    CardComponent,
    CheckboxComponent,
    TextfieldComponent,
    LineComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    TranslateModule
  ],
  exports: [
    ButtonComponent,
    IconSeriesButtonComponent,
    CardComponent,
    CheckboxComponent,
    TextfieldComponent,
    LineComponent,
  ]
})
export class LibraryModule {
}
