import {Injectable} from '@angular/core';
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {Icon} from "@src/app/library/components/icon";
import {TranslateService} from "@ngx-translate/core";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  // Toggle to disable all notifications
  private _allDisabled: boolean = false;

  constructor(
    private _toastService: ToastrService,
    private _dataService: InitDataService,
    private _translateService: TranslateService
  ) {
  }

  /**
   * Sets the toggle to enable or disable all notifications
   */
  public disableAllNotifications(disabled: boolean): void {
    this._allDisabled = disabled;
  }

  /**
   * Add a notification to the notification container
   *
   * @param messageKey - the messageKey to display in the notification, is translated
   * @param icon - the icon to display on the left side of the notification
   * @param cardType - the type of the notification card, mostly for styling
   * @param interpolationParams - optional interpolation parameters for the translation
   */
  public showNotification(
    messageKey: string,
    icon: Icon = Icon.warn,
    cardType: NotificationCardType = NotificationCardType.info,
    interpolationParams?: object
  ) {
    if (!this._allDisabled) {
      const messageValue = this._translateService.instant(messageKey, interpolationParams).trim();
      // If the translation value is an empty string and debug mode is on (so when there is a jwt token), just show the message key
      const messageToShow = this._dataService.authJwtToken && messageValue === "" ? messageKey : messageValue;

      if (messageToShow !== "") {
        this._toastService.show(
          messageToShow,
          icon, // We use the title field to pass on the icon, as we don't use titles anyway
          {toastClass: cardType === NotificationCardType.warning ? "warning" : "info"}
        )
      }
    }
  }
}
