<app-modal id="installation-service-modal" #modalComponent (closing)="close()">
    <div class="installation-service-modal-container">
        <h2 class="font-h2">{{ "app.modal.installation_service.header" | translate }}</h2>
        <p class="font-body2 bottom-spacer" [innerHTML]="'app.modal.installation_service.body1' | translate"></p>
        @if (installationServiceAvailable) {
            <app-installation-service-selection/>
        } @else {
            <b class="font-body2 bottom-spacer">{{ "app.installation_service.not_available" | translate }}</b>
        }
    </div>
</app-modal>
