export enum ViewMode {
  loading,
  plp,
  edit,
  preview,
  cleanTop,
  cleanFront,
  cleanSide,
  cleanNice,
}
