// eslint-disable-next-line max-classes-per-file
import {TranslateService} from "@ngx-translate/core";

export class GammaProductRequestMessage {
  products: GammaProduct[];
  signature: string; //64-char uuid

  constructor(products: GammaProduct[], signature: string) {
    this.products = products;
    this.signature = signature;
  }

  static fromObject(input: {products: object[], signature: string}): GammaProductRequestMessage {
    return new GammaProductRequestMessage(input.products.map(GammaProduct.fromObject), input.signature)
  }
}

export class GammaResetRequestMessage {
  action: GammaAction = GammaAction.RESET;
  height: string;

  constructor(height: string) {
    this.height = height;
  }
}

export class GammaResponseMessage {
  products?: GammaProductResponse[];
  error?: string;
}

export class GammaProductResponse {
  barcode: string;
  action: GammaAction;
  quantity?: number;
  success: boolean;
  message?: string;
}

export class GammaProduct {
  barcode: string;
  action: GammaAction;
  materials?: GammaMaterial[];
  imageURL?: string;
  title?: string;
  quantity?: number;
  category: string; // special gamma category; likely needs a default value
  deliveryType?: string; // note that this is a VERY big enum; for now left as string
  deliveryTime?: number; // deliveryTime in days
  price: number; // purchase price
  purchasePrice: string; // encrypted purchase price

  constructor(
    barcode: string,
    action: GammaAction,
    materials: GammaMaterial[],
    imageURL: string,
    title: string,
    quantity: number,
    category: string,
    deliveryType: string,
    deliveryTime: number,
    price: number,
    purchasePrice: string
  ) {
    this.barcode = barcode;
    this.action = action;
    this.materials = materials;
    this.imageURL = imageURL;
    this.title = title;
    this.quantity = quantity;
    this.category = category;
    this.deliveryType = deliveryType;
    this.deliveryTime = deliveryTime;
    this.price = price;
    this.purchasePrice = purchasePrice;
  }

  static fromObject(input: {
    barcode: string;
    action: GammaAction;
    materials?: object[];
    imageURL?: string;
    title?: string;
    quantity?: number;
    category: string; // special gamma category; likely needs a default value
    deliveryType?: string; // note that this is a VERY big enum; for now left as string
    deliveryTime?: number; // deliveryTime in days
    price: number; // purchase price
    purchasePrice: string;
  }): GammaProduct {
    return new GammaProduct(
      input.barcode,
      input.action,
      input.materials.map(GammaMaterial.fromObject),
      input.imageURL,
      input.title,
      input.quantity,
      input.category,
      input.deliveryType,
      input.deliveryTime,
      input.price,
      input.purchasePrice
    );
  }
}

export enum GammaAction {
  ADD = "ADD",
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
  RESET = "RESET"
}

export class GammaMaterial {
  code: string;
  value: string;

  constructor(code: string, value: string) {
    this.code = code;
    this.value = value;
  }

  static fromObject(input: {code: string, value: string}): GammaMaterial {
    return new GammaMaterial(input.code, input.value);
  }
}
