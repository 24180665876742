import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {Subject} from "rxjs";
import {ModalService} from "@src/app/services/modal/modal.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {RenderService} from "@src/app/services/render/render.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-ar-instruction-modal',
  templateUrl: './ar-instruction-modal.component.html',
  styleUrls: ['./ar-instruction-modal.component.scss']
})
export class ArInstructionModalComponent implements OnDestroy {

  @ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;

  private _componentDestroyed$: Subject<void> = new Subject();

  public loadingFinished: boolean = false;

  public arAllowed: { ios: boolean, android: boolean };
  public arAvailable: boolean = false;
  public isIos: boolean = navigator.userAgent.match(/iPad|iPhone|iPod/) != null;
  public arActivationClicked: boolean = false;
  private _arActive: boolean = false;

  public disableStartButton: boolean = this.startButtonDisabled();

  constructor(
    private _modalService: ModalService,
    private _dataService: InitDataService,
    protected _renderService: RenderService,
    private _applicationStateService: ApplicationStateService,
    private _translateService: TranslateService
  ) {
    this._applicationStateService.getState$().pipe(
      filter(t => t === ApplicationState.configAssetsLoaded), take(1), takeUntil(this._componentDestroyed$)
    ).subscribe(() => {
      this.loadingFinished = true;
      this.arAllowed = _dataService.arAllowed;
      this.disableStartButton = this.startButtonDisabled();
    });
    _renderService.ARAvailable.pipe(takeUntil(this._componentDestroyed$)).subscribe(n => this.arAvailable = n);
    _renderService.ARActive.pipe(takeUntil(this._componentDestroyed$)).subscribe(n => this._arActive = n);
  }

  public toggleAR(): void {
    if (this.arAvailable) {
      // Disable button
      this.arActivationClicked = true;
      document.getElementById("ARBUTTON")?.click();
      // If after 1.5 seconds AR is not started, inform user. This could be due to ARKit being not available
      // Unfortunately this is not checkable.
      setTimeout(() => {
        this.close();
        if (!this._arActive) {
          this._arNotSupportedAlert();
        } else {
          this.arActivationClicked = false;
        }
      }, 2500);
    } else if (this.isIos) {
      this.close();
      this._renderService.downloadUsdzScene();
      this.arActivationClicked = false;
    } else {
      this.close();
      this._arNotSupportedAlert();
    }
  }

  private _arNotSupportedAlert(): void {
    window.alert(this._translateService.instant("app.modal.ar.not_supported"));
  }

  public startButtonDisabled(): boolean {
    return (!this.loadingFinished || (!this.isIos && !this.arAllowed.android) || (this.isIos && !this.arAllowed.ios));
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  // Close me up
  public close(): void {
    this._modalService.close('ar-instruction-modal');
  }
}
