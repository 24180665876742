import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PlatformServiceShared} from './platform.service.shared';
import {PlatformService} from './platform.service.interface';

const PLATFORM = 'WEB';

/**
 * Example service that demonstrates various ways to separate and share logic between platforms:
 *  - web-specific implementation of an interface shared between different platforms (PlatformService)
 *  - web-specific implementation of service logic (the `get()` method)
 *  - web-specific exposure of logic shared between different platforms (PlatformServiceShared)
 */
@Injectable({
  providedIn: 'root'
})
export class PlatformServiceImpl extends PlatformServiceShared implements PlatformService {

  constructor() {
    super(PLATFORM);
  }

  get(): Observable<string> {
    return of(PLATFORM);
  }
}
