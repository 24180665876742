import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {environment} from "@src/environments/environment";
import {SentryLoggingMonitor} from "@src/app/services/logging/sentry-logging-monitor/sentry-logging-monitor";

/* tslint:disable:max-classes-per-file */

/**
 * Service that handles logging of various things in the app.
 * In the constructor a monitor is bind which processes the errors.
 * Note, only one monitor can be registered at a time.
 * See https://www.npmjs.com/package/ngx-logger.
 */
@Injectable()
export class LoggingService {

  constructor(private logger: NGXLogger) {
    if (environment.logging.serverLoggingEnabled) {
      this.logger.registerMonitor(new SentryLoggingMonitor());
    }
  }

  // The functions below handle the several error cases.

  public trace(message: string, additional: string[] = []): void {
    this.logger.trace(message, additional);
  }

  public debug(message: string, additional: string[] = []): void {
    this.logger.debug(message, additional);
  }

  public info(message: string, additional: string[] = []): void {
    this.logger.info(message, additional);
  }

  public log(message: string, additional: string[] = []): void {
    this.logger.log(message, additional);
  }

  public warn(message: string, additional: string[] = []): void {
    this.logger.warn(message, additional);
  }

  public error(error: Error, additional: string[] = []): void {
    this.logger.error(error, additional);
  }

  public fatal(error: Error = null, additional: string[] = []): void {
    this.logger.fatal(error, additional);
  }
}
