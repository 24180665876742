/* eslint-disable max-classes-per-file */
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of, OperatorFunction, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Apollo, QueryRef} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {
  ApolloLink,
  ApolloQueryResult,
  FetchResult,
  InMemoryCache,
  MutationOptions, Operation,
  OperationVariables,
  QueryOptions,
  WatchQueryOptions
} from '@apollo/client/core';
import {
  Configuration,
  ConfigurationOrder,
  Id,
  Material,
  ModuleBlueprint,
  ModulePlacement,
  ModuleVariant,
  RetailerOptions, SpacialPosition,
  Texture,
  WoodType
} from "@ess/jg-rule-executor";
import {GetAllAvailableModuleBlueprints} from "@src/app/generated/GetAllAvailableModuleBlueprints";
import {GqlConverterUtil, ModuleBlueprintWithWoodVariants} from "@src/app/services/utils/gqlConverter.util";
import {MUTATIONS, QUERIES} from "@src/app/services/apollo/apollo-queries";
import {GetBlueprintById} from "@src/app/generated/GetBlueprintById";
import {GetCatalog} from "@src/app/generated/GetCatalog";
import {GetVariantById} from "@src/app/generated/GetVariantById";
import {GetConfigurationByCode} from "@src/app/generated/GetConfigurationByCode";
import {UploadConfiguration} from "@src/app/generated/UploadConfiguration";
import {PlaceOrder} from "@src/app/generated/PlaceOrder";
import {JgApolloMemoryCache} from "@src/app/services/apollo/jg-apollo-memory-cache";
import {UpdateConfiguration} from "@src/app/generated/UpdateConfiguration";
import {
  ConfigurationPlacementInput,
  IdModulePlacementListSpacialPositionInputInputMap,
  RenderStateInput,
  UndoConfigurationInput
} from "@src/app/generated/globalTypes";
import {GetCurrentScopeId} from "@src/app/generated/GetCurrentScopeId";
import {GetTranslations} from "@src/app/generated/GetTranslations";
import {GetCurrentRetailerOptions} from "@src/app/generated/GetCurrentRetailerOptions";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {GetAllModuleBlueprintsDraft} from "@src/app/generated/GetAllModuleBlueprintsDraft";
import {GetCatalogDraft} from "@src/app/generated/GetCatalogDraft";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {GetMaterialsAndTextures} from "@src/app/generated/GetMaterialsAndTextures";
import {MarkerPlacement} from "@src/app/model/marker-placement";
import {GetMarkerPlacement} from "@src/app/generated/GetMarkerPlacement";
import {v4 as uuidv4} from 'uuid';
import {GetUnavailableModuleBlueprints} from "@src/app/generated/GetUnavailableModuleBlueprints";
import {ConfiguratorPreConfiguration} from "@src/app/model/configurator-preconfiguration";
import {GetPreConfigurationsByScope} from "@src/app/generated/GetPreConfigurationsByScope";
import {GetAllPublishedModuleBlueprints} from "@src/app/generated/GetAllPublishedModuleBlueprints";

@Injectable({
  providedIn: 'root'
})
export class ApolloServiceConfig {
  public backendUrl: string = 'http://localhost:9000';
  public gqlEntryPoint: string = '/graphql/graphql';
}

@Injectable({
  providedIn: 'root'
})
export class ApolloService {
  private _userIdent: string = `${uuidv4()}`;

  constructor(
    private _config: ApolloServiceConfig,
    private _apollo: Apollo,
    private _httpLink: HttpLink,
    private _dataService: InitDataService,
    private _loggingService: LoggingService,
  ) {
    // Setup
    const cache: InMemoryCache = new JgApolloMemoryCache(_loggingService);
    const link: ApolloLink = ApolloLink.from([this._httpLink.create({
      uri: (operation: Operation) => _config.backendUrl + _config.gqlEntryPoint + '?' + new URLSearchParams({
        _o: operation?.operationName,
        _i: this._userIdent,
        _s: this._dataService.currentScope
      }).toString(),
      withCredentials: true
    })]);
    this._apollo.create({
      link,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      },
      cache
    });
  }

  /**
   * Watch a query with results
   */
  public watchQuery<T, V = OperationVariables>(options: WatchQueryOptions<V>): QueryRef<T, V> {
    return this._apollo.watchQuery<T, V>(options);
  }

  /**
   * Execute query
   */
  public query$<T, V = OperationVariables>(options: QueryOptions<V>): Observable<ApolloQueryResult<T>> {
    return this._apollo.query<T>(options);
  }

  /**
   * Execute mutation
   */
  public mutate$<T, V = OperationVariables>(options: MutationOptions<T, V>): Observable<FetchResult<T>> {
    return this._apollo.mutate<T, V>(options);
  }

  private _getQueryString(qsData: Map<string, string>): string {
    let query = "";
    qsData.forEach((v, k) => {
      if (v && v !== "") {
        if (query === "") {
          query += "?";
        } else {
          query += "&";
        }
        query += `${k}=${v}`;
      }
    });
    return query;
  }

  /**
   * Fetches all module blueprints from backend
   */
  public fetchAllPublishedModuleBlueprints$(): Observable<ModuleBlueprintWithWoodVariants[]> {
    if (this._dataService.authJwtToken) {
      // If a JWT token has been supplied, get the draft blueprints
      return this.query$<GetAllModuleBlueprintsDraft>({
        query: QUERIES.getAllModuleBlueprintsDraft,
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertAllBlueprintsResponseDraft(r))
      );
    } else {
      // Otherwise, get only the regular published blueprints
      return this.query$<GetAllPublishedModuleBlueprints>({
        query: QUERIES.getAllPublishedModuleBlueprints,
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertAllPublishedBlueprintsResponse(r))
      );
    }
  }

  public fetchAllAvailableModuleBlueprints$(): Observable<ModuleBlueprintWithWoodVariants[]> {
    if (this._dataService.authJwtToken) {
      // If a JWT token has been supplied, get the draft blueprints
      return this.query$<GetAllModuleBlueprintsDraft>({
        query: QUERIES.getAllModuleBlueprintsDraft,
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertAllBlueprintsResponseDraft(r))
      );
    } else {
      return this.query$<GetAllAvailableModuleBlueprints>({
        query: QUERIES.getAllAvailableModuleBlueprints,
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertAllAvailableBlueprintsResponse(r))
      );
    }
  }

  /**
   * Fetches all unavailable module blueprints from backend
   */
  public fetchUnavailableModuleVariantIds$(): Observable<Map<WoodType, Id<ModuleVariant>[]>> {
    return this.query$<GetUnavailableModuleBlueprints>({
      query: QUERIES.getUnavailableModuleBlueprints,
      fetchPolicy: 'no-cache'
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertUnavailableVariantIds(r))
    );
  }

  /**
   * Helper to execute a query for a blueprint based on Id
   */
  public fetchBlueprintById$(id: Id<ModuleBlueprint>): Observable<ModuleBlueprintWithWoodVariants> {
    return this.query$<GetBlueprintById>({
      query: QUERIES.getBlueprintById,
      variables: {id}
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetBlueprintByIdResponse(r))
    );
  }

  /**
   * Gets a module variant based on the id provided
   *
   * @param id The Id of the variant to fetch
   */
  public fetchVariantById$(id: Id<ModuleVariant>): Observable<Map<WoodType, ModuleVariant>> {
    return this.query$<GetVariantById>({
      query: QUERIES.getVariantById,
      variables: {id}
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetVariantByIdResponse(r))
    );
  }

  /**
   * Fetches the catalog from the backend
   */
  public fetchCatalog$(purchasePriceMandatory: boolean = false): Observable<Map<WoodType, ConfiguratorCatalog>> {
    if (this._dataService.authJwtToken) {
      // If a JWT token has been supplied, get the draft catalog
      return this.query$<GetCatalogDraft>({
        query: QUERIES.getCatalogDraft,
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertGetCatalogResponseDraft(r))
      );
    } else {
      // Otherwise, get the regular catalog with only published blueprints and variants
      return this.query$<GetCatalog>({
        query: QUERIES.getCatalog,
        variables: {purchasePriceMandatory},
        fetchPolicy: 'no-cache'
      }).pipe(
        this._switchErrorAndConvert(r => GqlConverterUtil.convertGetCatalogResponse(r))
      );
    }
  }

  /**
   * Fetches a configuration based on the id provided
   *
   * @param code The code of the configuration to fetch
   */
  public getConfigurationByCode$(code: string): Observable<Configuration> {
    return this.query$<GetConfigurationByCode>({
      query: QUERIES.getConfigurationByCode,
      variables: {code},
      fetchPolicy: "no-cache"
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetConfigurationByCodeResponse(r))
    ) as Observable<Configuration>;
  }

  /**
   * Fetches all configuration based on the scope provided
   *
   * @param scope The scope of the configuration to fetch
   */
  public getPreconfigurationsByScope$(scope: string): Observable<ConfiguratorPreConfiguration[]> {
    return this.query$<GetPreConfigurationsByScope>({
      query: QUERIES.getPreConfigurationsByScope,
      variables: {scope},
      fetchPolicy: "no-cache"
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetPreConfigurationsByScopeResponse(r))
    ) as Observable<ConfiguratorPreConfiguration[]>;
  }

  /**
   * Fetches the current scope id
   */
  public getCurrentScopeId$(): Observable<string> {
    return this.query$<GetCurrentScopeId>({
      query: QUERIES.getCurrentScopeId
    }).pipe(
      this._switchErrorAndConvert(r => r.currentScopeId)
    ) as Observable<string>;
  }

  /**
   * Fetches the retailer options for the current scope
   */
  public getCurrentRetailerOptions$(): Observable<RetailerOptions> {
    return this.query$<GetCurrentRetailerOptions>({
      query: QUERIES.getCurrentRetailerOptions
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetCurrentRetailerOptionsResponse(r))
    ) as Observable<RetailerOptions>;
  }

  /**
   * Fetches the translations (for the current scope)
   */
  public getTranslations$(): Observable<object> {
    return this.query$<GetTranslations>({
      query: QUERIES.getTranslations
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetTranslationsResponse(r))
    ) as Observable<object>;
  }

  /**
   * Gets all textures and materials from the backend
   */
  public getAllTexturesAndMaterials$(): Observable<{ materials: Material[], textures: Texture[] }> {
    return this.query$<GetMaterialsAndTextures>({
      query: QUERIES.getMaterialsAndTextures
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetMaterialsAndTextures(r))
    ) as Observable<{ materials: Material[], textures: Texture[] }>;
  }

  /**
   * Get marker placement info
   *
   * @param code config code
   */
  public getConfigurationMarkers$(code: string): Promise<MarkerPlacement> {
    return firstValueFrom(this.query$<GetMarkerPlacement>({
      query: QUERIES.getMarkerPlacement,
      variables: {
        code
      }
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertGetMarkerResponse(r))
    ));
  }

  /**
   * Saves a configuration in the database, and returns the 6-digit code
   */
  public uploadConfiguration$(configuration: Configuration): Observable<string> {
    const input: ConfigurationPlacementInput = GqlConverterUtil.convertToConfigurationPlacementInput(configuration.configurationPlacement);
    const undoInput: UndoConfigurationInput[] = GqlConverterUtil.convertToUndoConfigurationInput(configuration.history);
    const renderStateInput: RenderStateInput = GqlConverterUtil.convertToRenderStateInput(configuration.renderState);

    return this.mutate$<UploadConfiguration>({
      mutation: MUTATIONS.uploadConfiguration,
      variables: {
        configurationPlacement: input,
        history: undoInput,
        renderState: renderStateInput,
        installationService: configuration.installationService
      }
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertUploadConfigurationResponse(r))
    ) as Observable<string>;
  }

  /**
   * Updates a configuration, and returns boolean based on success or failure
   */
  public updateConfiguration$(
    code: string,
    hasAcceptedTerms: boolean,
    name?: string,
    email?: string,
  ): Observable<boolean> {
    return this.mutate$<UpdateConfiguration>({
      mutation: MUTATIONS.updateConfiguration,
      variables: {
        code,
        name,
        email,
        hasAcceptedTerms
      }
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertUpdateConfigurationResponse(r))
    ) as Observable<boolean>;
  }

  /**
   * Sends configuration to backend and returns configurationOrder with calculated price and EAN
   */
  public placeConfigurationOrder$(configuration: Configuration, price: number, placementsInfo: Map<Id<ModulePlacement>, SpacialPosition[]>):
    Observable<{ order: ConfigurationOrder, payload?: string }> {
    const input: ConfigurationPlacementInput = GqlConverterUtil.convertToConfigurationPlacementInput(configuration.configurationPlacement);
    const placementsInfoInput : IdModulePlacementListSpacialPositionInputInputMap = GqlConverterUtil.convertToPlacementInfoInput(placementsInfo);
    return this.mutate$<PlaceOrder>({
      mutation: MUTATIONS.placeOrder,
      variables: {
        configurationPlacement: input,
        price,
        placementsInfo: placementsInfoInput,
        installationServiceEnabled: configuration.installationService
      }
    }).pipe(
      this._switchErrorAndConvert(r => GqlConverterUtil.convertPlaceOrderResponse(r))
    ) as Observable<{ order: ConfigurationOrder, payload?: string }>;
  }

  /**
   * Helper operator function to reduce boilerplate
   */
  private _switchErrorAndConvert<T, U>(converter: (t: T) => U): OperatorFunction<ApolloQueryResult<T>, U> {
    return switchMap<ApolloQueryResult<T>, Observable<U>>(r => {
      if (r.errors) {
        return throwError(() => r.errors);
      } else {
        return of(converter(r.data));
      }
    });
  }
}
