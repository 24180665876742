import {set} from "lodash";
import {Euler, Matrix4, Plane, Vector2, Vector3} from "three";
import {
  Asset,
  Attachment,
  BoundingBox,
  BoundingBoxSize,
  ColorCodes,
  Configuration,
  ConfigurationOrder,
  ConfigurationPlacement,
  Font,
  Id,
  id,
  Invisible,
  Link,
  Material,
  MaterialChange,
  ModuleBlueprint,
  ModulePlacement,
  ModuleType,
  ModuleVariant,
  RenderState,
  RetailerOptions,
  SpacialPosition,
  Texture,
  UndoConfiguration,
  VisualChange,
  VisualXAttachmentPoint,
  WebhookGenerators,
  WebhookOptions,
  WebhookUrls,
  WoodType,
  WoodTypeAvailable,
  woodTypes,
  XAttachmentPoint,
  YAttachmentPoint
} from "@ess/jg-rule-executor";
import {GetBlueprintById} from "@src/app/generated/GetBlueprintById";
import {GetVariantById, GetVariantById_variant_variant, GetVariantById_variant_variant_visualChanges} from "@src/app/generated/GetVariantById";
import {UploadConfiguration} from "@src/app/generated/UploadConfiguration";
import {PlaceOrder, PlaceOrder_order} from "@src/app/generated/PlaceOrder";
import {UpdateConfiguration} from "@src/app/generated/UpdateConfiguration";
import {GetTranslations} from "@src/app/generated/GetTranslations";
import {GetCatalog, GetCatalog_catalog} from "@src/app/generated/GetCatalog";
import {
  GetConfigurationByCode,
  GetConfigurationByCode_configuration,
  GetConfigurationByCode_configuration_configurationPlacement,
  GetConfigurationByCode_configuration_configurationPlacement_placements,
  GetConfigurationByCode_configuration_configurationPlacement_placements_xAttachments,
  GetConfigurationByCode_configuration_configurationPlacement_placements_xAttachments_values_value,
  GetConfigurationByCode_configuration_history,
  GetConfigurationByCode_configuration_history_configurationPlacement,
  GetConfigurationByCode_configuration_renderState
} from "@src/app/generated/GetConfigurationByCode";
import {
  ConfigurationPlacementInput,
  IdXAttachmentPointAttachmentInputMap,
  IdModulePlacementListSpacialPositionInputInputMap,
  ModulePlacementInput,
  RenderStateInput,
  UndoConfigurationInput,
  Vec3Input,
  WebhookFlowType
} from "@src/app/generated/globalTypes";
import {
  GetCurrentRetailerOptions,
  GetCurrentRetailerOptions_currentRetailerOptions,
  GetCurrentRetailerOptions_currentRetailerOptions_agreementLinks,
  GetCurrentRetailerOptions_currentRetailerOptions_colorCodes,
  GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions,
  GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_configuratorIcons,
  GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_font,
  GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_priceAppearance,
  GetCurrentRetailerOptions_currentRetailerOptions_currencyOptions,
  GetCurrentRetailerOptions_currentRetailerOptions_helpItems,
  GetCurrentRetailerOptions_currentRetailerOptions_hornbachOptions,
  GetCurrentRetailerOptions_currentRetailerOptions_installationPrices,
  GetCurrentRetailerOptions_currentRetailerOptions_installationPrices_values_value,
  GetCurrentRetailerOptions_currentRetailerOptions_webhookOptions,
  GetCurrentRetailerOptions_currentRetailerOptions_woodTypesAvailable
} from "@src/app/generated/GetCurrentRetailerOptions";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";
import {GetCatalogDraft} from "@src/app/generated/GetCatalogDraft";
import {GetAllModuleBlueprintsDraft} from "@src/app/generated/GetAllModuleBlueprintsDraft";
import {VisualYAttachmentPoint} from "@ess/jg-rule-executor/dist/src/domain/visual-y-attachment-point";
import {Translation} from "@ess/jg-rule-executor/dist/src/domain/translation";
import {
  GetMaterialsAndTextures,
  GetMaterialsAndTextures_materials,
  GetMaterialsAndTextures_textures,
  GetMaterialsAndTextures_textures_asset
} from "@src/app/generated/GetMaterialsAndTextures";
import {GetMarkerPlacement} from "@src/app/generated/GetMarkerPlacement";
import {MarkerPlacement} from "@src/app/model/marker-placement";
import {HelpItem} from "@ess/jg-rule-executor/dist/src/domain/HelpItem";
import {BbColor} from "@ess/jg-rule-executor/dist/src/domain/BbColor";
import {GetUnavailableModuleBlueprints} from "@src/app/generated/GetUnavailableModuleBlueprints";
import {
  GetPreConfigurationsByScope,
  GetPreConfigurationsByScope_preConfigurations
} from "@src/app/generated/GetPreConfigurationsByScope";
import {ConfiguratorPreConfiguration} from "@src/app/model/configurator-preconfiguration";
import {ConfiguratorIcons} from "@ess/jg-rule-executor/dist/src/domain/configurator-icons";
import {ConfiguratorOptions} from "@ess/jg-rule-executor/dist/src/domain/configurator-options";
import {CurrencyOptions} from "@ess/jg-rule-executor/dist/src/domain/currency-options";
import {PriceAppearance} from "@ess/jg-rule-executor/dist/src/domain/price-appearance";
import {HornbachOptions} from "@ess/jg-rule-executor/dist/src/domain/hornbach-options";
import {GetAllPublishedModuleBlueprints} from "@src/app/generated/GetAllPublishedModuleBlueprints";
import {
  GetAllAvailableModuleBlueprints,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_boundingBoxes,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_variantsPerWoodType_values_value_visualChanges,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_visualXAttachmentPoints,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_visualYAttachmentPoint,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_xAttachmentPoints,
  GetAllAvailableModuleBlueprints_allAvailableBlueprints_yAttachmentPoints
} from "@src/app/generated/GetAllAvailableModuleBlueprints";

export type ModuleBlueprintWithWoodVariants = {
  blueprint: ModuleBlueprint,
  variants: Map<WoodType, ModuleVariant[]>
};

export class GqlConverterUtil {

  static convertToConfigurationPlacementInput(input: ConfigurationPlacement): ConfigurationPlacementInput {
    if (input) {
      return {
        originPlacement: input.originPlacement,
        placements: input.placements.map(GqlConverterUtil.toModulePlacementInput),
        woodType: input.woodType
      };
    } else {
      return null;
    }
  }

  static convertToPlacementInfoInput(placementsInfo : Map<Id<ModulePlacement>, SpacialPosition[]>) : IdModulePlacementListSpacialPositionInputInputMap {
    if(placementsInfo) {
      const keys = Array.from(placementsInfo.keys());
      return {
        values: keys.map(key => {
          return  {
            key: key,
            value: placementsInfo.get(key)
          }
        })
      }
    } else {
      return null;
    }
  }
  static convertToUndoConfigurationInput(input: UndoConfiguration[]): UndoConfigurationInput[] {
    if (input) {
      return input.map(undoConfig => ({
        configurationPlacement: GqlConverterUtil.convertToConfigurationPlacementInput(undoConfig.configurationPlacement),
        date: undoConfig.date
      }));
    } else {
      return [];
    }
  }

  static convertToRenderStateInput(input: RenderState): RenderStateInput {
    if (input) {
      return {
        cameraPosition: GqlConverterUtil.convertToVec3Input(input.cameraPosition),
        // This euler can be safely converted to a vec3 since the order-parameter is not used (yet?)
        cameraRotation: GqlConverterUtil.convertToVec3Input(
          new Vector3(input.cameraRotation.x, input.cameraRotation.y, input.cameraRotation.z)
        ),
        controlTarget: GqlConverterUtil.convertToVec3Input(input.controlTarget),
      };
    } else {
      return null;
    }
  }

  static convertAllAvailableBlueprintsResponse(input: GetAllAvailableModuleBlueprints): ModuleBlueprintWithWoodVariants[] {
    if (input?.allAvailableBlueprints) {
      return input.allAvailableBlueprints.map(GqlConverterUtil.toModuleBlueprint);
    } else {
      return undefined;
    }
  }

  static convertAllPublishedBlueprintsResponse(input: GetAllPublishedModuleBlueprints): ModuleBlueprintWithWoodVariants[] {
    if (input?.allPublishedBlueprints) {
      return input.allPublishedBlueprints.map(GqlConverterUtil.toModuleBlueprint);
    } else {
      return undefined;
    }
  }

  static convertUnavailableVariantIds(input: GetUnavailableModuleBlueprints): Map<WoodType, Id<ModuleVariant>[]> {
    if (input?.allUnavailableBlueprints) {
      const flatInput: Map<WoodType, Id<ModuleVariant>[]>[] = input.allUnavailableBlueprints.map(
        u => new Map(u.variantsPerWoodType.values.map(
          v => [v.key, v.value.map(v => id<ModuleVariant>(v.id as string))])
        )
      );

      const woodTypeMap = new Map<WoodType, Id<ModuleVariant>[]>()
      woodTypes.forEach(woodType => {
        const currentList = flatInput
          .flatMap(x => x.get(woodType))
          .filter(x => x !== undefined)
        woodTypeMap.set(woodType, currentList);
      });
      return woodTypeMap;
    } else {
      return undefined;
    }
  }

  static convertAllBlueprintsResponseDraft(input: GetAllModuleBlueprintsDraft): ModuleBlueprintWithWoodVariants[] {
    if (input?.allBlueprintsDraft) {
      return input.allBlueprintsDraft.map(GqlConverterUtil.toModuleBlueprint);
    } else {
      return undefined;
    }
  }

  static convertGetBlueprintByIdResponse(input: GetBlueprintById): {
    blueprint: ModuleBlueprint,
    variants: Map<WoodType, ModuleVariant[]>
  } {
    if (input?.blueprint) {
      return GqlConverterUtil.toModuleBlueprint(input.blueprint);
    } else {
      return undefined;
    }
  }

  static convertGetVariantByIdResponse(input: GetVariantById): Map<WoodType, ModuleVariant> {
    if (input?.variant) {
      const entries = input.variant.map((v) =>
        [v.woodType, GqlConverterUtil.toModuleVariant(v.variant)] as [WoodType, ModuleVariant]
      );
      return new Map<WoodType, ModuleVariant>(entries);
    } else {
      return undefined;
    }
  }

  static convertGetCatalogResponse(input: GetCatalog): Map<WoodType, ConfiguratorCatalog> {
    if (input) {
      return GqlConverterUtil.toCatalog(input.catalog);
    } else {
      return undefined;
    }
  }

  static convertGetCatalogResponseDraft(input: GetCatalogDraft): Map<WoodType, ConfiguratorCatalog> {
    if (input) {
      return GqlConverterUtil.toCatalog(input.catalogDraft);
    } else {
      return undefined;
    }
  }

  static convertGetConfigurationByCodeResponse(input: GetConfigurationByCode): Configuration {
    if (input?.configuration) {
      return GqlConverterUtil.toConfiguration(input.configuration);
    } else {
      return undefined;
    }
  }

  static convertGetPreConfigurationsByScopeResponse(input: GetPreConfigurationsByScope): ConfiguratorPreConfiguration[] {
    if (input?.preConfigurations) {
      return input.preConfigurations.map(pc => GqlConverterUtil.toPreConfiguration(pc));
    } else {
      return undefined;
    }
  }

  static convertGetCurrentRetailerOptionsResponse(input: GetCurrentRetailerOptions): RetailerOptions {
    if (input?.currentRetailerOptions) {
      return GqlConverterUtil.toRetailerOptions(input.currentRetailerOptions);
    } else {
      return undefined;
    }
  }

  static convertGetTranslationsResponse(input: GetTranslations): object {
    if (input?.translations) {
      const translateJson = {};
      input.translations.forEach(x => {
        // Deconstruct the translation lines into a nested object to load into the translation service
        set(translateJson, x.key, x.value);
      });
      return translateJson;
    } else {
      return undefined;
    }
  }

  static convertGetMaterialsAndTextures(input: GetMaterialsAndTextures): { materials: Material[], textures: Texture[] } {
    return {
      materials: input.materials.map(m => GqlConverterUtil.convertMaterial(m)),
      textures: input.textures.map(m => GqlConverterUtil.convertTexture(m))
    };
  }

  static convertMaterial(input: GetMaterialsAndTextures_materials): Material {
    return new Material(
      input?.id,
      input?.name,
      input?.color,
      input?.roughness,
      input?.metalness,
      input?.map,
      input?.lightMap,
      input?.lightMapIntensity,
      input?.aoMap,
      input?.aoMapIntensity,
      input?.emissive,
      input?.emissiveIntensity,
      input?.emissiveMap,
      input?.bumpMap,
      input?.bumpScale,
      input?.normalMap,
      input?.normalMapType,
      input?.normalScale ? GqlConverterUtil.toVector2(input?.normalScale) : undefined,
      input?.displacementMap,
      input?.displacementScale,
      input?.displacementBias,
      input?.roughnessMap,
      input?.metalnessMap,
      input?.alphaMap,
      input?.envMap,
      input?.envMapIntensity,
      input?.wireframe,
      input?.wireframeLinewidth,
      input?.fog,
      input?.flatShading,
      input?.alphaTest,
      input?.alphaToCoverage,
      input?.blendDst,
      input?.blendDstAlpha,
      input?.blendEquation,
      input?.blendEquationAlpha,
      input?.blending,
      input?.blendSrc,
      input?.blendSrcAlpha,
      input?.clipIntersection,
      input?.clippingPlanes ? input?.clippingPlanes.map(p => GqlConverterUtil.toPlane(p)) : [],
      input?.clipShadows,
      input?.colorWrite,
      input?.depthFunc,
      input?.depthTest,
      input?.depthWrite,
      input?.opacity,
      input?.polygonOffset,
      input?.polygonOffsetFactor,
      input?.polygonOffsetUnits,
      input?.precision,
      input?.premultipliedAlpha,
      input?.dithering,
      input?.side,
      input?.shadowSide,
      input?.toneMapped,
      input?.transparent,
      input?.vertexColors,
      input?.visible,
      input?.format,
      input?.stencilWrite,
      input?.stencilFunc,
      input?.stencilRef,
      input?.stencilWriteMask,
      input?.stencilFuncMask,
      input?.stencilFail,
      input?.stencilZFail,
      input?.stencilZPass
    );
  }

  static convertTexture(input: GetMaterialsAndTextures_textures): Texture {
    return new Texture(
      input.id,
      input.name,
      input.asset ? GqlConverterUtil.convertTextureAsset(input.asset) : undefined
    );
  }

  static convertTextureAsset(input: GetMaterialsAndTextures_textures_asset): Asset {
    return new Asset(
      input.id,
      input.filename,
      input.mimeType,
      input.directory,
      input.url
    );
  }

  static convertUploadConfigurationResponse(input: UploadConfiguration): string {
    if (input?.configuration?.newConfigurationApp) {
      return input.configuration.newConfigurationApp.code;
    } else {
      return undefined;
    }
  }

  static convertUpdateConfigurationResponse(input: UpdateConfiguration): boolean {
    return !!input?.configuration?.updateConfigurationApp;
  }

  static convertPlaceOrderResponse(input: PlaceOrder): { order: ConfigurationOrder, payload?: string } {
    if (input?.order) {
      return GqlConverterUtil.toConfigurationOrderWithPayload(input.order);
    } else {
      return undefined;
    }
  }

  static convertGetMarkerResponse(input: GetMarkerPlacement): MarkerPlacement | undefined {
    if (input.configuration?.markerPlacement) {
      return new MarkerPlacement(
        GqlConverterUtil.keyValueListToMap(input.configuration.markerPlacement.mainMarkers.values),
        GqlConverterUtil.keyValueListToMap(input.configuration.markerPlacement.visualXAttachmentPointMarkers.values)
      );
    }
  }

  private static toConfiguration(input: GetConfigurationByCode_configuration): Configuration {
    if (input) {
      return new Configuration(
        id(input.id),
        input.name,
        undefined,
        input.code,
        GqlConverterUtil.toConfigurationPlacement(input.configurationPlacement),
        input.isPreConfiguration,
        GqlConverterUtil.toRenderState(input.renderState),
        (input as GetConfigurationByCode_configuration)?.history ?
          (input as GetConfigurationByCode_configuration).history.map(x => this.toUndoConfiguration(x)) : [],
        undefined,
        input.installationService
      );
    } else {
      return undefined;
    }
  }

  private static toPreConfiguration(input: GetPreConfigurationsByScope_preConfigurations): ConfiguratorPreConfiguration {
    if (input) {
      return new ConfiguratorPreConfiguration(
        id(input.id),
        input.code,
        input.name,
        id(input.scope),
        input.price,
        input.image
      );
    } else {
      return undefined;
    }
  }

  private static retailerPricesFromGql(input: GetCurrentRetailerOptions_currentRetailerOptions_installationPrices): Map<WoodType, Map<number, number>> {
    const outputMap = new Map<WoodType, Map<number, number>>();

    function makeSubMap(mapInput: GetCurrentRetailerOptions_currentRetailerOptions_installationPrices_values_value): Map<number, number> {
      const subMap = new Map<number, number>();
      mapInput.values.forEach(t => subMap.set(t.key, t.value));
      return subMap;
    }

    input.values.forEach(t => {
      outputMap.set(t.key, makeSubMap(t.value));
    });
    return outputMap;
  }

  private static toWebhookOptions(input: GetCurrentRetailerOptions_currentRetailerOptions_webhookOptions): WebhookOptions {
    if (input) {
      return new WebhookOptions(
        WebhookFlowType[input.flowType],
        new WebhookUrls(input.webhookUrls.redirectUrl),
        input.webhookGenerators ? new WebhookGenerators(input.webhookGenerators.magentoTwoPhaseGenerator, input.webhookGenerators.codeGetGenerator) :
          new WebhookGenerators()
      );
    } else {
      return undefined;
    }
  }

  private static toRetailerOptions(input: GetCurrentRetailerOptions_currentRetailerOptions): RetailerOptions {
    if (input) {
      return new RetailerOptions(
        input.purchasePriceMandatory,
        GqlConverterUtil.toCurrencyOptions(input.currencyOptions),
        GqlConverterUtil.toColorCodes(input.colorCodes),
        GqlConverterUtil.toAgreementLinks(input.agreementLinks),
        input.helpLink ? new Link(input.helpLink.href, input.helpLink.text) : undefined,
        GqlConverterUtil.toWoodTypeAvailable(input.woodTypesAvailable),
        input.configurationIdentifier,
        input.resultSceneSkyMaterialReference,
        input.resultSceneFloorMaterialReference,
        input.installationPrices ? GqlConverterUtil.retailerPricesFromGql(input.installationPrices) : undefined,
        input.preconfigurations,
        input.helpItems ? input.helpItems.map(h => GqlConverterUtil.helpItemFromGql(h)) : [],
        GqlConverterUtil.toWebhookOptions(input.webhookOptions),
        input.codeParameterName,
        input.configuratorOptions ? GqlConverterUtil.toConfigurationOptions(input.configuratorOptions) : undefined,
        undefined,
        input.hornbachOptions ? GqlConverterUtil.toHornbachOptions(input.hornbachOptions): undefined
      );
    } else {
      return undefined;
    }
  }

  private static toCurrencyOptions(input: GetCurrentRetailerOptions_currentRetailerOptions_currencyOptions): CurrencyOptions {
    if (input) {
      return new CurrencyOptions(
        input.currency,
        input.showCurrency,
        input.locale,
        input.showThousandsSeparator,
      )
    }
  }

  private static toConfigurationOptions(input: GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions): ConfiguratorOptions {
    if (input) {
      return new ConfiguratorOptions(
        input.favicon,
        input.banner,
        GqlConverterUtil.toFont(input.font),
        GqlConverterUtil.toConfiguratorIcons(input.configuratorIcons),
        GqlConverterUtil.toPriceAppearance(input.priceAppearance),
        input.bottomLogo,
        input.saveButton,
        input.hideSteps,
        input.hideMenuSaveButton,
        input.hideMenuOpenButton,
        input.hideShareContent,
        input.extraCardWidth,
      )
    }
  }

  private static toHornbachOptions(input: GetCurrentRetailerOptions_currentRetailerOptions_hornbachOptions): HornbachOptions {
    if (input) {
      return new HornbachOptions(
        input.integrationServiceUrl,
        input.articleId,
        input.companyCode,
      )
    }
  }

  private static helpItemFromGql(input: GetCurrentRetailerOptions_currentRetailerOptions_helpItems): HelpItem {
    if (input) {
      return new HelpItem(
        input.order,
        input.title,
        input.videoAsset,
        input.thumbnailAsset
      );
    }
  }

  private static toColorCodes(input: GetCurrentRetailerOptions_currentRetailerOptions_colorCodes): ColorCodes {
    if (input) {
      return new ColorCodes(
        input.button,
        input.buttonPrimaryHover,
        input.buttonSecondaryHover,
        input.gradient1,
        input.gradient2,
        input.cartButton,
        input.cartButtonHover,
        input.background,
        input.selectedItemBackground,
        input.bodyText,
        input.headerText,
        input.selectedItem,
        input.icons,
        input.activeStep,
        input.inactiveStep,
        input.dropShadow,
        input.warn,
        input.border
      );
    } else {
      return undefined;
    }
  }

  private static toFont(input: GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_font): Font {
    if (input) {
      return new Font(
        input.regularFontAsset,
        input.boldFontAsset,
        input.italicFontAsset,
        input.mediumFontAsset
      );
    } else {
      return undefined;
    }
  }

  private static toConfiguratorIcons(input: GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_configuratorIcons): ConfiguratorIcons {
    if (input) {
      return new ConfiguratorIcons(
        input.measurement,
        input.woodType,
        input.reset
      );
    } else {
      return undefined;
    }
  }

  private static toPriceAppearance(input: GetCurrentRetailerOptions_currentRetailerOptions_configuratorOptions_priceAppearance): PriceAppearance {
    if (input) {
      return new PriceAppearance(
        input.font,
        input.color
      );
    } else {
      return undefined;
    }
  }

  private static toAgreementLinks(input: GetCurrentRetailerOptions_currentRetailerOptions_agreementLinks[]): Link[] {
    return input.map(mbLink => {
      if (mbLink) {
        return new Link(mbLink.href, mbLink.text);
      } else {
        return undefined;
      }
    }).filter(t => t !== undefined);
  }

  private static toWoodTypeAvailable(input: GetCurrentRetailerOptions_currentRetailerOptions_woodTypesAvailable[]): WoodTypeAvailable[] {
    return input.map(wta => {
      if (wta) {
        return new WoodTypeAvailable(
          WoodType[wta.woodType],
          wta.available
        );
      } else {
        return undefined;
      }
    }).filter(w => w !== undefined);
  }

  /**
   * Converts object to RenderState-object. Note: we make the assumption that the order of the euler object
   * is irrelevant.
   *
   * @private
   */
  private static toRenderState(input: GetConfigurationByCode_configuration_renderState): RenderState {
    return new RenderState(
      input?.cameraPosition ? GqlConverterUtil.toVector3(input.cameraPosition) : undefined,
      new Euler(input?.cameraRotation.x, input?.cameraRotation.y, input?.cameraRotation.z),
      input?.controlTarget ? GqlConverterUtil.toVector3(input.controlTarget) : undefined
    );
  }

  private static toConfigurationPlacement(
    input: GetConfigurationByCode_configuration_configurationPlacement | GetConfigurationByCode_configuration_history_configurationPlacement
  ): ConfigurationPlacement {
    if (input) {
      const res: ConfigurationPlacement = new ConfigurationPlacement();
      res.placements = input.placements.map(GqlConverterUtil.toModulePlacement);
      res.originPlacement = id(input.originPlacement);
      res.woodType = WoodType[input.woodType];
      return res;
    } else {
      return undefined;
    }
  }

  private static toUndoConfiguration(input: GetConfigurationByCode_configuration_history): UndoConfiguration {
    if (input) {
      return new UndoConfiguration(
        input.date,
        this.toConfigurationPlacement(input.configurationPlacement)
      );
    } else {
      return undefined;
    }
  }

  private static toConfigurationOrderWithPayload(input: PlaceOrder_order): { order: ConfigurationOrder, payload?: string } {
    if (input) {
      const order: ConfigurationOrder = new ConfigurationOrder();
      order.price = input.newConfigurationOrder.configurationOrder.price;
      order.installationCost = input.newConfigurationOrder.configurationOrder.installationCost;
      order.code = input.newConfigurationOrder.configurationOrder.code;
      return {order, ...(input.newConfigurationOrder.webhookPayload ? {payload: input.newConfigurationOrder.webhookPayload} : {})};
    } else {
      return undefined;
    }
  }

  private static toModulePlacement(input: GetConfigurationByCode_configuration_configurationPlacement_placements): ModulePlacement {
    if (input) {
      const res: ModulePlacement = new ModulePlacement();
      res.id = id(input.id);
      res.variantId = id(input.variantId);
      res.transformation = GqlConverterUtil.toMatrix4(input.transformation);
      res.xAttachments = GqlConverterUtil.toXAttachmentsMap(input.xAttachments);
      return res;
    } else {
      return undefined;
    }
  }

  private static convertToVec3Input(input: Vector3): Vec3Input {
    if (input?.x !== undefined && input?.y !== undefined && input?.z !== undefined) {
      return {x: input.x, y: input.y, z: input.z};
    }
    return undefined;
  }

  private static toModulePlacementInput(input: ModulePlacement): ModulePlacementInput {
    if (input) {
      return {
        id: input.id,
        variantId: input.variantId,
        xAttachments: GqlConverterUtil.toXAttachmentsInput(input.xAttachments)
      };
    } else {
      return null;
    }
  }

  private static toXAttachmentsMap(
    input: GetConfigurationByCode_configuration_configurationPlacement_placements_xAttachments
  ): Map<Id<XAttachmentPoint>, Attachment> {
    // arrow function becomes ridiculously long
    // eslint-disable-next-line arrow-body-style
    return input.values.reduce((acc, cur) => {
      return acc.set(id(cur.key), this.toAttachment(cur.value));
    }, new Map<Id<XAttachmentPoint>, Attachment>());
  }

  private static toXAttachmentsInput(input: Map<Id<XAttachmentPoint>, Attachment>): IdXAttachmentPointAttachmentInputMap {
    if (input) {
      return {
        values: Array.from(input.keys()).map(key => ({
          key,
          value: {
            placementId: input.get(key).placementId,
            yAttachmentId: input.get(key).yAttachmentId
          }
        }))
      };
    } else {
      return null;
    }
  }

  private static toAttachment(
    input: GetConfigurationByCode_configuration_configurationPlacement_placements_xAttachments_values_value
  ): Attachment {
    if (input) {
      return new Attachment(
        input.placementId,
        input.yAttachmentId
      );
    } else {
      return undefined;
    }
  }

  private static toMatrix4(input: {
    r1: { c1, c2, c3, c4 },
    r2: { c1, c2, c3, c4 },
    r3: { c1, c2, c3, c4 },
    r4: { c1, c2, c3, c4 }
  }): Matrix4 {
    if (input) {
      return new Matrix4().set(
        input.r1.c1, input.r1.c2, input.r1.c3, input.r1.c4,
        input.r2.c1, input.r2.c2, input.r2.c3, input.r2.c4,
        input.r3.c1, input.r3.c2, input.r3.c3, input.r3.c4,
        input.r4.c1, input.r4.c2, input.r4.c3, input.r4.c4,
      );
    } else {
      return new Matrix4(); //identity matrix
    }
  }

  // private static resolveXAttachmentsMap(input: )

  private static toModuleBlueprint(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints): ModuleBlueprintWithWoodVariants {
    if (input) {
      const res: ModuleBlueprint = new ModuleBlueprint();
      res.id = id(input.id);
      res.name = input.name;
      res.status = input.status;
      res.xAttachmentPoints = input.xAttachmentPoints ? input.xAttachmentPoints.map(GqlConverterUtil.toXAttachmentPoint) : [];
      res.yAttachmentPoints = input.yAttachmentPoints ? input.yAttachmentPoints.map(GqlConverterUtil.toYAttachmentPoint) : [];
      res.model = input.model;
      res.visualXAttachmentPoints = input.visualXAttachmentPoints ? input.visualXAttachmentPoints.map(GqlConverterUtil.toVisualXAttachmentPoint) : [];
      res.visualYAttachmentPoint = input.visualYAttachmentPoint ? GqlConverterUtil.toVisualYAttachmentPoint(input.visualYAttachmentPoint) : undefined;
      res.boundingBoxes = input.boundingBoxes.map((bbox) => GqlConverterUtil.toBoundingBox(bbox));
      res.categoryId = input.categoryId;
      res.categoryReference = input.categoryReference;
      res.moduleType = input.moduleType === 'Main' ? ModuleType.Main : ModuleType.Aux;
      res.groupId = input.groupId;
      res.translationKeys = input.translationKeys.reduce((prev, tk) => prev.set(tk.key, tk.value), new Map<string, string>());
      res.considerAsFree = input.considerAsFree;
      res.featureImage = input.featureImage;

      const variants = input.variantsPerWoodType.values.map((wv) =>
        [wv.key, wv.value.map((v) => GqlConverterUtil.toModuleVariant(v))] as [WoodType, ModuleVariant[]]
      );

      return {
        blueprint: res,
        variants: new Map<WoodType, ModuleVariant[]>(variants)
      };
    } else {
      return undefined;
    }
  }

  private static toBoundingBox(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints_boundingBoxes): BoundingBox {
    return new BoundingBox(
      GqlConverterUtil.toVector3(input.position),
      new BoundingBoxSize(
        input.size.width,
        input.size.height,
        input.size.length,
      ),
      BbColor.fromRaw({color: input.color.color, id: input.color.id})
    );
  }

  private static toModuleVariant(input: GetVariantById_variant_variant): ModuleVariant {
    if (input) {

      const res: ModuleVariant = new ModuleVariant();

      res.id = id(input.id);
      res.blueprintId = id(input.blueprintId);
      res.price = input.price;
      res.catalogIcon = input.catalogIcon;
      res.visualChanges = input.visualChanges.map(GqlConverterUtil.toVisualChanges);
      res.showInCatalog = input.showInCatalog;
      res.attributeValues = GqlConverterUtil.keyValueListToMap(input.attributeValues.values);
      res.translationKeys = input.translations.values.reduce((prev, tk) => prev.set(tk.key, tk.value), new Map<string, string>());
      res.groupId = input.groupId

      return res;
    } else {
      return undefined;
    }
  }

  private static toXAttachmentPoint(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints_xAttachmentPoints): XAttachmentPoint {
    return new XAttachmentPoint(
      id(input.id),
      GqlConverterUtil.toVector3(input.position),
      GqlConverterUtil.toVector3(input.orientation),
      new Set(input.properties.map(propertyArray => new Set(propertyArray))
      ));
  }

  private static toYAttachmentPoint(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints_yAttachmentPoints): YAttachmentPoint {
    return new YAttachmentPoint(
      id(input.id),
      GqlConverterUtil.toVector3(input.position),
      GqlConverterUtil.toVector3(input.orientation),
      new Set(input.properties)
    );
  }

  private static toVisualXAttachmentPoint(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints_visualXAttachmentPoints): VisualXAttachmentPoint {
    return new VisualXAttachmentPoint(
      GqlConverterUtil.toVector3(input.position),
      input.orientation ? GqlConverterUtil.toVector3(input.orientation) : undefined,
      input.xAttachmentPointIds ? input.xAttachmentPointIds.map(i => id(i)) : []
    );
  }

  private static toVisualYAttachmentPoint(input: GetAllAvailableModuleBlueprints_allAvailableBlueprints_visualYAttachmentPoint): VisualYAttachmentPoint {
    return new VisualYAttachmentPoint(
      GqlConverterUtil.toVector3(input.position),
      input.orientation ? GqlConverterUtil.toVector3(input.orientation) : undefined,
      id(input.yAttachmentPointId)
    );
  }

  private static toVector3(input: { __typename: string, x: number, y: number, z: number }): Vector3 {
    return new Vector3(input.x, input.y, input.z);
  }

  private static toVector2(input: { __typename: string, x: number, y: number }): Vector2 {
    return new Vector2(input.x, input.y);
  }

  private static toPlane(input: { __typename: string, normal: { __typename: string, x: number, y: number, z: number }, constant: number }): Plane {
    return new Plane(GqlConverterUtil.toVector3(input.normal), input.constant);
  }

  private static toCatalog(input: GetCatalog_catalog): Map<WoodType, ConfiguratorCatalog> {
    return ConfiguratorCatalog.fromGql(input);
  }

  private static keyValueListToMap<K, V>(list: { key: K, value: V }[]): Map<K, V> {
    return list.reduce((map, kv) => {
      map.set(kv.key, kv.value);
      return map;
    }, new Map<K, V>());
  }

  private static toVisualChanges(
    input: GetVariantById_variant_variant_visualChanges |
      GetAllAvailableModuleBlueprints_allAvailableBlueprints_variantsPerWoodType_values_value_visualChanges
  ): VisualChange {
    switch (input.__typename) {
      case "Translation":
        return new Translation(
          input.xaps.map(x => id<XAttachmentPoint>(x)),
          input.yaps.map(y => id<YAttachmentPoint>(y)),
          GqlConverterUtil.toVector3(input.translate),
          input.translateModule
        );
      case "Invisible":
        return new Invisible(
          input.name
        );
      case "MaterialChange":
        return new MaterialChange(
          GqlConverterUtil.keyValueListToMap(input.changes.values)
        );
    }
  }
}
