import {Asset} from "@ess/jg-rule-executor";

export class VariantWithCount {
  title: string;
  miniature: Asset;
  count: number;

  constructor(title: string, miniature: Asset, count: number) {
    this.title = title;
    this.miniature = miniature;
    this.count = count;
  }
}
