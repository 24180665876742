// This config file contains the environment setting for
// Acceptance
import {environment as sharedEnv} from './environment.shared';
import {sentryUrl} from "./settings";

export const environment = {
  ...sharedEnv,
  production: true,
  envName: 'Acceptance',
  logging: {
    serverLoggingEnabled: true,
    serverUrl: sentryUrl,
    minLogLevel: 'WARN'
  },
  baseUrl: 'https://configurator-accep.mycustomplayground.com/'
};
