import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ModalService} from "@src/app/services/modal/modal.service";
import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {IframeService} from "@src/app/services/iframe/iframe.service";

/**
 * How to add a sub-modal:
 * 1. create new component
 * 2. add '@ViewChild("modalComponent", {static: false}) private _modalComponent: ModalComponent;' to the .ts file
 * 3. add '<app-modal id="sub-modal" #modalComponent>' to the .html file. Make sure the id is unique
 * 4. add the sub-modal to app.component.html
 */
@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() blocking: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() normalScreen: boolean = false;
  public readonly outputEmitter: EventEmitter<any> = new EventEmitter<any>();
  public closeSrc: string = iconSource(Icon.close);

  private _argsSubj: Subject<object> = new Subject<object>();
  private _dragFromModal = false;

  @Output() closing: EventEmitter<void> = new EventEmitter<void>();
  public isInFrame = this._iframeService.inIframe();

  constructor(
    private _modalService: ModalService,
    private _loggingService: LoggingService,
    private _iframeService: IframeService,
    private readonly _eltRef: ElementRef
  ) {
  }

  // Set up the component
  public ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      this._loggingService.warn('Modal created without an id!');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this._eltRef.nativeElement);

    // close modal on background click; do not close on drag
    this._eltRef.nativeElement.addEventListener('mousedown', el => {
      if (el.target.className !== 'modal') {
        this._dragFromModal = true;
      }
    });
    this._eltRef.nativeElement.addEventListener('mouseup', el => {
      if (el.target.className === 'modal') {
        if (this._dragFromModal !== true && !this.blocking) {
          this.close();
        }
        this._dragFromModal = false;
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this._modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  public ngOnDestroy(): void {
    this._modalService.remove(this.id);
    this._eltRef.nativeElement.remove();
  }

  /**
   * Retrieve the value for the given argument, if it exists, as an observable
   * Note that arg is only set on 'open', and so will only ever emit a single value.
   */
  public getModalArg(argName: string): Observable<any> {
    return this._argsSubj.asObservable().pipe(map(args => {
      if (args?.hasOwnProperty(argName)) {
        return args[argName as keyof typeof args];
      } else {
        return undefined;
      }
    }));
  }

  public getOutput(): Observable<any> {
    return this.outputEmitter.asObservable();
  }

  /**
   * Open modal and set optional arguments
   *
   * @param args an optional, arbitrary object containing the (named) arguments for the modal.
   *        args can later be accessed using getModalArg(name).
   */
  public open(args?: object): void {
    this._eltRef.nativeElement.style.display = 'block';
    this._argsSubj.next(args);
  }

  // close modal
  public close(): void {
    // close emitter for clicking outside modal
    this._modalService.closeEmitter.emit(this.id);
    this._eltRef.nativeElement.style.display = 'none';
    this.closing.emit();
  }
}
