/* eslint-disable max-classes-per-file */
import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {ViewMode} from "@src/app/model/view-mode";
import {Icon, iconSource} from "@src/app/library/components/icon";
import {RenderService} from '@src/app/services/render/render.service';
import {ModalService} from "@src/app/services/modal/modal.service";
import {environment} from "@src/environments/environment";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {ApplicationState} from "@src/app/model/applicationstate";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {Subject} from "rxjs";
import {DebugService} from "@src/app/services/debug/debug.service";
import {IframeService} from "@src/app/services/iframe/iframe.service";

/**
 * Type listing all possible menu items.
 * If items get removed, make sure to also remove them from the list!
 */
type MenuItemName =
  "app.menu.open" |
  "app.menu.save" |
  "app.menu.ar" |
  "app.menu.restart" |
  "app.modal.help.header" |
  "app.menu.installation_service";


/**
 * Represents a single item in the menu (top right corner) configurator.
 *
 * @arg name (translatable) display name of the item
 * @arg onClick function to trigger on Click
 * @arg addBarAfter whether to add a horizontal dividing line after the item
 * @arg icon path to the icon to be shown to the left of the item (use iconSource fn!)
 * @arg hide if the item should be hidden or not
 */
class MenuItem {
  name: MenuItemName;
  icon: string;
  onClick: () => void;
  addBarAfter: boolean;
  toggle?: boolean;
  hide?: boolean;
  disabled?: boolean;

  constructor(
    name: MenuItemName,
    icon: Icon,
    onClick: () => void,
    disabled: boolean = false,
    hide: boolean = false,
    addBarAfter: boolean = true,
    toggle: boolean = false
  ) {
    this.name = name;
    this.icon = iconSource(icon);
    this.onClick = () => {
      if (!this.disabled) {
        onClick();
      }
    };
    this.disabled = disabled;
    this.toggle = toggle;
    this.hide = hide;
    this.addBarAfter = addBarAfter;
  }

  public setHide(hide: boolean) {
    this.hide = hide;
  }

  public setDisabled(disabled: boolean) {
    this.disabled = disabled;
  }
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  public closeIconSource: string = iconSource(Icon.close);
  public visible: boolean = false;
  public showMeasurements: boolean = false;

  public version: string = environment.versions.version;
  public debugIcon = iconSource(Icon.hammer);
  public openIcon = iconSource(Icon.openfolder);


  private _componentDestroyed$: Subject<void> = new Subject();

  private _viewMode: ViewMode = ViewMode.plp;

  /**
   * Menu items to appear in the menu.
   */
  public menuItems: MenuItem[] = [
    new MenuItem(
      "app.menu.open",
      Icon.openfolder,
      () => {
        this._interactionService.showOpenModal();
        this.closeMenu();
      },
      false
    ),
    new MenuItem(
      "app.menu.save",
      Icon.save,
      () => {
        this._interactionService.saveCurrentConfiguration();
        this.closeMenu();
      },
      this._viewMode === ViewMode.plp,
    ),
    new MenuItem(
      "app.menu.ar",
      Icon.ar,
      () => {
        if (this._dataService.isIos || this._dataService.isAndroid) {
          this._modalService.open('ar-instruction-modal');
        } else {
          this._interactionService.saveCurrentConfiguration('qr-code-modal');
        }
        this.closeMenu();
      },
      this._viewMode === ViewMode.plp,
      true // Set to false when ar is available
    ),
    new MenuItem(
      "app.menu.restart",
      Icon.restart,
      () => {
        this._interactionService.restoreStartConfiguration();
        this.closeMenu();
      },
      this._viewMode === ViewMode.plp
    ),
    new MenuItem(
      "app.modal.help.header",
      Icon.help,
      () => {
        this._modalService.open('help-modal');
        this.closeMenu();
      }
    ),
    new MenuItem(
      "app.menu.installation_service",
      Icon.tool,
      () => {
        this._modalService.open('installation-service-modal');
        this.closeMenu();
      },
      this._viewMode === ViewMode.plp,
      true // Set to false when installation service is available
    )
  ];

  constructor(
    private _interactionService: InteractionService,
    private _renderService: RenderService,
    private _dataService: InitDataService,
    private _modalService: ModalService,
    private _priceCalculatorService: PriceCalculatorService,
    private _iframeService: IframeService,
    private _elRef: ElementRef,
    public debugService: DebugService,
    stateService: ApplicationStateService
  ) {
    stateService.getState$()
      .pipe(
        takeUntil(this._componentDestroyed$),
        filter(t => t === ApplicationState.currentScopeAndRetailerAreLoaded),
        take(1)
      )
      .subscribe({
        next: () => {
          if (this._dataService.arAllowed.ios || this._dataService.arAllowed.android) {
            if (
              !this._iframeService.inIframe() && // not in iframe AND one of the following:
              ((this._dataService.isIos && this._dataService.arAllowed.ios) || // ios with allowed
                (this._dataService.isAndroid && this._dataService.arAllowed.android) || // android with allowed
                (!this._dataService.isAndroid && !this._dataService.isIos)) // non-mobile
            ) {
              this.menuItems.find(i => i.name === "app.menu.ar")?.setHide(false);
            }
          }

          // Due to bindings and recalculations, this is the only way it seems to properly show these items without continuous calculations
          if (this._priceCalculatorService.installationServiceAvailableForRetailer()) {
            this.menuItems.find(i => i.name === "app.menu.installation_service")?.setHide(false);
          }

          this.menuItems.find(i => i.name === "app.menu.open")
            ?.setHide(this._dataService.currentRetailerOptions?.configuratorOptions?.hideMenuOpenButton);
          this.menuItems.find(i => i.name === "app.menu.save")
            ?.setHide(this._dataService.currentRetailerOptions?.configuratorOptions?.hideMenuOpenButton);
        }
      });

    this._interactionService.getViewMode$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (m) => {
          this.menuItems.find(i => i.name === "app.menu.save")?.setDisabled(m === ViewMode.plp);
          this.menuItems.find(i => i.name === "app.menu.ar")?.setDisabled(m === ViewMode.plp);
          this.menuItems.find(i => i.name === "app.menu.restart")?.setDisabled(m === ViewMode.plp);
          this.menuItems.find(i => i.name === "app.menu.installation_service")?.setDisabled(m === ViewMode.plp);

          if (this.showMeasurements) {
            this._interactionService.setMeasurements(m === ViewMode.preview ? false : this.showMeasurements);
          }
        }
      });

    this._interactionService.getMenuCloser$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(() => this.visible = false);

    this._interactionService.getViewMode$()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(v => this._viewMode = v);
  }

  ngOnInit() {
    this._elRef.nativeElement.addEventListener('mouseup', el => {
      if (el.target.className === 'outside-menu') {
        this.closeMenu();
      }
    });
  }

  closeMenu(): void {
    this._interactionService.closeMenu();
  }

  toggleVisible(): void {
    this.visible = !this.visible;
  }

  downloadUsdz(): void {
    this._renderService.downloadUsdzScene();
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}

/* eslint-enable max-classes-per-file */
