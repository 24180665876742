import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {Configuration, Id, ModuleBlueprint, ModuleVariant, XAttachmentPoint} from "@ess/jg-rule-executor";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {AttachmentPointInfo} from "@src/app/model/attachment-point-info";
import {cloneDeep} from "lodash";
import {InitDataService} from '../data/init-data.service';
import {setOfSetContains} from "@src/app/helpers/general.helpers";
import {FatFlatCatalog, FatFlatCatalogCategory} from "@src/app/model/fat-flat-catalog";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {WorkerService} from "@src/app/services/worker/worker.service";

@Injectable({
  providedIn: 'root'
})
export class CatalogManagementService implements OnDestroy {
  // Cache observable for catalog
  public readonly filteredCatalog$: Subject<FatFlatCatalog> = new Subject<FatFlatCatalog>();
  public readonly catalog$: Subject<ConfiguratorCatalog> = new Subject<ConfiguratorCatalog>();
  private _serviceDestroyed$: Subject<void> = new Subject();

  constructor(
    private _moduleManagementService: ModuleManagementService,
    private _dataService: InitDataService,
    private _interactionService: InteractionService,
    private _logger: LoggingService,
    private _workerService: WorkerService
  ) {

  }

  /**
   * Given a Configuration and XAttachmentPoint, it is determined which items in the catalog would 'fit' onto the given XAttachmentPoints.
   * This is done by comparing the list of properties for the XAttachmentPoint with the properties of the YAttachmentPoints of all
   * available blueprints. The filtered catalog is then pushed to the filteredCatalog$ subject.
   *
   * If the attachment point or the config is missing, an undefined catalog is returned.
   *
   * @param info
   * @param config the configuration (needed for retrieving the actual attachmentPoint)
   */
  public async filterCatalog(info: AttachmentPointInfo, config: Configuration): Promise<void> {

    if (info && config) {
      const xAttachments = this._findXAttachmentPoints(info, config);
      const catalogClone = this._dataService.catalog.cloneForFatFlatCatalog();
      const flatCatalog = new FatFlatCatalog(this._moduleManagementService, catalogClone);
      if (xAttachments) {
        flatCatalog.categories = await this._getFilteredCategories(xAttachments, flatCatalog.categories);
        this._workerService.terminateWorkers();
      } else {
        flatCatalog.id = null;
        flatCatalog.categories = null;
      }
      this.filteredCatalog$.next(flatCatalog);
    } else {
      this.filteredCatalog$.next(undefined);
    }
  }

  public getCatalog(): void {
    this.catalog$.next(cloneDeep(this._dataService.catalog));
  }

  /**
   * OnDestroy close subscriptions and clear cache
   */
  public ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }

  /**
   * Get a list of full XAttachmentPoints for a given APInfo and configuration
   */
  private _findXAttachmentPoints(info: AttachmentPointInfo, config: Configuration): XAttachmentPoint[] {
    const placement = config.configurationPlacement.placement(
      info.placementId
    );
    if (!!placement) {
      const fatVariant = this._dataService.inConfigAndPublishedModuleVariants.find(fv => fv.variant.id === placement.variantId);
      if (info instanceof AttachmentPointInfo && info.childIds) {
        // Checks if it is included -- we don't care if it's full or not
        return fatVariant.blueprint.xAttachmentPoints.filter(x => info.childIds.includes(x.id));
      } else {
        return [fatVariant.blueprint.xAttachmentPoints.find(
          x => (info instanceof AttachmentPointInfo) ? x.id === info.xAttachmentId : undefined
        )];
      }
    }
    return [];
  }

  /**
   * Given a list of xAttachmentPoints and a list of fatFlatCategories, filter those categories to only contain items that fit the XAttachmentPoints.
   */
  private async _getFilteredCategories(xAttachments: XAttachmentPoint[], categories: FatFlatCatalogCategory[]): Promise<FatFlatCatalogCategory[]> {
    if (!categories) {
      return [];
    }

    // Start with an emptied category. It will be filled after validation of the variants
    const startingArray = categories.map(c => c.cloneWithTitleOnly() ); // This is the cheapest way to copy the title to a new FatFlatCatalogCategory object

    // get all variants and filter the ones that fit on some of the xattachmentpoints
    const fittingVariants: Id<ModuleVariant>[] = this._dataService.inConfigAndPublishedModuleVariants
      .filter(
        variant => variant.blueprint.yAttachmentPoints.some(
          a => xAttachments.some(x => setOfSetContains(x.properties, a.properties)) || a.properties.size === 0
        )
      )
      .map(fv => fv.variant.id);

    // for each category, filter the variants to make sure they are in the fittingVariants list.
    return (await categories.reduce(async (acc, c) => {
        const variants = c.variants
          .filter(x => x.showInCatalog && fittingVariants.includes(x.id));
        await this._filterVariantsWithWorker(await acc, c, variants);
        return acc;
      },
      Promise.resolve(startingArray) // use startingArray as accumulator and return it after logic has been executed
    )).filter(c => c.variants.length > 0 || c.invalidVariants.length > 0)
  }

  private async _filterVariantsWithWorker(acc: FatFlatCatalogCategory[], currentCategory: FatFlatCatalogCategory, variants: ModuleVariant[]): Promise<void> {
    const existingCategory = acc.find(x => x.title === currentCategory.title);
    this._workerService.initializeWorkerPool();
    await this._workerService.runTask(this._validateVariants.bind(this)) // create instance of multithreaded web-worker
      .then(worker => {
        return worker.executeFunction(variants) // return the execution of the worker with the variants as argument
      })
      .then(
        res => {
          // The first array contains all valid variants, the second array contains all invalid variants.
          const checkedVariants: [ModuleVariant[], ModuleVariant[]] = res;

          // Add any variants that aren't already in the returnCatalog we're building
          existingCategory.variants.push(...checkedVariants[0].filter(v => !existingCategory.variants.some(x => x.id === v.id)));
          existingCategory.invalidVariants.push(...checkedVariants[1].filter(v => !existingCategory.invalidVariants.some(x => x.id === v.id)));
        }
      )
  }

  /**
   * Validates variants for the current selected AP and sorts them into 2 arrays.
   */
  private _validateVariants(variants: ModuleVariant[]): [ModuleVariant[], ModuleVariant[]] {
    const valid: ModuleVariant[] = [];
    const invalid: ModuleVariant[] = [];
    const blueprints: Map<Id<ModuleBlueprint>, boolean> = new Map();
    // map over every variant and decide in which array they belong
    variants.map(v => {
      // Check if we've already checked the blueprint, but if the variant has translation visual changes, we need to check it anyway again (positions change)
      if (blueprints.has(v.blueprintId) && !v.visualChanges.find(c => c.type === 'translation')) { // add variant to correct list
        if (blueprints.get(v.blueprintId)) { // checks if current variant of blueprint is valid
          valid.push(v);
        } else {
          invalid.push(v);
        }
      } else { // validate variant and add it to array. Also add blueprint to blueprints
        try {
          if (this._interactionService.isVariantValid(v.id)) {
            blueprints.set(v.blueprintId, true);
            valid.push(v);
          } else {
            blueprints.set(v.blueprintId, false);
            invalid.push(v);
          }
        } catch (err) {
          this._logger.warn(`Failed to validate variant ${v.id}`);
          this._logger.warn(err);
        }
      }
    });
    // returning both arrays in an array, because tuples don't exist in typescript :)
    return [valid, invalid];
  }
}
