import {Component, Input, OnInit} from '@angular/core';
import {combineLatest, Observable, race, tap, timer} from "rxjs";
import {filter, map, take} from "rxjs/operators";

/**
 * Component that shows a spinner when either:
 * - a given observable does not emit in a given timeframe
 *
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  // Toggle to show spinning or not
  public isSpinning: boolean = false;

  // Timing observable that is set on starting the spinner
  private _aliveTimer?: Observable<any>;

  // Indicator that emits when spinner should not trigger
  @Input() finishedIndicator?: Observable<any>;
  // Indicator that has value indicating whether spinner is alive - note that only one indicator is allowed!
  @Input() isAliveIndicator?: Observable<boolean>;
  // If the finished indicator does not emit in this timeframe, we start the spinner
  @Input() initialToleranceMs?: number;
  // Minimal time the spinner should display in milliseconds
  @Input() minimalSpinningTimeMs: number = 500;

  @Input() displayText: string;
  @Input() progress: { cur: number, expect: number };

  public ngOnInit() {
    if (this.finishedIndicator && this.isAliveIndicator) {
      throw new Error('Only one indicator allowed!');
    }

    if (this.finishedIndicator && this.initialToleranceMs) {
      // Race the starting condition and a timer
      // If the timer exits first, show spinner, otherwise not
      race(
        this.finishedIndicator.pipe(map(_ => true)),
        timer(this.initialToleranceMs).pipe(map(_ => false)),
      ).pipe(
        take(1), // no unsub needed
        map(condition => {
          if (!condition) {
            // start spinning ans start watching for triggers to stop
            this._startSpinning();
            combineLatest([
              this.finishedIndicator,
              this._aliveTimer
            ]).pipe(
              take(1), // no unsub needed
              tap(_ => this._stopSpinning())
            ).subscribe();
          }
        })
      ).subscribe();
    }

    if (this.isAliveIndicator && this.initialToleranceMs) {
      // Race the starting condition and a timer
      // If the timer exits first, show spinner, otherwise not
      race(
        this.isAliveIndicator.pipe(filter(_ => _)), // wait for true value
        timer(this.initialToleranceMs).pipe(map(_ => false)),
      ).pipe(
        take(1), // no unsub needed
        map(condition => {
          if (!condition) {
            // start spinning ans start watching for triggers to stop
            this._startSpinning();
            combineLatest([
              this.isAliveIndicator.pipe(filter(_ => !_)), // wait for false value
              this._aliveTimer
            ]).pipe(
              take(1), // no unsub needed
              tap(_ => this._stopSpinning())
            ).subscribe();
          }
        })
      ).subscribe();
    }
  }

  public doNothing(event$: Event): void {
    event$.stopPropagation();
  }

  /**
   * Starts the spinning process
   */
  private _startSpinning(): void {
    if (!this.isSpinning) {
      this.isSpinning = true;
      this._aliveTimer = timer(this.minimalSpinningTimeMs);
    }
  }

  /**
   * Stops the spinning process after a set amount of time
   */
  private _stopSpinning(): void {
    this.isSpinning = false;
  }

}
